import React, { useState } from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

// import "./styles.css";

const SnackAlert = ({ title, open, type, onClose }) => {
  const [state] = useState({
    vertical: "top",
    horizontal: "right",
  });

  const { vertical, horizontal } = state;

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function renderAlert() {
    if (type === "success") {
      return (
        <Alert onClose={onClose} severity="success">
          {title}
        </Alert>
      );
    } else if (type === "warning") {
      return (
        <Alert onClose={onClose} severity="warning">
          {title}
        </Alert>
      );
    } else if (type === "info") {
      return (
        <Alert onClose={onClose} severity="info">
          {title}
        </Alert>
      );
    } else {
      return (
        <Alert onClose={onClose} severity="error">
          {title}
        </Alert>
      );
    }
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={onClose}
      key={vertical + horizontal}
      autoHideDuration={6000}
    >
      {renderAlert()}
    </Snackbar>
  );
};

SnackAlert.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  type: PropTypes.string,
  onClose: PropTypes.func,
};

SnackAlert.defaultProps = {
  title: "Insira uma mensagem",
  open: false,
  type: "success",
  onClose: () => {},
};

export default SnackAlert;
