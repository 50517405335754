import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";

import {
  loginRequest,
  getOrganizationService,
  getShowMyChannel,
} from "../../services/api";
import { ToggleLoggedContext } from "../../Context/login";
import { SnackAlert } from "../../Template/Admin/components";
import { BASE_URL } from "../../services/Ambiente";

import logoImg from "../../assets/images/logo_v3.png";

import "./styles.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [loadingFacebook, setLoadingFacebook] = useState(false);
  const [organization, setOrganization] = useState({});
  const [previousAction, setPreviousAction] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  const { toggleLoggedAction } = useContext(ToggleLoggedContext);
  const facebookRef = useRef(null);
  const googleRef = useRef(null);

  const history = useHistory();

  useEffect(() => {
    getOrganizationInfo();
    handleHistoryState();
    // eslint-disable-next-line
  }, []);

  const handleHistoryState = () => {
    const state = history?.location?.state;
    if (state) {
      if (state.goBack) setPreviousAction(state.previousPage);
    }
  };

  function getOrganizationInfo() {
    getOrganizationService()
      .then((response) => {
        if (response.data.return) {
          setOrganization(response.data.data[0]);
        } else {
          setAlert({ open: true, type: "warning", title: response.data.data });
        }
      })
      .catch((err) => {
        setAlert({ open: true, type: "error", title: err });
      });
  }

  function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);

    const data = new FormData();
    data.append("email", email);
    data.append("senha", password);
    data.append("tipo_login", "padrao");

    loginRequest(data)
      .then(async (response) => {
        setLoading(false);
        handleLoginSuccess(response);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ open: true, type: "error", title: err });
      });
  }

  const handleSocialLogin = async ({ provider, data }) => {
    const formData = new FormData();
    formData.append("tipo_login", provider);

    if (provider === "facebook") setLoadingFacebook(true);
    else setLoadingGoogle(true);

    try {
      const tokenId = provider === "facebook" ? data.userID : data.login_hint;
      formData.append("email", data.email);
      formData.append("login_token_id", tokenId);

      const response = await loginRequest(formData);
      handleLoginSuccess(response);
    } catch (error) {
      setAlert({ open: true, type: "error", title: error });
    } finally {
      if (provider === "facebook") setLoadingFacebook(false);
      else setLoadingGoogle(false);
    }
  };

  const handleLoginSuccess = async (response) => {
    if (response.data.return) {
      sessionStorage.setItem(
        "currentUser",
        JSON.stringify(response.data.data[0])
      );
      const res = await onGetShowMyChannel(response.data.data?.[0]?.id);
      if (res) {
        sessionStorage.setItem("userChannel", JSON.stringify(res));
      }

      setTimeout(() => {
        toggleLoggedAction();
        if (previousAction) goToPreviusPage();
        else history.push("/home");
        document.location.reload(true);
      }, 500);
    } else {
      setAlert({ open: true, type: "warning", title: response.data.data });
    }
  };

  const goToPreviusPage = () => {
    const state = history?.location?.state;
    history.push(previousAction, {
      ...state,
    });
  };

  const onGetShowMyChannel = async (id) => {
    try {
      const response = await getShowMyChannel(id);
      if (response.data.return) {
        return response.data.data[0];
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  function goToOnboarding() {
    history.push("/cadastro");
  }

  function goToForgotPassword() {
    history.push("/esqueci-senha");
  }

  const onRejectSocialLogin = (err) => {
    const msg = handleErrorMsg(err.error);
    setAlert({ open: true, type: "warning", title: msg });
    setTimeout(() => {
      goToOnboarding();
    }, 3000);
  };

  const handleErrorMsg = (err) => {
    switch (err) {
      case "popup_closed_by_user":
        return "Login cancelado pelo usuário.";
      default:
        return "Login cancelado pelo usuário.";
    }
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <div className="login-site-container">
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <section className="login-main-wrapper">
        <div className="container-fluid pl-0 pr-0">
          <div className="row no-gutters">
            <div className="col-md-5 p-5 bg-white full-height">
              <div className="login-main-left">
                <div className="text-center mb-5 login-main-left-header pt-4">
                  <img src={logoImg} alt="Logomarca" style={{ height: 60 }} />
                  <h5 className="mt-3 mb-3">Bem Vindo ao It's On Tickets</h5>
                  <p style={{ display: "none" }}>
                    Uma breve descrição ou frase de impacto
                  </p>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>E-mail</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Insira o seu e-mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                  </div>
                  <div className="form-group">
                    <label>Senha</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Insira sua senha"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    ></input>
                  </div>
                  <div className="mt-4">
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-outline-primary btn-block btn-lg"
                        >
                          {loading ? (
                            <CircularProgress size={18} color="primary" />
                          ) : (
                            "Entrar"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="text-center mt-4">
                  <div className="light-gray d-flex flex-row justify-content-center">
                    <div
                      className="login-site-btn-onb"
                      onClick={goToForgotPassword}
                    >
                      Esqueci a senha
                    </div>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <div className="light-gray d-flex flex-row justify-content-center">
                    Não tem uma conta?{" "}
                    <div
                      className="login-site-btn-onb"
                      onClick={goToOnboarding}
                    >
                      Cadastre-se
                    </div>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <div className="light-gray d-flex flex-row justify-content-center">
                    Ou
                  </div>
                </div>
                <div className="text-center mt-3 mb-2 d-flex flex-column align-items-center">
                  <div className="login-social-btn">
                    <LoginSocialFacebook
                      ref={facebookRef}
                      appId="1947675018953795"
                      onResolve={handleSocialLogin}
                      onReject={onRejectSocialLogin}
                      language="pt_BR"
                    >
                      <FacebookLoginButton>
                        <span className="login-social-btn-text">
                          {loadingFacebook ? (
                            <div className="login-social-loading">
                              <CircularProgress size={18} color="secondary" />
                            </div>
                          ) : (
                            "Continuar pelo Facebook"
                          )}
                        </span>
                      </FacebookLoginButton>
                    </LoginSocialFacebook>
                  </div>
                  <div className="login-social-btn">
                    <LoginSocialGoogle
                      ref={googleRef}
                      client_id="96004599360-vfftbjd3kl19u2p5d64of25cnvjo7eov.apps.googleusercontent.com"
                      onResolve={handleSocialLogin}
                      onReject={onRejectSocialLogin}
                    >
                      <GoogleLoginButton>
                        <span className="login-social-btn-text">
                          {loadingGoogle ? (
                            <div className="login-social-loading">
                              <CircularProgress size={18} color="primary" />
                            </div>
                          ) : (
                            "Continuar pelo Google"
                          )}
                        </span>
                      </GoogleLoginButton>
                    </LoginSocialGoogle>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 d-flex align-items-center">
              <div className="login-main-right bg-white p-5 mt-5 mb-5 d-flex justify-content-center">
                {organization?.logo_prin ? (
                  <img
                    className="login-site-img"
                    src={`${BASE_URL}${organization.logo_prin}`}
                    alt="Logomarca Organização"
                  />
                ) : (
                  <div style={{ height: 420 }}></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
