import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Moment from "moment";

// import "./styles.css";

Moment.locale("pt-BR");

const DatesSelection = ({ dates, selectedDate, onClickDate }) => {
  const [newDates, setNewDates] = useState([]);
  const [firstSelected, setFirstSelected] = useState(false);

  useEffect(() => {
    let aux = dates;

    aux.forEach((item) => {
      if (item.data === selectedDate) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });

    setFirstSelected(selectedDate ? false : true);

    setNewDates(aux);
  }, [dates, selectedDate]);

  function onSelectDate(date, grav_id) {
    onClickDate(date, grav_id);

    let index = newDates.findIndex((item) => item.id === grav_id);

    if (index) {
      let aux = newDates;

      aux.forEach((date, i) => {
        if (i === index) {
          date.selected = true;
        } else {
          date.selected = false;
        }
      });

      setNewDates(aux);
    }
  }

  function selectAllDays() {
    let aux = newDates;

    aux.forEach((date) => {
      date.selected = false;
    });

    setNewDates(aux);
    onClickDate(null);
  }

  return (
    <div className="single-video-info-content box mb-3">
      <h6>Datas de gravações da sala:</h6>
      <p className="mb-0 mt-2">
        <span
          className={firstSelected ? "selected-tag" : "tags"}
          onClick={() => selectAllDays()}
        >
          Início
        </span>
        {newDates.map((date, i) => (
          <span
            key={String(i)}
            // className={date.selected ? "selected-tag" : "tags"}
            className={date.selected ? "tags" : "tags"}
            onClick={() => onSelectDate(date.data, date.id)}
          >
            {date.id + ' - ' + Moment(date.data)
              .utc()
              .format("DD/MM/YYYY")}
          </span>
        ))}
      </p>
    </div>
  );
};

DatesSelection.propTypes = {
  dates: PropTypes.array,
  selectedDate: PropTypes.string,
  onClickDate: PropTypes.func,
};

DatesSelection.defaultProps = {
  dates: [],
  selectedDate: "",
  onClickDate: (date, grav_id) => {},
};

export default DatesSelection;
