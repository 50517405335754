import React from "react";
import PropTypes from "prop-types";
import { SectionTitle, ChannelContentList } from "../../components";

const ChannelsContentContainer = ({
  contents,
  empty,
  msgEmpty,
  title,
  showDelete,
  selectedContent,
  onPreviewContent,
  onEditContent,
  onDeleteContent,
}) => {
  return (
    <>
      {empty ? (
        <>
          <SectionTitle title={title} />
          <div className="col-md-12 mb-3 pl-0" style={{ minHeight: 140 }}>
            <div className="main-title">
              <span>{msgEmpty}</span>
            </div>
          </div>
        </>
      ) : (
        <div className="video-block section-padding">
          <ChannelContentList
            title={title}
            data={contents}
            showDelete={showDelete}
            onSelected={(e, item) => selectedContent(e, item)}
            onClickPreview={(e, item) => onPreviewContent(e, item)}
            onDelete={(e, item) => onDeleteContent(e, item)}
            onEdit={onEditContent ? (e, item) => onEditContent(e, item) : null}
          />
        </div>
      )}
    </>
  );
};

ChannelsContentContainer.propTypes = {
  contents: PropTypes.array,
  empty: PropTypes.bool,
  msgEmpty: PropTypes.string,
  title: PropTypes.string,
  showDelete: PropTypes.bool,
  selectedContent: PropTypes.func,
  onPreviewContent: PropTypes.func,
  onDeleteContent: PropTypes.func,
};

ChannelsContentContainer.defaultProps = {
  contents: [],
  empty: false,
  msgEmpty: "",
  title: "",
  showDelete: false,
  selectedContent: (e, item) => {},
  onPreviewContent: (e, item) => {},
  onEditContent: null,
  onDeleteContent: (e, item) => {},
};

export default ChannelsContentContainer;
