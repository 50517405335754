import React, { useState, useEffect } from "react";
import Moment from "moment";

import {
  SectionTitle,
  SnackAlert,
  RoomsLoading,
  MyShoppingContainer,
} from "../../Template/Admin/components";
import { getMyShopping, requestContentDownload } from "../../services/api";

import "./styles.css";

Moment.locale("pt-BR");

const MyShoppingScreen = () => {
  const [myShopping, setMyShopping] = useState([]);
  const [emptyShopping, setEmptyShopping] = useState(false);
  const [msgEmptyShopping, setMsgEmptyShopping] = useState("");
  const [loading, setLoading] = useState(true);
  const [requestLoading, setRequestLoading] = useState([]);
  const [loggedUser, setLoggedUser] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  useEffect(() => {
    onGetMyShopping();
    // eslint-disable-next-line
  }, []);

  const onGetMyShopping = () => {
    const user = JSON.parse(sessionStorage.getItem("currentUser"));

    if (user) {
      setLoggedUser(user);
      setLoading(true);

      const email = encodeURIComponent(user?.email);

      getMyShopping(user?.id, email)
        .then((response) => {
          setLoading(false);

          if (response.data.return) {
            setMyShopping(response.data.data);
            const data = response.data.data;
            const aux = [];
            data.forEach(() => {
              aux.push(false);
            });
            setRequestLoading(aux);
            setEmptyShopping(false);
          } else {
            setEmptyShopping(true);
            setMsgEmptyShopping(response.data.data);
          }
        })
        .catch(() => {
          setLoading(false);
          setAlert({
            open: true,
            type: "error",
            title: "Erro ao buscar lista de compras.",
          });
        });
    }
  };

  const onRequestContentDownload = (e, item) => {
    e.preventDefault();

    const load = [...requestLoading];
    const index = myShopping.findIndex(
      (itm) => item.transacao_id === itm.transacao_id
    );
    if (index >= 0) load[index] = true;

    setRequestLoading(load);

    if (loggedUser) {
      requestContentDownload(loggedUser?.id, item?.conteudo_id)
        .then((response) => {
          disableRequestLoading();

          if (response.data.return) {
            setAlert({
              open: true,
              type: "success",
              title:
                "Download solicitado com sucesso. Por favor, verifique seu e-mail.",
            });
          } else {
            setAlert({
              open: true,
              type: "error",
              title: "Não foi possível solicitar o download deste produto.",
            });
          }
        })
        .catch(() => {
          disableRequestLoading();
          setAlert({
            open: true,
            type: "error",
            title: "Não foi possível solicitar o download deste produto.",
          });
        });
    }
  };

  const disableRequestLoading = () => {
    const newLoad = [...requestLoading];
    const aux = [];
    newLoad.forEach(() => {
      aux.push(false);
    });
    setRequestLoading(aux);
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  if (loading) {
    return (
      <div className="container-fluid pb-0 my-shopping-screen-container">
        <RoomsLoading />
      </div>
    );
  }

  return (
    <div className="container-fluid pb-0 my-shopping-screen-container">
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <div className="top-category section-padding mb-4">
        <div className="my-shopping-screen-top-container pl-0">
          <SectionTitle title="Minhas Compras" />
        </div>
      </div>
      <div className="col-lg-12 col-md-12 mb-5 pl-0 pr-0">
        <MyShoppingContainer
          shopping={myShopping}
          empty={emptyShopping}
          msgEmpty={msgEmptyShopping}
          loading={requestLoading}
          selectedShopping={onRequestContentDownload}
        />
      </div>
    </div>
  );
};

export default MyShoppingScreen;
