import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { CustomButton, SnackAlert, UsersLoading } from "../../../components";
import {
  getCanAddRoomUsersService,
  addRoomUserService,
} from "../../../../../services/api";

import "./styles.css";

const AddUsers = ({ open, onClose, roomId }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  useEffect(() => {
    if (open) {
      getCanAddRoomUsers();
    }
    // eslint-disable-next-line
  }, [open]);

  function getCanAddRoomUsers() {
    setLoading(true);

    getCanAddRoomUsersService(roomId)
      .then((response) => {
        setLoading(false);

        if (response.data.return) {
          setUsers(response.data.data);
        } else {
          setAlert({ open: true, type: "warning", title: response.data.data });
        }
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ open: true, type: "error", title: err });
      });
  }

  function addRoomUser(id) {
    const loggeduser = JSON.parse(sessionStorage.getItem("currentUser"));

    if (loggeduser) {
      setLoading(true);

      const data = new FormData();
      data.append("sala_id", roomId);
      data.append("usuario_id", id);
      data.append("acao_usuario_id", loggeduser.id);

      addRoomUserService(data)
        .then((response) => {
          if (response.data.return) {
            setAlert({
              open: true,
              type: "success",
              title: response.data.data,
            });
            getCanAddRoomUsers();
          } else {
            setLoading(false);
            setAlert({
              open: true,
              type: "warning",
              title: response.data.data,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          setAlert({ open: true, type: "error", title: err });
        });
    }
  }

  const handleClose = () => {
    onClose();
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
    >
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <DialogTitle id="modal-title">
        {"Usuários para Adicionar na Sala"}
      </DialogTitle>
      <DialogContent>
        <div id="modal-body" className="add-users-container">
          <div className="add-users-content">
            {loading ? (
              <UsersLoading />
            ) : (
              users.map((user, i) => (
                <div
                  key={String(i)}
                  className={users.length === i + 1 ? "add-users-ll" : ""}
                >
                  <span>{`${user.first_name} - ${user.email}`}</span>
                  <div
                    className="add-users-btn-plus"
                    onClick={() => addRoomUser(user.id)}
                  >
                    <i className="fas fa-fw fa-plus"></i>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleClose}>Fechar</CustomButton>
      </DialogActions>
    </Dialog>
  );
};

AddUsers.propTypes = {
  open: PropTypes.bool,
  roomId: PropTypes.string,
  onClose: PropTypes.func,
};

AddUsers.defaultProps = {
  open: false,
  roomId: "",
  onClose: () => {},
};

export default AddUsers;
