import React, { useState } from "react";
import PropTypes from "prop-types";
import { BMContainer, SectionTitle, BestMomentView } from "../../components";
import { convertMinToSec } from "../../../../utils";

const HomeBestMoments = ({ moments, title }) => {
  const [selected, setSelected] = useState(null);
  const [urlSelected, setUrlSelected] = useState("");
  const [shareData, setShareData] = useState({
    url: "",
    startTime: 0,
  });
  const [openBestMomentModal, setOpenBestMomentModal] = useState(false);

  function selectedMoment(e, item) {
    setSelected(item);

    let start = convertMinToSec(item?.intervalo_inicial);
    let end = convertMinToSec(item?.intervalo_final);
    let videoUrl = item?.url_gravacao?.replace("watch?v=", "embed/");

    if (start && end) {
      let url = `${videoUrl}?start=${start}&end=${end}`;
      setUrlSelected(url);
      setShareData({
        url: videoUrl,
        startTime: start,
      });
      setOpenBestMomentModal(true);
    }
  }

  function onCloseBestMomentModal() {
    setOpenBestMomentModal(false);
    setSelected(null);
    setUrlSelected("");
  }

  return (
    <>
      {moments.lenght === 0 ? (
        <>
          <SectionTitle title={title} />
          <div className="col-md-12 mb-3 pl-0" style={{ minHeight: 140 }}>
            <div className="main-title">
              <span>Não há melhores momentos no momento.</span>
            </div>
          </div>
        </>
      ) : (
        <div className="video-block section-padding mb-2">
          <BMContainer
            title={title}
            moments={moments}
            onSelected={(e, item) => selectedMoment(e, item)}
          />
          <BestMomentView
            open={openBestMomentModal}
            onClose={onCloseBestMomentModal}
            title={selected?.titulo}
            url={urlSelected}
            canShare
            shareData={shareData}
          />
        </div>
      )}
    </>
  );
};

HomeBestMoments.propTypes = {
  moments: PropTypes.array,
  title: PropTypes.string,
};

HomeBestMoments.defaultProps = {
  moments: [],
  title: "",
};

export default HomeBestMoments;
