import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import VMasker from "vanilla-masker";
import {
  forgotPasswordService,
  getOrganizationService,
} from "../../services/api";
import { SnackAlert, InputBasic } from "../../Template/Admin/components";
import { BASE_URL } from "../../services/Ambiente";

import logoImg from "../../assets/images/logo_v3.png";

import "./styles.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState({});
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  const history = useHistory();

  useEffect(() => {
    getOrganizationInfo();
  }, []);

  function getOrganizationInfo() {
    getOrganizationService()
      .then((response) => {
        if (response.data.return) {
          setOrganization(response.data.data[0]);
        } else {
          setAlert({ open: true, type: "warning", title: response.data.data });
        }
      })
      .catch((err) => {
        setAlert({ open: true, type: "error", title: err });
      });
  }

  function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);

    const data = new FormData();
    data.append("email", email);
    data.append("celular", cellPhone);

    forgotPasswordService(data)
      .then((response) => {
        setLoading(false);
        if (response.data.return) {
          setAlert({ open: true, type: "success", title: response.data.data });
          setTimeout(() => {
            history.push("/login");
          }, 4000);
        } else {
          setAlert({ open: true, type: "warning", title: response.data.data });
        }
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ open: true, type: "error", title: err });
      });
  }

  function goToLogin() {
    history.push("/login");
  }

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <div className="forgot-password-container">
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <section className="login-main-wrapper">
        <div className="container-fluid pl-0 pr-0">
          <div className="row no-gutters">
            <div className="col-md-5 p-5 bg-white full-height">
              <div className="login-main-left">
                <div className="text-center mb-5 login-main-left-header pt-4">
                  <img src={logoImg} alt="Logomarca" style={{ height: 60 }} />
                  <h5 className="mt-3 mb-3">
                    It's On Tickets - Redefinir Senha
                  </h5>
                  <p style={{ display: "none" }}>
                    Uma breve descrição ou frase de impacto
                  </p>
                </div>
                <form onSubmit={handleSubmit}>
                  <InputBasic
                    required={true}
                    label="E-mail"
                    placeholder="Insira o seu e-mail"
                    value={email}
                    onChange={(value) => setEmail(value)}
                  />
                  <InputBasic
                    required={true}
                    label="Celular"
                    placeholder="Insira o seu celular"
                    value={cellPhone}
                    onChange={(value) => {
                      let maskedValue = VMasker.toPattern(
                        value,
                        "(99) 9 9999-9999"
                      );
                      setCellPhone(maskedValue);
                    }}
                  />
                  <div className="mt-4">
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-outline-primary btn-block btn-lg"
                        >
                          {loading ? (
                            <CircularProgress size={18} color="primary" />
                          ) : (
                            "Redefinir Senha"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="text-center mt-5">
                  <div className="light-gray d-flex flex-row justify-content-center">
                    Lembrou da sua senha?{" "}
                    <div
                      className="forgot-password-btn-onb"
                      onClick={goToLogin}
                    >
                      Faça o login
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 d-flex align-items-center">
              <div className="login-main-right bg-white p-5 mt-5 mb-5 d-flex justify-content-center">
                {organization?.logo_prin ? (
                  <img
                    className="forgot-password-img"
                    src={`${BASE_URL}${organization.logo_prin}`}
                    alt="Logomarca Organização"
                  />
                ) : (
                  <div style={{ height: 420 }}></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForgotPassword;
