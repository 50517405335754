import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CustomButton, SnackAlert, InputBasic } from "../../../components";
import { requestChannel } from "../../../../../services/api";

//import "./styles.css";

const RequestChannel = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  useEffect(() => {
    if (!open) {
      resetFields();
    }
    // eslint-disable-next-line
  }, [open]);

  const onRequestChannel = (id) => {
    const loggeduser = JSON.parse(sessionStorage.getItem("currentUser"));

    if (!title || !description) {
      setAlert({
        open: true,
        type: "warning",
        title: `O campo ${title ? "Descrição" : "Título"} é obrigatório.`,
      });
    } else {
      if (loggeduser) {
        setLoading(true);

        const data = new FormData();
        data.append("usuario_id", loggeduser.id);
        data.append("titulo", title);
        data.append("descricao", description);

        requestChannel(data)
          .then((response) => {
            setLoading(false);
            if (response.data.return) {
              setLoading(false);
              setAlert({
                open: true,
                type: "success",
                title: response.data.data,
              });
              setTimeout(() => {
                handleClose();
              }, 3000);
            } else {
              setAlert({
                open: true,
                type: "warning",
                title: response.data.data,
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            setAlert({ open: true, type: "error", title: err });
          });
      }
    }
  };

  const resetFields = () => {
    setTitle("");
    setDescription("");
  };

  const handleClose = () => {
    onClose();
    resetFields();
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
    >
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <DialogTitle id="modal-title">{"Solicitar Loja"}</DialogTitle>
      <DialogContent>
        <div id="modal-body">
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <InputBasic
                required={true}
                label="Título"
                placeholder="Insira o título"
                maxLength={50}
                value={title}
                onChange={(value) => setTitle(value)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <InputBasic
                required={true}
                label="Descrição"
                placeholder="Insira uma descrição"
                maxLength={70}
                value={description}
                onChange={(value) => setDescription(value)}
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleClose}>Fechar</CustomButton>
        <CustomButton onClick={onRequestChannel}>
          {loading ? <CircularProgress size={20} /> : <div>Enviar</div>}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

RequestChannel.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

RequestChannel.defaultProps = {
  open: false,
  onClose: () => {},
};

export default RequestChannel;
