import React from "react";
import PropTypes from "prop-types";
import { ChannelsList, SectionTitle } from "../../components";

const ChannelsContainer = ({
  channels,
  empty,
  msgEmpty,
  title,
  selectedChannel,
}) => {
  return (
    <>
      {empty ? (
        <>
          <SectionTitle title={title} />
          <div className="col-md-12 mb-3 pl-0" style={{ minHeight: 140 }}>
            <div className="main-title">
              <span>{msgEmpty}</span>
            </div>
          </div>
        </>
      ) : (
        <div className="video-block section-padding">
          <ChannelsList
            title={title}
            data={channels}
            onSelected={(e, item) => selectedChannel(e, item)}
          />
        </div>
      )}
    </>
  );
};

ChannelsContainer.propTypes = {
  channels: PropTypes.array,
  empty: PropTypes.bool,
  msgEmpty: PropTypes.string,
  title: PropTypes.string,
  selectedChannel: PropTypes.func,
};

ChannelsContainer.defaultProps = {
  channels: [],
  empty: false,
  msgEmpty: "",
  title: "",
  selectedChannel: (e, item) => {},
};

export default ChannelsContainer;
