import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "./TabPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const VideoInteractions = ({ components }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="Video interactions"
        >
          {components.map((item, i) => (
            <Tab
              key={String(i)}
              title={item.title}
              icon={item.icon}
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
      </AppBar>
      {components.map((item, i) => (
        <TabPanel
          key={String(i)}
          value={value}
          index={i}
          setPadding={i === 1 ? false : true}
        >
          {item.component}
        </TabPanel>
      ))}
    </div>
  );
};

VideoInteractions.propTypes = {
  components: PropTypes.array,
};

VideoInteractions.defaultProps = {
  components: [],
};

export default VideoInteractions;
