import React from "react";

// import Google from "../../../../assets/images/google.png";
// import Apple from "../../../../assets/images/apple.png";

import "./styles.css";

const Footer = () => {
  return (
    <footer className="sticky-footer">
      <div className="container">
        <div className="row no-gutters">
          <div className="col-lg-6 col-sm-6">
            <p className="mt-2 mb-0">
              &copy; Copyright {new Date().getFullYear()}{" "}
              <strong className="text-dark">It's On Tickets</strong>
              . All Rights Reserved <br />
            </p>
          </div>
          {/* <div className="col-lg-6 col-sm-6 text-right">
            <div className="app">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=br.com.itsonsportplayer"
                className="mr-2"
              >
                <img alt="" src={Google} />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/its-on-sport-player/id1573269918"
              >
                <img alt="" src={Apple} />
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
