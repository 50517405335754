import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CloudDownload } from "@material-ui/icons";
import { getDownloadVideo } from "../../../../services/api";

import { CustomButton, LocalVideos } from "../../components";

import "./styles.css";

const VideoCard = ({
  url,
  urlOrigin,
  description,
  live,
  date,
  showDownloadBtn,
  cam,
  typeLive,
}) => {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [showTabs, setShowTabs] = useState(false);

  const [enterVideo, setEnterVideo] = useState(1);

  useEffect(() => {
    function resize() {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  useEffect(() => {
    if ((typeLive === "Servidor Its On" && live) || (live && url === "#"))
      setShowTabs(true);
    else setShowTabs(false);
  }, [typeLive, live, url]);

  async function handleDownload() {
    getDownloadVideo(urlOrigin).then((response) => {
      window.open(response.data.urlVideo);
    });
  }

  async function enterVideoF() {
    window.startPlayerLive();
    setEnterVideo(0);
  }

  return (
    <div className="single-video mt-1 d-flex flex-column">
      {showTabs ? (
        <>
          {enterVideo ? enterVideoF() : ""}
          <LocalVideos data={cam} live={live} mainUrl={url} />
        </>
      ) : (
        <>
          <iframe
            width="100%"
            height={width < 600 ? "200" : height - 250}
            src={`${url}${live ? (date ? "?" : "&") : "?"}autoplay=1`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="Vídeo da câmera"
          ></iframe>
          <div className="single-video-title box mb-2 mt-2 video-card-title-container">
            <h2 className="mb-0">{description}</h2>
            {showDownloadBtn && (
              <CustomButton
                title="Download"
                showBorder
                onClick={() => handleDownload()}
              >
                <CloudDownload />
              </CustomButton>
            )}
          </div>
        </>
      )}
    </div>
  );
};

VideoCard.propTypes = {
  url: PropTypes.string,
  urlOrigin: PropTypes.string,
  description: PropTypes.string,
  live: PropTypes.bool,
  date: PropTypes.string,
  showDownloadBtn: PropTypes.bool,
  cam: PropTypes.array,
  typeLive: PropTypes.string,
};

VideoCard.defaultProps = {
  url: "",
  urlOrigin: "",
  description: "Insira uma descrição...",
  live: false,
  date: "",
  showDownloadBtn: false,
  cam: [],
  typeLive: "Servidor Its On",
};

export default VideoCard;
