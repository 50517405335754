import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import { CircularProgress } from "@material-ui/core";

import {
  onboardingService,
  getOrganizationService,
  getShowMyChannel,
  onboardingSocialService,
} from "../../services/api";
import { ToggleLoggedContext } from "../../Context/login";
import { SnackAlert } from "../../Template/Admin/components";
import { BASE_URL } from "../../services/Ambiente";
import Form from "./containers/Form";

import logoImg from "../../assets/images/logo_v3.png";

import "./styles.css";

const Onboarding = () => {
  const [loading, setLoading] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [loadingFacebook, setLoadingFacebook] = useState(false);
  const [organization, setOrganization] = useState({});
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  const { toggleLoggedAction } = useContext(ToggleLoggedContext);

  const facebookRef = useRef(null);
  const googleRef = useRef(null);

  const history = useHistory();

  useEffect(() => {
    getOrganizationInfo();
  }, []);

  function getOrganizationInfo() {
    getOrganizationService()
      .then((response) => {
        if (response.data.return) {
          setOrganization(response.data.data[0]);
        } else {
          setAlert({ open: true, type: "warning", title: response.data.data });
        }
      })
      .catch((err) => {
        setAlert({ open: true, type: "error", title: err });
      });
  }

  const handleSocialLogin = async ({ provider, data }) => {
    const formData = new FormData();
    formData.append("tipo_login", provider);

    if (provider === "facebook") {
      setLoadingFacebook(true);
      try {
        const username = `${String(data.first_name).toLowerCase()}.${String(
          data.last_name
        ).toLowerCase()}`;
        formData.append("email", data.email);
        formData.append("username", username);
        formData.append("first_name", data.first_name);
        formData.append("last_name", data.last_name);
        formData.append("login_token_id", data.userID);

        const response = await onboardingSocialService(formData);
        handleCreateUserSuccess(response);
      } catch (error) {
        setAlert({ open: true, type: "error", title: error });
      } finally {
        setLoadingFacebook(false);
      }
    } else {
      setLoadingGoogle(true);
      try {
        const username = `${String(data.firstName).toLowerCase()}.${String(
          data.lastName
        ).toLowerCase()}`;
        formData.append("email", data.email);
        formData.append("username", username);
        formData.append("first_name", data.firstName);
        formData.append("last_name", data.lastName);
        formData.append("login_token_id", data.login_hint);

        const response = await onboardingSocialService(formData);
        handleCreateUserSuccess(response);
      } catch (error) {
        setAlert({ open: true, type: "error", title: error });
      } finally {
        setLoadingGoogle(false);
      }
    }
  };

  function handleSubmit(values) {
    setLoading(true);

    const data = new FormData();
    data.append("email", values.email);
    data.append("username", values.username);
    data.append("first_name", values.firstName);
    data.append("last_name", values.lastName);
    data.append("telefone", values.phone);
    data.append("celular", values.cellphone);
    data.append("local", values.city);
    data.append("nascimento", values.birthday);
    data.append("password", values.password);
    data.append("confirm_password", values.confirmPassword);
    data.append("tipo_login", "padrao");

    onboardingService(data)
      .then((response) => {
        setLoading(false);
        handleCreateUserSuccess(response);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ open: true, type: "error", title: err });
      });
  }

  const handleCreateUserSuccess = async (response) => {
    if (response.data.return) {
      setAlert({ open: true, type: "success", title: response.data.msg });

      sessionStorage.setItem(
        "currentUser",
        JSON.stringify(response.data.data[0])
      );
      const res = await onGetShowMyChannel(response.data.data?.[0]?.id);
      if (res) {
        sessionStorage.setItem("userChannel", JSON.stringify(res));
      }

      setTimeout(() => {
        toggleLoggedAction();
        history.push("/home");
        document.location.reload(true);
      }, 3000);
    } else {
      setAlert({ open: true, type: "warning", title: response.data.data });
    }
  };

  const onRejectSocialLogin = (err) => {
    const msg = handleErrorMsg(err.error);
    setAlert({ open: true, type: "warning", title: msg });
  };

  const handleErrorMsg = (err) => {
    switch (err) {
      case "popup_closed_by_user":
        return "Criação de conta cancelada pelo usuário.";
      default:
        return "Criação de conta cancelada pelo usuário.";
    }
  };

  const onGetShowMyChannel = async (id) => {
    try {
      const response = await getShowMyChannel(id);
      if (response.data.return) {
        return response.data.data[0];
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  function goToLogin() {
    history.push("/login");
  }

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <div className="onboarding-container">
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <section className="login-main-wrapper">
        <div className="container-fluid pl-0 pr-0">
          <div className="row no-gutters onboarding-content">
            <div className="col-md-5 pt-1 pl-3 pr-3 pb-1 bg-white full-height height-auto">
              <div className="">
                <div className="text-center mb-5 pt-4">
                  <img src={logoImg} alt="Logomarca" style={{ height: 60 }} />
                  <h5 className="mt-3 mb-3">It's On Sport Player - Cadastro</h5>
                  <p style={{ display: "none" }}>
                    Uma breve descrição ou frase de impacto
                  </p>
                </div>
                <Form loading={loading} onSubmit={handleSubmit} />
                <div className="text-center mt-3">
                  <div className="light-gray d-flex flex-row justify-content-center">
                    Já tem uma conta?
                    <div className="login-site-btn-onb" onClick={goToLogin}>
                      Faça o login
                    </div>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <div className="light-gray d-flex flex-row justify-content-center">
                    Ou
                  </div>
                </div>
                <div className="text-center mt-3 mb-2 d-flex flex-column align-items-center">
                  <div className="onboarding-social-btn">
                    <LoginSocialFacebook
                      ref={facebookRef}
                      appId="1947675018953795"
                      onResolve={handleSocialLogin}
                      onReject={onRejectSocialLogin}
                    >
                      <FacebookLoginButton>
                        <span className="onboarding-social-btn-text">
                          {loadingFacebook ? (
                            <div className="onboarding-social-loading">
                              <CircularProgress size={18} color="secondary" />
                            </div>
                          ) : (
                            "Continuar pelo Facebook"
                          )}
                        </span>
                      </FacebookLoginButton>
                    </LoginSocialFacebook>
                  </div>
                  <div className="onboarding-social-btn">
                    <LoginSocialGoogle
                      ref={googleRef}
                      client_id="96004599360-vfftbjd3kl19u2p5d64of25cnvjo7eov.apps.googleusercontent.com"
                      onResolve={handleSocialLogin}
                      onReject={onRejectSocialLogin}
                    >
                      <GoogleLoginButton>
                        <span className="onboarding-social-btn-text">
                          {loadingGoogle ? (
                            <div className="onboarding-social-loading">
                              <CircularProgress size={18} color="primary" />
                            </div>
                          ) : (
                            "Continuar pelo Google"
                          )}
                        </span>
                      </GoogleLoginButton>
                    </LoginSocialGoogle>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 d-flex align-items-center">
              <div className="login-main-right bg-white p-5 mt-5 mb-5 d-flex justify-content-center">
                {organization?.logo_prin ? (
                  <img
                    className="onboarding-img"
                    src={`${BASE_URL}${organization.logo_prin}`}
                    alt="Logomarca Organização"
                  />
                ) : (
                  <div style={{ height: 420 }}></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Onboarding;
