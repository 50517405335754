import React from "react";
import PropTypes from "prop-types";

// import "./styles.css";

const Advertising = ({ height, url }) => {
  return (
    <iframe
      width="100%"
      height={height}
      src={`${url?.replace("watch?v=", "embed/") || ""}?autoplay=1&mute=1`}
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
      title="Propaganda"
    ></iframe>
  );
};

Advertising.propTypes = {
  height: PropTypes.string,
  url: PropTypes.string,
};

Advertising.defaultProps = {
  height: "150",
  url: "",
};

export default Advertising;
