import React, { useState, useEffect } from "react";
import {
  SectionTitle,
  OrgLoading,
  SnackAlert,
  BuyHere,
} from "../../Template/Admin/components";
import { getOrganizationService } from "../../services/api";
import { BASE_URL } from "../../services/Ambiente";

import logoDefault from "../../assets/images/v1.png";

import "./styles.css";

const AboutScreen = () => {
  const [organization, setOrganization] = useState({});
  const [loading, setLoading] = useState(true);
  const [openModalBuyHere, setOpenModalBuyHere] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  useEffect(() => {
    getOrganizationInfo();
  }, []);

  async function getOrganizationInfo() {
    setLoading(true);

    getOrganizationService()
      .then((response) => {
        setLoading(false);

        if (response.data.return) {
          setOrganization(response.data.data[0]);
        } else {
          setOrganization(response.data.data);
        }
      })
      .catch((err) => {
        setAlert({ open: true, type: "error", title: err });
      });
  }

  function convertAbout() {
    let stringHtml = organization.sobre || "<p></p>";

    return <div dangerouslySetInnerHTML={{ __html: stringHtml }}></div>;
  }

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <div className="container-fluid pb-0 about-screen-container">
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <div className="top-category section-padding mb-4">
        <div className="row">
          <SectionTitle title="Organização" paddingLeft={false} />
        </div>
      </div>
      {loading ? (
        <div className="row">
          <div className="col-lg-5 col-md-5 about-screen-mb about-screen-loading">
            <OrgLoading />
          </div>
        </div>
      ) : (
        <div className="row about-screen-min-h">
          <div className="col-lg-5 col-md-5 about-screen-mb">
            <h6 className="text-dark">Nome da Organização:</h6>
            <p>{organization.nome_prin}</p>
            <h6 className="text-dark">Telefone:</h6>
            <p>{organization.telefone}</p>
            <h6 className="text-dark">Email:</h6>
            <p>{organization.email}</p>
            <h6 className="text-dark">Endereço:</h6>
            <p>{organization.endereco}</p>
            <h6 className="text-dark">Sobre:</h6>
            <div>{convertAbout()}</div>
          </div>
          <div className="col-lg-7 col-md-7 about-screen-img-container">
            <div className="card">
              <div className="card-body d-flex justify-content-center">
                <img
                  className="about-screen-img"
                  src={`${BASE_URL}${organization.logo_prin}` || logoDefault}
                  alt="Logo organização"
                />
              </div>
            </div>

            <br />
            <br />

            <button
              type="button"
              className="btn btn-outline-primary btn-block btn-lg green-bt"
              onClick={() => setOpenModalBuyHere(true)}
            >
              Seja Franqueado
            </button>
          </div>
        </div>
      )}

      <BuyHere
        open={openModalBuyHere}
        onClose={() => setOpenModalBuyHere(false)}
      />
    </div>
  );
};

export default AboutScreen;
