import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Moment from "moment";
import Grid from "@material-ui/core/Grid";

import { SectionTitle, DataTable, InputBasic } from "../../components";
import Filters from "../../../../utils/Filters";
import Row from "./Row";

import "./styles.css";

Moment.locale("pt-BR");

const MySalesList = ({ title, data }) => {
  const [search, setSearch] = useState("");
  const [listData, setListData] = useState([]);
  const [backup, setBackup] = useState([]);

  const filters = new Filters();

  useEffect(() => {
    if (data) {
      setListData(data);
      setBackup(data);
    } else setListData([]);
  }, [data]);

  useEffect(() => {
    if (search) {
      const filter = backup.filter((item) => filters.sales(item, search));
      setListData(filter);
    } else {
      setListData(backup);
    }
    // eslint-disable-next-line
  }, [search]);

  return (
    <div className="row my-shopping-list-container">
      {title && <SectionTitle title={title} paddingLeft={false} />}
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <InputBasic
            label="Filtro"
            value={search}
            onChange={(value) => setSearch(value)}
          />
        </Grid>
        <Grid item xs={0} md={8}>
          <div />
        </Grid>
        <Grid item xs={12} md={12}>
          <DataTable rows={listData} header={headers} rowComponent={Row} />
        </Grid>
      </Grid>
    </div>
  );
};

const headers = [
  "Evento",
  "Produto",
  "Status do Pagamento",
  "Valor Pago (R$)",
  "Data/Hora",
];

MySalesList.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
};

MySalesList.defaultProps = {
  data: [],
  title: "",
};

export default MySalesList;
