import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";

import { SnackAlert, SectionTitle } from "../../../components";

const FullLoading = ({ open, infoText, onClose }) => {
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  const handleClose = () => {
    onClose();
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
    >
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <DialogContent>
        <div id="modal-body">
          <Grid
            container
            spacing={1}
            className="d-flex align-items-center justify-content-center"
          >
            <Grid
              item
              xs={12}
              md={12}
              className="d-flex flex-column align-items-center"
            >
              <CircularProgress
                size={52}
                color="primary"
                style={{ marginBottom: 18 }}
              />
              <SectionTitle title={infoText} />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

FullLoading.propTypes = {
  open: PropTypes.bool,
  infoText: PropTypes.string,
  onClose: PropTypes.func,
};

FullLoading.defaultProps = {
  open: false,
  infoText: "",
  onClose: () => {},
};

export default FullLoading;
