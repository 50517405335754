import React from "react";
import PropTypes from "prop-types";
import { SectionTitle } from "../index";

import { BASE_URL } from "services/Ambiente"

import "./styles.css";

const RoomsList = ({ title, data, onSelected }) => {
  return (
    <div className="row video-list-container">
      <SectionTitle title={title} paddingLeft={false} />
      {data.map((item, i) => (
        <div
          key={String(i)}
          className="col-xl-3 col-sm-6 mb-3 mt-2"
          style={{ cursor: "pointer" }}
          onClick={(e) => onSelected(e, item)}
        >
          <div className="video-card">
            <div className="item"></div>
            <div className="video-card-image d-flex align-items-center">
              <img className="img-fluid img-destaque-sala" src={`${BASE_URL}/${item.imagem_destaque}`} alt="" />
              <div className="time" style={{ display: "none" }}>
                3:50
              </div>
            </div>
            <div className="video-card-body">
              <div className="video-title">{item.nome}</div>
              <div className="video-page text-success">{item.descricao}</div>
              <div className="video-view" style={{ display: "none" }}>
                1.8M views &nbsp;
                <i className="fas fa-calendar-alt"></i> 11 Months ago
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

RoomsList.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  onSelected: PropTypes.func,
};

RoomsList.defaultProps = {
  data: [],
  title: "",
  onSelected: (e, item) => {},
};

export default RoomsList;
