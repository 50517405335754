import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Moment from "moment";
import { CommentsLoading, SectionTitle } from "..";
import InsertComent from "./InsertComent";

import "./styles.css";

Moment.locale("pt-BR");

const ForumTab = ({
  messages,
  sendComment,
  loading,
  msgEmpty,
  idDono,
  deleteComment,
}) => {
  const [canDelete, setCanDelete] = useState(false);

  const loggedUser = JSON.parse(sessionStorage.getItem("currentUser"));

  useEffect(() => {
    if (loggedUser?.id === idDono) {
      setCanDelete(true);
    } else {
      setCanDelete(false);
    }
  }, [idDono, loggedUser]);

  if (loading) {
    return (
      <div className="box mb-3 single-video-comment-tabs mb-4">
        <CommentsLoading />
      </div>
    );
  }

  return (
    <div
      className="tab-pane fade show active"
      id="comments"
      role="tabpanel"
      aria-labelledby="comments-tab"
      style={{ overflow: "hidden" }}
    >
      <div className="blocoMensagensForum">
        {msgEmpty === "" ? (
          messages?.map((message, i) => (
            <div key={String(i)} className="reviews-members forum-msg-content">
              <div className="media">
                <div className="media-body">
                  <div className="reviews-members-header forum-msg-header">
                    <h6 className="mb-1 d-flex align-items-baseline">
                      <div className="text-black">{message.first_name}</div>{" "}
                      <small className="text-gray ml-2">{`${Moment(message.data)
                        .utc()
                        .format("DD/MM/YYYY")} - ${message.hora}`}</small>
                    </h6>
                  </div>
                  <div className="reviews-members-body">
                    <p className="mb-0">{message.mensagem}</p>
                  </div>
                </div>
              </div>
              {canDelete ? (
                loggedUser?.id === message.usuario_id ? (
                  <button
                    className="btn-delete-msg"
                    onClick={() => deleteComment(message)}
                  >
                    <i className="fas fa-fw fa-trash"></i>
                  </button>
                ) : (
                  <button
                    className="btn-delete-msg"
                    onClick={() => deleteComment(message)}
                  >
                    <i className="fas fa-fw fa-trash"></i>
                  </button>
                )
              ) : loggedUser?.id === message.usuario_id ? (
                <button
                  className="btn-delete-msg"
                  onClick={() => deleteComment(message)}
                >
                  <i className="fas fa-fw fa-trash"></i>
                </button>
              ) : (
                <div></div>
              )}
            </div>
          ))
        ) : (
          <div className="reviews-members">
            <SectionTitle title={msgEmpty} />
          </div>
        )}
      </div>
      <InsertComent
        loggedUser={loggedUser}
        numberOfMsgs={messages.length}
        sendComment={(value) => sendComment(value)}
      />
    </div>
  );
};

ForumTab.propTypes = {
  messages: PropTypes.array,
  msgEmpty: PropTypes.string,
  loading: PropTypes.bool,
  idDono: PropTypes.string,
  sendComment: PropTypes.func,
  deleteComment: PropTypes.func,
};

ForumTab.defaultProps = {
  messages: [],
  msgEmpty: "",
  idDono: "",
  loading: true,
  sendComment: (comment) => {},
  deleteComment: (comment) => {},
};

export default ForumTab;
