import React from "react";
import PropTypes from "prop-types";

// import "./styles.css";

const Select = ({ label, name, value, options, required, onChange }) => {
  return (
    <div className="form-group">
      <div className="d-flex flex-row align-items-start">
        <label>{label}</label>
        {required && <span className="text-danger ml-1">*</span>}
      </div>
      <select
        required={required}
        className="form-control"
        name={name}
        value={value}
        onChange={onChange}
      >
        <option value="" key="">
          Selecione uma opção
        </option>
        {options.map((option, i) => (
          <option value={option.value} key={String(i + 1)}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

Select.defaultProps = {
  label: "",
  name: "",
  value: "",
  options: [],
  required: false,
  onChange: (e) => {},
};

export default Select;
