import axios from "axios";
import { BASE_URL, BASE_NODE_URL } from "./Ambiente";

const api = axios.create({
  baseURL: `${BASE_URL}/api`,
  timeout: 10000,
});

const upload = axios.create({
  baseURL: `${BASE_URL}/api`,
  timeout: 3600000,
});

/**
 *
 * @param {FormData} data
 */
const loginRequest = async (data) => {
  return await api.post("/login", data);
};

const getOrganizationService = async () => {
  return await api.get("/organizacao");
};

const getPublicRoomsService = async () => {
  return await api.get("/salas_publicas");
};

const getPublicRoomsPastService = async () => {
  return await api.get("/salas_publicas_passadas");
};

const getBannersNoticesService = async () => {
  return await api.get("/noticias");
};

/**
 *
 * @param {String} id
 */
const getPrivateRoomsService = async (id) => {
  return await api.get(`/salas_privadas/${id}`);
};

/**
 *
 * @param {String} id
 */
const getMyRoomsService = async (id) => {
  return await api.get(`/minhas_salas/${id}`);
};

/**
 *
 * @param {String} id
 */
const getMyOwnRoomsService = async (id) => {
  return await api.get(`/minhas_salas_dono/${id}`);
};

/**
 *
 * @param {String} userId
 * @param {String} roomId
 */
const getRoomService = async (userId, roomId) => {
  return await api.get(`/visualizar_sala/${userId}/${roomId}`);
};

/**
 *
 * @param {String} id
 * @param {String} date
 */
const getRoomMessagesService = async (id, date) => {
  if (date) {
    return await api.get(`/mensagens_forum/${id}/${date}`);
  } else {
    return await api.get(`/mensagens_forum/${id}`);
  }
};

const insertMessageForumService = async (data) => {
  return await api.post("/inserir_mensagem_forum", data);
};

/**
 *
 * @param {String} id
 */
const getRoomDatesService = async (id) => {
  return await api.get(`/datas_sala/${id}`);
};

/**
 *
 * @param {String} id
 * @param {String} date
 * @param {String} grav_id
 */
const getRoomRecordsService = async (id, date, grav_id) => {
  if (date) {
    return await api.get(`/gravacoes_sala/${id}/${date}/${grav_id}`);
  } else {
    return await api.get(`/gravacoes_sala/${id}`);
  }
};

/**
 *
 * @param {String} id
 */
const getBestMomentsRecordService = async (salaId, recordId) => {
  return await api.get(`/melhores_momentos_gravacao/${salaId}/${recordId}`);
};

/**
 *
 * @param {*} data
 */
const insertBestMomentService = async (data) => {
  return await api.post("/inserir_melhor_momento", data);
};

const getBestMomentsHomeService = async () => {
  return await api.get("/melhores_momentos_home");
};

/**
 *
 * @param {*} data
 */
const requestBestMoment = async (data) => {
  return await api.post("/solicitar_melhor_momento", data);
};

/**
 *
 * @param {*} data
 */
const deleteMessageService = async (data) => {
  return await api.post("/desativar_mensagem_forum", data);
};

/**
 *
 * @param {*} data
 */
const addLikeDeslikeService = async (data) => {
  return await api.post("/add_like_deslike_melhor_momento", data);
};

/**
 *
 * @param {*} data
 */
const removeLikeDeslikeService = async (data) => {
  return await api.post("/remove_like_deslike_melhor_momento", data);
};

/**
 *
 * @param {*} data
 */
const removeBestMomentService = async (data) => {
  return await api.post("/desativar_melhor_momento", data);
};

/**
 *
 * @param {String} id
 */
const getRoomUsersService = async (id) => {
  return await api.get(`/users_adicionados_sala/${id}`);
};

/**
 *
 * @param {String} id
 */
const getCanAddRoomUsersService = async (id) => {
  return await api.get(`/users_disponiveis_sala/${id}`);
};

/**
 *
 * @param {*} data
 */
const addRoomUserService = async (data) => {
  return await api.post("/adicionar_usuario_sala", data);
};

/**
 *
 * @param {*} data
 */
const deleteRoomUserService = async (data) => {
  return await api.post("/desativar_usuario_sala", data);
};

/**
 *
 * @param {*} data
 */
const onboardingService = async (data) => {
  return await api.post("/cadastrar_usuario", data);
};

/**
 *
 * @param {*} data
 */
const onboardingSocialService = async (data) => {
  return await api.post("/cadastrar_usuario_social", data);
};

/**
 *
 * @param {*} data
 */
const forgotPasswordService = async (data) => {
  return await api.post("/esqueci_minha_senha", data);
};

/**
 *
 * @param {String} id
 */
const getProfileService = async (id) => {
  return await api.get(`/perfil_usuario/${id}`);
};

/**
 *
 * @param {*} data
 */
const changeUserDataService = async (data) => {
  return await api.post("/alterar_dados_usuario", data);
};

/**
 *
 * @param {*} data
 */
const changePasswordService = async (data) => {
  return await api.post("/alterar_senha", data);
};

/**
 *
 * @param {*} data
 */
const searchRoomService = async (data) => {
  return await api.post("/buscar_salas", data);
};

/**
 *
 * @param {String} id
 */
const getDownloadVideo = async (urlVideo) => {
  return await api.get(BASE_NODE_URL + "/watch?v=" + urlVideo);
};

/**
 *
 * @param {*} data
 */
const contrateAquiService = async (data) => {
  return await api.post("/contrate_aqui", data);
};

const getChannels = async () => {
  return await api.get("/lista_canais");
};

/**
 *
 * @param {*} data
 */
const requestChannel = async (data) => {
  return await api.post("/solicitacao_canal", data);
};

/**
 *
 * @param {String} id
 */
const getChannelContent = async (id) => {
  return await api.get(`/lista_conteudo_canais/${id}`);
};

/**
 *
 * @param {String} userId
 */
const getShowMyChannel = async (userId) => {
  return await api.get(`/verificar_canal/${userId}`);
};

/**
 *
 * @param {String} channelId
 */
const getMyChannelContentList = async (channelId) => {
  return await api.get(`/lista_conteudo_canais/${channelId}`);
};

/**
 *
 * @param {String} userId
 * @param {String} channelId
 * @param {String} contentId
 */
const disableContentChannel = async (userId, channelId, contentId) => {
  return await api.get(
    `/desativar_conteudo/${userId}/${channelId}/${contentId}`
  );
};

/**
 *
 * @param {*} data
 */
const updateMyChannel = async (data) => {
  return await api.post("/editar_canal", data);
};

/**
 *
 * @param {*} data
 */
const createMyChannelContent = async (data) => {
  return await upload.post("/upload_conteudo_canal", data);
};

/**
 *
 * @param {*} data
 */
const editMyChannelContent = async (data) => {
  return await upload.post("/editar_conteudo_canal", data);
};

/**
 *
 * @param {String} userId
 * @param {String} email
 */
const getMyShopping = async (userId, email) => {
  return await api.get(`/minhas_compras/${userId}/${email}/`);
};

/**
 *
 * @param {String} userId
 * @param {String} contentId
 */
const requestContentDownload = async (userId, contentId) => {
  return await api.get(`/solicitar_download/${userId}/${contentId}/`);
};

/**
 *
 * @param {String} userId
 * @param {String} email
 */
const getMySales = async (userId, email) => {
  return await api.get(`/minhas_vendas/${userId}/${email}/`);
};

/**
 *
 * @param {*} data
 */
const validateTicket = async (data) => {
  return await api.post("/validar_ingresso", data);
};

/**
 *
 * @param {String} eventId
 */
const getEvent = async (eventId) => {
  return await api.get(`/dados_canal/${eventId}`);
};

/**
 *
 * @param {*} data
 */
const createCupom = async (data) => {
  return await upload.post("/criar_cupom_conteudo", data);
};

/**
 *
 * @param {String} userId
 * @param {String} contentId
 */
const getEventCoupons = async (userId, contentId) => {
  return await api.get(`/meus_cupons/${userId}/${contentId}`);
};

/**
 *
 * @param {*} data
 */
const updateCouponStatus = async (data) => {
  return await upload.post("/atualizar_status_cupom", data);
};

/**
 *
 * @param {*} data
 */
const sendGift = async (data) => {
  return await upload.post("/presentear_conteudo", data);
};

/**
 *
 * @param {*} data
 */
const getTickets = async (data) => {
  return await api.post("/visualizar_ingressos", data);
};

export {
  loginRequest,
  getOrganizationService,
  getPublicRoomsService,
  getPublicRoomsPastService,
  getBannersNoticesService,
  getPrivateRoomsService,
  getMyRoomsService,
  getMyOwnRoomsService,
  getRoomService,
  getRoomMessagesService,
  insertMessageForumService,
  getRoomDatesService,
  getRoomRecordsService,
  getBestMomentsRecordService,
  insertBestMomentService,
  getBestMomentsHomeService,
  deleteMessageService,
  addLikeDeslikeService,
  removeLikeDeslikeService,
  removeBestMomentService,
  getRoomUsersService,
  getCanAddRoomUsersService,
  addRoomUserService,
  deleteRoomUserService,
  onboardingService,
  onboardingSocialService,
  forgotPasswordService,
  getProfileService,
  changeUserDataService,
  changePasswordService,
  searchRoomService,
  getDownloadVideo,
  contrateAquiService,
  requestBestMoment,
  getChannels,
  requestChannel,
  getChannelContent,
  getShowMyChannel,
  getMyChannelContentList,
  disableContentChannel,
  updateMyChannel,
  createMyChannelContent,
  getMyShopping,
  requestContentDownload,
  getMySales,
  validateTicket,
  getEvent,
  createCupom,
  getEventCoupons,
  updateCouponStatus,
  sendGift,
  editMyChannelContent,
  getTickets,
};
