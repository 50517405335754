import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";
import VMasker from "vanilla-masker";
import Moment from "moment";

import {
  CustomButton,
  SnackAlert,
  InputBasic,
  FullLoading,
  Select,
} from "../../../components";
import { createMyChannelContent } from "../../../../../services/api";

Moment.locale("pt-BR");

const TYPE_FILES =
  ".gif, .jpg, .jpeg, .png, .wmv, .mp4, .wav, .avi, .mov, .mpg, .mp3, .mkv, .zip, .rar";

const CreateContent = ({ open, myChannel, onClose, onSubmit }) => {
  const [fileFull, setFileFull] = useState("");
  const [filePreview, setFilePreview] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    titulo: "",
    descricao: "",
    valor: "",
    tipo_conteudo: "",
    gratuito: "",
    conteudo_completo: "",
    conteudo_preview: "",
    quantidade_maxima: "",
    endereco: "",
    data_expiracao: "",
    vendas_cpf: "",
    quantidade_lotes: "",
  });
  const [lots, setLots] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  useEffect(() => {
    handleQtdLots();
    // eslint-disable-next-line
  }, [formData.tipo_conteudo, formData.quantidade_lotes]);

  useEffect(() => {
    if (formData.tipo_conteudo === "Ingresso") {
      setFormData({ ...formData, valor: "" });
    }
    // eslint-disable-next-line
  }, [formData.tipo_conteudo]);

  const resetFields = () => {
    setFormData({
      titulo: "",
      descricao: "",
      valor: "",
      tipo_conteudo: "",
      gratuito: "",
      conteudo_completo: "",
      conteudo_preview: "",
      quantidade_maxima: "",
      endereco: "",
      data_expiracao: "",
      vendas_cpf: "",
      quantidade_lotes: "",
    });
    setFileFull("");
    setFilePreview("");
    setLots([]);
  };

  const handleChangeForm = (value, e) => {
    const newList = Object.assign({}, formData, {
      [e.target.name]: value,
    });
    setFormData(newList);
  };

  const handleQtdLots = () => {
    if (formData.tipo_conteudo === "Ingresso") {
      const aux = [];
      const qtd = Number(formData.quantidade_lotes);

      for (let i = 0; i < qtd; i++) {
        aux.push({
          id: i + 1,
          data_inicio_lote: "",
          data_final_lote: "",
          valor_lote: "",
        });
      }

      setLots(aux);
    } else setLots([]);
  };

  const handleChangeFormFields = (value, e, i) => {
    const newFields = Object.assign({}, lots[i], {
      [e.target.name]: value,
    });

    const arrAux = [...lots];

    const newArr = arrAux.map((item) => {
      if (item.id === newFields.id) return newFields;
      else return item;
    });

    setLots(newArr);
  };

  const handleFile = (e, type) => {
    e.preventDefault();

    const reader = new FileReader();

    reader.onload = async (e) => {};
    if (type === "preview") setFilePreview(e.target.files[0]);
    else setFileFull(e.target.files[0]);

    if (e.target.files?.[0]?.name) reader.readAsArrayBuffer(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const loggeduser = JSON.parse(sessionStorage.getItem("currentUser"));

    const newData = new FormData();
    Object.keys(formData).forEach((item) => {
      newData.append(String(item), formData[item]);
    });

    newData.delete("conteudo_completo");
    newData.delete("conteudo_preview");
    if (formData.tipo_conteudo === "Ingresso") {
      newData.delete("valor");
      newData.append("valor", lots?.[0]?.valor_lote || "");
      newData.append("lotes_ingressos", lots);
    }
    newData.append("usuario_id", loggeduser?.id);
    newData.append("canal_id", myChannel?.id);
    newData.append("conteudo_completo", fileFull);
    newData.append("conteudo_preview", filePreview);

    /* for (let value of newData.entries()) {
      console.log(`Campo ${value[0]} - Valor: ${JSON.stringify(value[1])}`);
    } */

    createMyChannelContent(newData)
      .then((response) => {
        const action = response.data.return ? "success" : "warning";
        setAlert({
          open: true,
          type: action,
          title: response.data.data,
        });

        if (response.data.return) {
          setTimeout(() => {
            onSubmit();
          }, 3000);
        }
      })
      .catch((err) => {
        setAlert({ open: true, type: "error", title: err });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLotsQts = () => {
    const aux = [];
    for (let i = 0; i < 5; i++) {
      aux.push({
        value: String(i + 1),
        label: String(i + 1),
      });
    }
    return aux;
  };

  const handleClose = () => {
    onClose();
    resetFields();
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
    >
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <DialogTitle id="modal-title">{"Inserir Produto"}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <div id="modal-body">
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <InputBasic
                  required
                  label="Título"
                  name="titulo"
                  maxLength={100}
                  placeholder="Insira o título"
                  value={formData.titulo}
                  onChange={handleChangeForm}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputBasic
                  required
                  label="Descrição"
                  name="descricao"
                  maxLength={1000}
                  placeholder="Insira uma descrição"
                  value={formData.descricao}
                  onChange={handleChangeForm}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputBasic
                  required={formData.tipo_conteudo !== "Ingresso"}
                  label="Valor Global"
                  name="valor"
                  placeholder="Insira um valor"
                  value={formData.valor}
                  onChange={(value, e) => {
                    const newValue = VMasker.toMoney(value);
                    handleChangeForm(newValue, e);
                  }}
                  disabled={formData.tipo_conteudo === "Ingresso"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputBasic
                  required
                  label="Quantidade Máxima"
                  maxLength={10}
                  name="quantidade_maxima"
                  placeholder="Insira uma quantidade"
                  value={formData.quantidade_maxima}
                  onChange={(value, e) => {
                    const newValue = VMasker.toNumber(value);
                    handleChangeForm(newValue, e);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputBasic
                  required
                  label="Data de Expiração"
                  name="data_expiracao"
                  placeholder="Insira a data de expiração"
                  type="date"
                  min={Moment()
                    .utc(true)
                    .format("YYYY-MM-DD")}
                  value={formData.data_expiracao}
                  onChange={handleChangeForm}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputBasic
                  label="Endereço"
                  maxLength={200}
                  name="endereco"
                  placeholder="Insira o endereço"
                  value={formData.endereco}
                  onChange={(value, e) => handleChangeForm(value, e)}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Select
                  label="Gratuito?"
                  options={[
                    {
                      label: "Sim",
                      value: "Sim",
                    },
                    {
                      label: "Não",
                      value: "Não",
                    },
                  ]}
                  name="gratuito"
                  value={formData.gratuito}
                  onChange={(e) => handleChangeForm(e.target.value, e)}
                  required
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Select
                  label="Mais de uma venda por CPF?"
                  options={[
                    {
                      label: "Sim",
                      value: "Sim",
                    },
                    {
                      label: "Não",
                      value: "Não",
                    },
                  ]}
                  name="vendas_cpf"
                  value={formData.vendas_cpf}
                  onChange={(e) => handleChangeForm(e.target.value, e)}
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  label="Tipo do Produto"
                  options={[
                    {
                      label: "Imagem",
                      value: "Imagem",
                    },
                    {
                      label: "Vídeo",
                      value: "Vídeo",
                    },
                    {
                      label: "Áudio",
                      value: "Áudio",
                    },
                    {
                      label: "Arquivo",
                      value: "Arquivo",
                    },
                    {
                      label: "Ingresso",
                      value: "Ingresso",
                    },
                  ]}
                  name="tipo_conteudo"
                  value={formData.tipo_conteudo}
                  onChange={(e) => handleChangeForm(e.target.value, e)}
                  required
                />
              </Grid>
              {formData.tipo_conteudo === "Ingresso" && (
                <Grid item xs={12} md={12}>
                  <Select
                    label="Quantidade de Lotes"
                    options={getLotsQts()}
                    name="quantidade_lotes"
                    value={formData.quantidade_lotes}
                    onChange={(e) => handleChangeForm(e.target.value, e)}
                    required
                  />
                </Grid>
              )}
              {formData.tipo_conteudo === "Ingresso" &&
                Number(formData.quantidade_lotes) > 0 && (
                  <Grid item xs={12} md={12}>
                    {lots.map((item, i) => (
                      <div className="group_ingress" key={String(i)}>
                        <h6>{`Lote ${i + 1}`}</h6>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={4} className="mt-3 input_buy">
                            <InputBasic
                              label="Data Início"
                              name="data_inicio_lote"
                              type="date"
                              min={Moment()
                                .utc(true)
                                .format("YYYY-MM-DD")}
                              value={lots[i].data_inicio_lote}
                              onChange={(value, e) =>
                                handleChangeFormFields(value, e, i)
                              }
                              required
                            />
                          </Grid>
                          <Grid item xs={12} md={4} className="mt-3 input_buy">
                            <InputBasic
                              label="Data Final"
                              name="data_final_lote"
                              type="date"
                              min={Moment(
                                lots[i].data_inicio_lote
                                  ? lots[i].data_inicio_lote
                                  : null
                              )
                                .utc(true)
                                .format("YYYY-MM-DD")}
                              value={lots[i].data_final_lote}
                              onChange={(value, e) =>
                                handleChangeFormFields(value, e, i)
                              }
                              required
                            />
                          </Grid>
                          <Grid item xs={12} md={4} className="mt-3 input_buy">
                            <InputBasic
                              label="Valor"
                              placeholder="Insira o valor do lote"
                              name="valor_lote"
                              type="tel"
                              value={lots[i].valor_lote}
                              onChange={(value, e) => {
                                const maskedValue = VMasker.toMoney(value);
                                handleChangeFormFields(maskedValue, e, i);
                              }}
                              required
                            />
                          </Grid>
                        </Grid>
                        <hr />
                      </div>
                    ))}
                  </Grid>
                )}
              <Grid item xs={12} md={12}>
                <InputBasic
                  type="file"
                  label="Preview do Produto"
                  name="conteudo_preview"
                  value={formData.conteudo_preview}
                  onChange={(value, e) => {
                    handleFile(e, "preview");
                    handleChangeForm(value, e);
                  }}
                  accept={TYPE_FILES}
                  required
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputBasic
                  type="file"
                  label="Produto Completo"
                  name="conteudo_completo"
                  value={formData.conteudo_completo}
                  onChange={(value, e) => {
                    handleFile(e, "full");
                    handleChangeForm(value, e);
                  }}
                  accept={TYPE_FILES}
                  required
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleClose}>Fechar</CustomButton>
          <CustomButton type="submit" disabled={loading}>
            {loading ? <CircularProgress size={20} /> : <div>Enviar</div>}
          </CustomButton>
        </DialogActions>
      </form>
      <FullLoading
        open={loading}
        infoText="Por favor, aguarde enquanto fazemos o upload dos arquivos."
      />
    </Dialog>
  );
};

CreateContent.propTypes = {
  open: PropTypes.bool,
  myChannel: PropTypes.any,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

CreateContent.defaultProps = {
  open: false,
  myChannel: {},
  onClose: () => {},
  onSubmit: () => {},
};

export default CreateContent;
