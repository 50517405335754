import React from "react";
import PropTypes from "prop-types";
import { convertMilToMin } from "../../../../utils";
import { SectionTitle } from "../../../../Template/Admin/components";

import { BASE_URL } from "services/Ambiente"

import "./styles.css";

const BMContainer = ({ moments, title, onSelected }) => {
  return (
    <div className="row bm-container">
      <SectionTitle title={title} paddingLeft={false} />
      {moments.map((item, i) => (
        <div
          key={String(i)}
          className="col-xl-3 col-sm-6 mb-3 mt-2"
          style={{ cursor: "pointer" }}
          onClick={(e) => onSelected(e, item)}
        >
          <div className="video-card">
            <div className="item"></div>
            <div className="video-card-image d-flex align-items-center">
            <img className="img-fluid img-destaque-sala" src={`${BASE_URL}/${item.imagem_destaque}`} alt="" />
              <div className="time">
                {convertMilToMin(item.intervalo_inicial, item.intervalo_final)}
              </div>
            </div>
            <div className="video-card-body">
              <div className="video-title">{item.titulo}</div>
              <div className="video-view">
                <div className="d-flex flex-row">
                  <div className="bm-container-like mr-2">
                    <i className="fas fa-thumbs-up"></i> {item.like}
                  </div>{" "}
                  <div className="bm-container-like">
                    <i className="fas fa-thumbs-down"></i> {item.deslike}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

BMContainer.propTypes = {
  moments: PropTypes.array,
  title: PropTypes.string,
  onSelected: PropTypes.func,
};

BMContainer.defaultProps = {
  moments: [],
  title: "",
  onSelected: (e, item) => {},
};

export default BMContainer;
