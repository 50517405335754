import React, { useState, useEffect } from "react";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import {
  SectionTitle,
  SnackAlert,
  ProfileLoading,
} from "../../Template/Admin/components";
import { getProfileService, changeUserDataService } from "../../services/api";
import Form from "./containers/Form";

import "./styles.css";

Moment.locale("pt-BR");

const EditProfileScreen = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  const history = useHistory();

  useEffect(() => {
    getProfile();
  }, []);

  function getProfile() {
    setLoading(true);

    const loggedUser = JSON.parse(sessionStorage.getItem("currentUser"));

    getProfileService(loggedUser.id)
      .then((response) => {
        setLoading(false);

        if (response.data.return) {
          setUser(response.data.data[0]);
        } else {
          setAlert({ open: true, type: "warning", title: response.data.data });
        }
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ open: true, type: "error", title: err });
      });
  }

  function onChangeDataUser(data) {
    setLoadingRequest(true);

    const loggedUser = JSON.parse(sessionStorage.getItem("currentUser"));

    const newData = new FormData();
    newData.append("user_id", loggedUser?.id);
    newData.append("email", data.email);
    newData.append("username", data.username);
    newData.append("first_name", data.firstName);
    newData.append("last_name", data.lastName);
    newData.append("celular", data.cellphone);
    newData.append("telefone", data.phone);
    newData.append("local", data.city);
    newData.append("nascimento", data.birthday);

    changeUserDataService(newData)
      .then((response) => {
        setLoadingRequest(false);

        if (response.data.return) {
          setAlert({ open: true, type: "success", title: response.data.data });
          setTimeout(() => {
            history.push("/home/perfil");
          }, 3000);
        } else {
          setAlert({ open: true, type: "warning", title: response.data.data });
        }
      })
      .catch((err) => {
        setLoadingRequest(false);
        setAlert({ open: true, type: "error", title: err });
      });
  }

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  if (loading) {
    return (
      <div className="container-fluid pb-0 edit-profile-screen-container">
        <ProfileLoading />
      </div>
    );
  }

  return (
    <div className="container-fluid pb-0 edit-profile-screen-container">
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <div className="top-category section-padding mb-4">
        <div className="row pl-0">
          <div className="col-lg-8 col-md-8">
            <SectionTitle title="Editar Perfil" />
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 mb-5 pl-0 d-flex flex-column">
        <Form
          data={user}
          loading={loadingRequest}
          onSubmit={onChangeDataUser}
        />
      </div>
    </div>
  );
};

export default EditProfileScreen;
