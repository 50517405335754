import React from "react";
import PropTypes from "prop-types";
import { RoomsList, SectionTitle } from "../../components";

// import "./styles.css";

const RoomsContainer = ({ rooms, empty, msgEmpty, title, selectedRoom }) => {
  return (
    <>
      {empty || msgEmpty ? (
        <>
          <SectionTitle title={title} />
          <div className="col-md-12 mb-3 pl-0" style={{ minHeight: 140 }}>
            <div className="main-title">
              <span>{msgEmpty}</span>
            </div>
          </div>
        </>
      ) : (
        <div className="video-block section-padding">
          <RoomsList
            title={title}
            data={rooms}
            onSelected={(e, item) => selectedRoom(e, item)}
          />
        </div>
      )}
    </>
  );
};

RoomsContainer.propTypes = {
  rooms: PropTypes.array,
  empty: PropTypes.bool,
  msgEmpty: PropTypes.string,
  title: PropTypes.string,
  selectedRoom: PropTypes.func,
};

RoomsContainer.defaultProps = {
  rooms: [],
  empty: false,
  msgEmpty: "",
  title: "",
  selectedRoom: (e, item) => {},
};

export default RoomsContainer;
