import React from "react";
import PropTypes from "prop-types";
import { MySalesList } from "../../components";

const MySalesContainer = ({ sales, empty, msgEmpty }) => {
  return (
    <>
      {empty ? (
        <div className="col-md-12 mb-3 pl-0" style={{ minHeight: 140 }}>
          <div className="main-title">
            <span>{msgEmpty}</span>
          </div>
        </div>
      ) : (
        <div className="video-block section-padding">
          <MySalesList title="" data={sales} />
        </div>
      )}
    </>
  );
};

MySalesContainer.propTypes = {
  sales: PropTypes.array,
  empty: PropTypes.bool,
  msgEmpty: PropTypes.string,
};

MySalesContainer.defaultProps = {
  sales: [],
  empty: false,
  msgEmpty: "",
};

export default MySalesContainer;
