import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";

import { SectionTitle } from "../index";
import { BASE_URL } from "services/Ambiente";

import "./styles.css";

const ChannelsList = ({ title, data, onSelected }) => {
  return (
    <div className="row video-list-container">
      {title && <SectionTitle title={title} paddingLeft={false} />}
      {data.map((item, i) => (
        <div
          key={String(i)}
          className="col-xl-3 col-sm-6 mb-3 mt-2"
          style={{ cursor: "pointer" }}
          onClick={(e) => onSelected(e, item)}
        >
          <div className="video-card">
            <div className="item"></div>
            <div className="video-card-image d-flex align-items-center">
              <img
                className="img-fluid video-img-destaque"
                src={`${BASE_URL}/${item.imagem_destaque}`}
                alt=""
              />
            </div>
            <div className="video-card-body">
              <div className="video-title">{item.titulo}</div>
              <div className="video-page">{parse(item.descricao)}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

ChannelsList.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  onSelected: PropTypes.func,
};

ChannelsList.defaultProps = {
  data: [],
  title: "",
  onSelected: (e, item) => {},
};

export default ChannelsList;
