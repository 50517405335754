import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

// import "./styles.css";

const CustomButton = ({
  children,
  title,
  onClick,
  color,
  showBorder,
  disabled,
  type,
}) => {
  const BTN = withStyles(() => ({
    root: {
      color: color,
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "#e8e8e8",
      },
      minWidth: 40,
      minHeight: 35,
      textTransform: "none",
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 14,
      display: "flex",
      flexDirection: "row",
      borderRadius: 8,
      marginLeft: 4,
      border: showBorder ? "1px solid #ff253a" : "unset",
    },
  }))(Button);

  return (
    <BTN type={type} title={title} onClick={onClick} disabled={disabled}>
      {children}
    </BTN>
  );
};

CustomButton.propTypes = {
  children: PropTypes.any.isRequired,
  color: PropTypes.string,
  title: PropTypes.string,
  showBorder: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

CustomButton.defaultProps = {
  color: "#ff516b",
  title: "",
  showBorder: false,
  disabled: false,
  type: "button",
  onClick: () => {},
};

export default CustomButton;
