import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Grid, Checkbox, FormGroup } from "@material-ui/core";
import parse from "html-react-parser";
import VMasker from "vanilla-masker";

import { CustomButton, SnackAlert, InputBasic } from "../../../components";
import { convertMoneyToFloat } from "../../../../../utils";
import { BASE_URL } from "services/Ambiente";

import "./styles.css";

const URL_TERMS =
  "https://docs.google.com/document/d/1Dy7w5df5nwujc_oI0q09U-Mgd2vNPN1TA0RK_0qs83o/edit?usp=sharing";

const ContentBuy = ({ open, selectedContent, onClose }) => {
  const [url, setUrl] = useState("");
  const [userLogged, setUserLogged] = useState({});
  const [checked, setChecked] = useState(false);
  const [total, setTotal] = useState(0);
  const [serviceTax, setServiceTax] = useState(0);
  const [formData, setFormData] = useState({
    descricao_canal: "",
    descricao_conteudo: "",
    gratuito: "",
    tipo_conteudo: "",
    titulo_canal: "",
    titulo_conteudo: "",
    valor: "",
    quantidade: "",
    cupom_desconto: "",
  });
  const [invitations, setInvitations] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  useEffect(() => {
    if (!open) {
      resetFields();
    } else {
      const loggedUser = JSON.parse(sessionStorage.getItem("currentUser"));
      if (loggedUser) setUserLogged(loggedUser);
      setUrl(`${BASE_URL}/api/efetuar_pagamento_conteudo`);
      const item = selectedContent;
      setFormData({
        descricao_canal: item.descricao_canal,
        descricao_conteudo: item.descricao_conteudo,
        gratuito: item.gratuito,
        tipo_conteudo: item.tipo_conteudo,
        titulo_canal: item.titulo_canal,
        titulo_conteudo: item.titulo_conteudo,
        valor: item.valor,
      });
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (formData.valor && formData.quantidade) {
      const SERVICE_TAX = selectedContent?.taxa_admin
        ? parseFloat(selectedContent?.taxa_admin) / 100
        : 1;
      const t = Number(formData.quantidade) * getFloatNumber(formData.valor);
      setTotal(VMasker.toMoney(t.toFixed(2)));
      setServiceTax(VMasker.toMoney((t + t * SERVICE_TAX).toFixed(2)));
    } else {
      setTotal(0);
      setServiceTax(0);
    }

    handleQtdInvitations();
    // eslint-disable-next-line
  }, [formData.quantidade, formData.valor]);

  const getFloatNumber = (value) => {
    return parseFloat(convertMoneyToFloat(value));
  };

  const handleQtdInvitations = () => {
    if (formData.quantidade > 0) {
      const aux = [];
      const qtd = Number(formData.quantidade);

      for (let i = 0; i < qtd; i++) {
        aux.push({
          id: i + 1,
          nome: "",
          cpf: "",
          email: "",
        });
      }

      setInvitations(aux);
    } else setInvitations([]);
  };

  const handleChangeFormFields = (value, e, i) => {
    const newFields = Object.assign({}, invitations[i], {
      [e.target.name]: value,
    });

    const arrAux = [...invitations];

    const newArr = arrAux.map((item) => {
      if (item.id === newFields.id) {
        return newFields;
      } else {
        return item;
      }
    });

    setInvitations(newArr);
  };

  const resetFields = () => {
    setFormData({
      descricao_canal: "",
      descricao_conteudo: "",
      gratuito: "",
      tipo_conteudo: "",
      titulo_canal: "",
      titulo_conteudo: "",
      valor: "",
      quantidade: "",
      cupom_desconto: "",
    });
    setInvitations([]);
  };

  const handleChangeForm = (value, e) => {
    const newList = Object.assign({}, formData, {
      [e.target.name]: value,
    });
    setFormData(newList);
  };

  const handleClose = () => {
    onClose();
    resetFields();
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
    >
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <DialogTitle id="modal-title">{"Comprar Produto"}</DialogTitle>
      <DialogContent>
        <div id="modal-body">
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <div className="content-buy-mt">
                <h6 className="text-dark">Título do Evento:</h6>
                <span className="content-buy-txt">
                  {formData?.titulo_canal}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="content-buy-mt">
                <h6 className="text-dark">Descrição do Evento:</h6>
                <span className="content-buy-txt">
                  {parse(formData?.descricao_canal)}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="content-buy-mt">
                <h6 className="text-dark">Título do Produto:</h6>
                <span className="content-buy-txt">
                  {formData?.titulo_conteudo}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="content-buy-mt">
                <h6 className="text-dark">Descrição do Produto:</h6>
                <span className="content-buy-txt">
                  {parse(formData?.descricao_conteudo)}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="content-buy-mt">
                <h6 className="text-dark">Endereço:</h6>
                <span className="content-buy-txt">
                  {formData?.endereco || "-"}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="content-buy-mt">
                <h6 className="text-dark">Tipo do Produto:</h6>
                <span className="content-buy-txt">
                  {formData?.tipo_conteudo}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="content-buy-mt">
                <h6 className="text-dark">Gratuito?</h6>
                <span className="content-buy-txt">{formData?.gratuito}</span>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="content-buy-mt">
                <h6 className="text-dark">Valor Unitário:</h6>
                <span className="content-buy-txt">
                  {formData?.valor || "-"}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="content-buy-mt">
                <h6 className="text-dark">Quantidade X Valor Unitário:</h6>
                <span className="content-buy-txt">{total}</span>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="content-buy-mt">
                <h6 className="text-dark">Valor com a Taxa de Serviço*:</h6>
                <span className="content-buy-txt">{serviceTax}</span>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="content-buy-mt">
                <span className="content-buy-txt">
                  * Será cobrado {selectedContent?.taxa_admin || "0"}% da taxa
                  de serviço.
                </span>
              </div>
            </Grid>
            <form action={url} method="post">
              <InputBasic
                className="d-none"
                type="hidden"
                name="user_id"
                value={userLogged?.id}
              />
              <InputBasic
                className="d-none"
                type="hidden"
                name="conteudo_id"
                value={selectedContent.conteudo_id}
              />
              <InputBasic
                className="d-none"
                type="hidden"
                name="ingressos"
                value={JSON.stringify(invitations)}
              />
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} className="mt-3">
                  <InputBasic
                    label="Quantidade"
                    placeholder="Máximo de 10"
                    maxLength={2}
                    name="quantidade"
                    value={formData.quantidade}
                    onChange={(value, e) => {
                      const maskedValue = VMasker.toNumber(value);
                      if (Number(maskedValue) <= 10)
                        handleChangeForm(maskedValue, e);
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} className="mt-3">
                  <InputBasic
                    label="Cupom de desconto"
                    placeholder="Insira código do cupom"
                    name="cupom_desconto"
                    value={formData.cupom_desconto}
                    onChange={(value, e) => handleChangeForm(value, e)}
                  />
                </Grid>
                {formData.quantidade > 0 &&
                  formData.tipo_conteudo === "Ingresso" && (
                    <Grid item xs={12} md={12}>
                      {invitations.map((item, i) => (
                        <div className="group_ingress" key={String(i)}>
                          <h6>{`Identificação ingresso ${i + 1}`}</h6>
                          <Grid container spacing={1}>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              className="mt-3 input_buy"
                            >
                              <InputBasic
                                label="Nome"
                                placeholder="Insira o nome"
                                name="nome"
                                value={invitations[i].nome}
                                onChange={(value, e) =>
                                  handleChangeFormFields(value, e, i)
                                }
                                required
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              className="mt-3 input_buy"
                            >
                              <InputBasic
                                label="CPF"
                                placeholder="Insira o CPF"
                                name="cpf"
                                value={invitations[i].cpf}
                                onChange={(value, e) => {
                                  const maskedValue = VMasker.toPattern(
                                    value,
                                    "999.999.999-99"
                                  );
                                  handleChangeFormFields(maskedValue, e, i);
                                }}
                                required
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              className="mt-3 input_buy"
                            >
                              <InputBasic
                                label="E-mail"
                                placeholder="Insira o e-mail"
                                name="email"
                                type="email"
                                value={invitations[i].email}
                                onChange={(value, e) =>
                                  handleChangeFormFields(value, e, i)
                                }
                                required
                              />
                            </Grid>
                          </Grid>
                          <hr />
                        </div>
                      ))}
                    </Grid>
                  )}
                <Grid item xs={12} md={12} className="mt-3">
                  <FormGroup
                    row
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Checkbox
                      id="check-terms"
                      checked={checked}
                      onChange={(e) => setChecked(e.target.checked)}
                      color="secondary"
                    />
                    <label htmlFor="check-terms" style={{ marginBottom: 0 }}>
                      Eu concordo com os{" "}
                      <a
                        href={URL_TERMS}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Termos e Condições
                      </a>
                      .
                    </label>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className="d-flex align-items-center justify-content-center mt-1">
                    <CustomButton type="submit" disabled={!checked}>
                      <div
                        className={
                          checked
                            ? "content-buy-btn"
                            : "content-buy-btn-disabled"
                        }
                      >
                        <span>Ir para o Pagamento</span>
                      </div>
                    </CustomButton>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleClose}>Fechar</CustomButton>
      </DialogActions>
    </Dialog>
  );
};

ContentBuy.propTypes = {
  open: PropTypes.bool,
  selectedContent: PropTypes.any,
  onClose: PropTypes.func,
};

ContentBuy.defaultProps = {
  open: false,
  selectedContent: {},
  onClose: () => {},
};

export default ContentBuy;
