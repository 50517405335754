import Moment from "moment";

Moment.locale("pt-BR");

export default class Filters {
  sales(item, filter) {
    const check =
      String(item.canal_titulo)
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      String(item?.conteudo_titulo)
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      String(item?.status_pagamento)
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      String(item?.valor_pago)
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      String(
        item?.data_update_not
          ? Moment(item?.data_update_not)
              .utc(true)
              .format("DD/MM/YYYY")
          : ""
      )
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      String(item?.hora_update_not)
        .toLowerCase()
        .includes(filter.toLowerCase());

    return check;
  }
}
