import React from "react";
import ContentLoader from "react-content-loader";

const RoomsLoading = () => {
  let width = window.innerWidth;
  let box = `0 0 ${width - 300} 575`;

  return (
    <ContentLoader
      width={width - 300}
      height={575}
      viewBox={box}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="14" y="9" rx="2" ry="2" width="140" height="10" />
      <rect x="14" y="30" rx="2" ry="2" width="667" height="11" />
      <rect x="12" y="58" rx="2" ry="2" width="211" height="211" />
      <rect x="240" y="57" rx="2" ry="2" width="211" height="211" />
      <rect x="467" y="56" rx="2" ry="2" width="211" height="211" />
    </ContentLoader>
  );
};

export default RoomsLoading;
