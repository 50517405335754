/**
 *
 * @param {String} start
 * @param {String} end
 */
const convertMilToMin = (start, end) => {
  if (!start || !end) {
    return undefined;
  }

  const date1 = new Date();
  const date2 = new Date();

  let startTime = start.split(":");
  let endTime = end.split(":");

  date1.setHours(startTime[0], startTime[1], startTime[2], 0);
  date2.setHours(endTime[0], endTime[1], endTime[2], 0);

  let total = (date1 - date2) * -1;

  let min = Math.floor((total / 1000 / 60) << 0);
  let sec = Math.floor((total / 1000) % 60);

  return `${min}:${sec < 10 ? `0${sec}` : sec}`;
};

/**
 *
 * @param {String} value
 */
const convertMinToSec = (value) => {
  if (!value) {
    return undefined;
  }

  let hours = 0;
  let min = 0;
  let sec = 0;

  let time = value?.split(":");

  if (Number(time[0]) > 0) {
    hours = Number(time[0]) * 3600;
  }

  if (Number(time[1]) > 0) {
    min = Number(time[1]) * 60;
  }

  if (Number(time[2]) > 0) {
    sec = Number(time[2]);
  }

  return hours + min + sec;
};

/**
 *
 * @param {String} value
 */
const onlyNumbers = (value) => {
  if (!value) {
    return value;
  }

  const n = value.replace(/[a-zA-Z/]+/g, "");

  return n;
};

/**
 *
 * @param {String} url
 */
const verifyFileFormat = (url) => {
  if (!url) return "";

  const ext = url.split(".").pop();

  if (
    ext === "gif" ||
    ext === "jpg" ||
    ext === "jpeg" ||
    ext === "png" ||
    ext === "JPG" ||
    ext === "JPEG" ||
    ext === "PNG"
  )
    return "Imagem";
  else if (
    ext === "wmv" ||
    ext === "mp4" ||
    ext === "avi" ||
    ext === "mov" ||
    ext === "mpg" ||
    ext === "mkv" ||
    ext === "WMV" ||
    ext === "MP4" ||
    ext === "AVI" ||
    ext === "MOV" ||
    ext === "MPG" ||
    ext === "MKV"
  )
    return "Vídeo";
  else if (ext === "wav" || ext === "WAV" || ext === "mp3" || ext === "MP3")
    return "Áudio";
  else return "Arquivo";
};

/**
 *
 * @param {string} value
 */
const convertMoneyToFloat = (value) => {
  if (!value) return "0,00";

  const aux = value.split(",");
  const part1 = aux[0].replace(".", "");

  const final = [part1, aux[1]].join(".");

  return final;
};

/**
 *
 * @param {Array} data - Array de dados
 * @param {string} id - Campo do objeto que será usado como value no select
 * @param {string} label - Campo do objeto que será usado como label no select
 */
const convertDataForCombo = (data, id, label) => {
  if (!data) return [];

  const response = data.map((item) => ({
    value: item[id],
    label: item[label],
    ...item,
  }));

  return response;
};

/**
 *
 * @param {string} value
 */
const convertMoneyToNumber = (value) => {
  if (!value) return 0;

  const number = value.replace(/[^\d.]/g, "");

  return parseFloat(number);
};

/**
 *
 * @param {number} value - Valor inteiro que será formatado
 * @param {number} maxFracDig - Quantidade máxima de casas decimais
 * @param {number} minFracDig - Quantidade mínima de casas decimais
 */
const formatMoney = (value, maxFracDig = 2, minFracDig = 2) => {
  if (!value) return "0,00";

  const formatter = new Intl.NumberFormat("pt-BR", {
    maximumFractionDigits: maxFracDig,
    minimumFractionDigits: minFracDig,
  });

  return formatter.format(value);
};

export {
  convertMilToMin,
  convertMinToSec,
  onlyNumbers,
  verifyFileFormat,
  convertMoneyToFloat,
  convertDataForCombo,
  convertMoneyToNumber,
  formatMoney,
};
