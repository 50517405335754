import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CustomButton, SnackAlert, InputBasic } from "../../../components";
import { sendGift } from "../../../../../services/api";

import "./styles.css";

const SendGift = ({ open, selected, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  useEffect(() => {
    if (!open) resetFields();
    // eslint-disable-next-line
  }, [open]);

  const handleChangeForm = (value, e) => {
    const newList = Object.assign({}, formData, {
      [e.target.name]: value,
    });
    setFormData(newList);
  };

  const onSendGift = async (e) => {
    e.preventDefault();

    const loggedUser = JSON.parse(sessionStorage.getItem("currentUser"));

    if (loggedUser) {
      setLoading(true);
      try {
        const data = new FormData();
        data.append("usuario_id", loggedUser.id);
        data.append("email_presente", formData.email);
        data.append("conteudo_id", selected?.conteudo_id);
        data.append("conteudo_comprado_id", selected?.transacao_id);

        const response = await sendGift(data);
        const type = response.data.return ? "success" : "error";
        setAlert({
          open: true,
          type: type,
          title: response.data.data,
        });
        setTimeout(() => {
          handleClose();
        }, 3000);
      } catch (error) {
        setAlert({
          open: true,
          type: "error",
          title:
            "Não foi possível realizar esta solicitação. Por favor, tente novamente.",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const resetFields = () => {
    setFormData({
      email: "",
    });
  };

  const handleClose = () => {
    onClose();
    resetFields();
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
      maxWidth="md"
    >
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <DialogTitle id="modal-title">{"Presentear"}</DialogTitle>
      <form onSubmit={onSendGift}>
        <DialogContent>
          <div id="modal-body" className="modal-send-gift">
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <InputBasic
                  required={true}
                  label="E-mail"
                  placeholder="Insira o e-mail de quem você quer presentear"
                  maxLength={100}
                  value={formData.email}
                  name="email"
                  type="email"
                  onChange={handleChangeForm}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleClose}>Fechar</CustomButton>
          <CustomButton type="submit" disabled={loading}>
            {loading ? <CircularProgress size={20} /> : <div>Enviar</div>}
          </CustomButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

SendGift.propTypes = {
  open: PropTypes.bool,
  selected: PropTypes.any,
  onClose: PropTypes.func,
};

SendGift.defaultProps = {
  open: false,
  selected: {},
  onClose: () => {},
};

export default SendGift;
