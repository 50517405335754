import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";

import Colors from "../../../../../config/Colors";

import "./styles.css";

const InputSearch = ({ placeholder, loading, clear, onSubmit }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (clear) {
      setValue("");
    }
  }, [clear]);

  function onSearch(e) {
    e.preventDefault();

    onSubmit(value);
  }

  function onPressEnter(e) {
    if (e.nativeEvent.keyCode === 13) {
      onSubmit(value);
    }
  }

  return (
    <form
      onSubmit={onSearch}
      className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-5 my-2 my-md-0 osahan-navbar-search"
    >
      <div className="input-group mr-5">
        <input
          type="text"
          className="form-control input-search"
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyPress={onPressEnter}
        ></input>
        <div className="input-group-append">
          <button
            className="btn btn-light"
            type="button"
            onClick={() => onSubmit(value)}
            style={{ backgroundColor: Colors.primary }}
          >
            {loading ? (
              <CircularProgress size={14} color="primary" />
            ) : (
              <div>
                <i
                  className="fas fa-search"
                  style={{ color: Colors.fourth }}
                ></i>
              </div>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

InputSearch.propTypes = {
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  clear: PropTypes.bool,
  onSubmit: PropTypes.func,
};

InputSearch.defaultProps = {
  placeholder: "Pesquise aqui...",
  loading: false,
  clear: false,
  onSubmit: (value) => {},
};

export default InputSearch;
