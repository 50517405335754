import React, { useState, useEffect } from "react";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";

import {
  SectionTitle,
  SnackAlert,
  RoomsLoading,
  MyChannelContainer,
  CustomButton,
  UpdateMyChannel,
  CreateContent,
  PreviewContent,
  DisableContent,
  EditContent,
} from "../../Template/Admin/components";
import {
  getShowMyChannel,
  getMyChannelContentList,
  getEventCoupons,
} from "../../services/api";
import { convertDataForCombo } from "../../utils";
import { CreateCupom, CouponsList } from "./containers";

import "./styles.css";

Moment.locale("pt-BR");

const MyChannelScreen = () => {
  const [myChannel, setMyChannel] = useState([]);
  const [content, setContent] = useState([]);
  const [contentForCombo, setContentForCombo] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [emptyChannel, setEmptyChannel] = useState(false);
  const [msgEmptyChannel, setMsgEmptyChannel] = useState("");
  const [emptyContent, setEmptyContent] = useState(false);
  const [msgEmptyContent, setMsgEmptyContent] = useState("");
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalPreview, setOpenModalPreview] = useState(false);
  const [openModalEditContent, setOpenModalEditContent] = useState(false);
  const [openModalDisable, setOpenModalDisable] = useState(false);
  const [openModalCreateCupom, setOpenModalCreateCupom] = useState(false);
  const [openModalListCoupons, setOpenModalListCoupons] = useState(false);
  const [selectedContent, setSelectedContent] = useState({});
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  const history = useHistory();

  useEffect(() => {
    onGetShowMyChannel();
    // eslint-disable-next-line
  }, []);

  const onGetShowMyChannel = () => {
    setLoading(true);

    const loggedUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (loggedUser) setUser(loggedUser);

    if (loggedUser) {
      getShowMyChannel(loggedUser?.id)
        .then((response) => {
          if (response.data.return) {
            setMyChannel(response.data.data);
            onGetMyChannelContentList(response.data.data?.[0]?.id);
            onGetMyCoupons(loggedUser?.id, response.data.data?.[0]?.id);
            setEmptyChannel(false);
          } else {
            setLoading(false);
            setEmptyChannel(true);
            setMsgEmptyChannel(response.data.data);
          }
        })
        .catch((err) => {
          setLoading(false);
          setAlert({ open: true, type: "error", title: err });
        });
    }
  };

  const onGetMyChannelContentList = (id) => {
    getMyChannelContentList(id)
      .then((response) => {
        setLoading(false);

        if (response.data.return) {
          setContent(response.data.data);
          setContentForCombo(
            convertDataForCombo(
              response.data.data,
              "conteudo_id",
              "titulo_conteudo"
            )
          );
          setEmptyContent(false);
        } else {
          setEmptyContent(true);
          setMsgEmptyContent(response.data.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ open: true, type: "error", title: err });
      });
  };

  const onGetMyCoupons = async (userId, id) => {
    try {
      const response = await getEventCoupons(userId, id);
      if (response.data.return) {
        setCoupons(response.data.data);
      } else setCoupons([]);
    } catch (error) {
      setAlert({ open: true, type: "error", title: error.message });
    }
  };

  const handleSubmit = (type) => {
    if (type === "create") setOpenModalCreate(false);
    else if (type === "edit") setOpenModalEditContent(false);
    else if (type === "delete") setOpenModalDisable(false);
    else setOpenModalEdit(false);

    onGetShowMyChannel();
  };

  const handleSelected = (e, content, type) => {
    e.preventDefault();

    setSelectedContent(content);

    if (type === "preview") setOpenModalPreview(true);
    if (type === "edit") setOpenModalEditContent(true);
    else setOpenModalDisable(true);
  };

  const goToValidateTicket = () => {
    history.push("/home/validar-ingresso", { onwerId: myChannel?.[0]?.id });
  };

  const onUpdateCouponsList = () => {
    setOpenModalCreateCupom(false);
    onGetMyCoupons(user?.id, myChannel?.[0]?.id);
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  if (loading) {
    return (
      <div className="container-fluid pb-0 my-channel-screen-container">
        <RoomsLoading />
      </div>
    );
  }

  return (
    <div className="container-fluid pb-0 my-channel-screen-container">
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <div className="top-category section-padding mb-4">
        <div className="my-channel-screen-top-container pl-0">
          <div>
            <SectionTitle title="Meu Evento" />
          </div>
          <div className="my-channel-screen-buttons-container">
            <div className="my-channel-list-opt">
              <CustomButton onClick={() => setOpenModalCreate(true)}>
                <span>Inserir Produto</span>
              </CustomButton>
              <CustomButton onClick={() => setOpenModalEdit(true)}>
                <span>Editar Evento</span>
              </CustomButton>
              <CustomButton onClick={goToValidateTicket}>
                <span>Validar Ingressos</span>
              </CustomButton>
            </div>
            <Hidden smDown>
              <Divider orientation="vertical" flexItem />
            </Hidden>
            <Hidden smUp>
              <Divider orientation="horizontal" />
            </Hidden>
            <div className="my-channel-coupons-opt">
              <CustomButton onClick={() => setOpenModalCreateCupom(true)}>
                <span>Criar Cupom</span>
              </CustomButton>
              <CustomButton onClick={() => setOpenModalListCoupons(true)}>
                <span>Ver Cupons</span>
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 mb-5 pl-0">
        {emptyChannel ? (
          <div className="col-md-12 mb-3 pl-0" style={{ minHeight: 140 }}>
            <div className="main-title">
              <span>{msgEmptyChannel}</span>
            </div>
          </div>
        ) : (
          <MyChannelContainer
            myChannel={myChannel[0]}
            contents={content}
            emptyContent={emptyContent}
            msgEmptyContent={msgEmptyContent}
            onPreviewContent={(e, item) => handleSelected(e, item, "preview")}
            onEditContent={(e, item) => handleSelected(e, item, "edit")}
            onDeleteContent={(e, item) => handleSelected(e, item, "delete")}
          />
        )}
        <UpdateMyChannel
          open={openModalEdit}
          myChannel={myChannel[0]}
          onClose={() => setOpenModalEdit(false)}
          onSubmit={() => handleSubmit("edit")}
        />
        <CreateContent
          open={openModalCreate}
          myChannel={myChannel[0]}
          onClose={() => setOpenModalCreate(false)}
          onSubmit={() => handleSubmit("create")}
        />
        <EditContent
          open={openModalEditContent}
          myChannel={myChannel[0]}
          selected={selectedContent}
          onClose={() => setOpenModalEditContent(false)}
          onSubmit={() => handleSubmit("edit")}
        />
        <PreviewContent
          open={openModalPreview}
          selectedContent={selectedContent}
          onClose={() => setOpenModalPreview(false)}
        />
        <DisableContent
          open={openModalDisable}
          myChannel={myChannel[0]}
          selectedContent={selectedContent}
          onClose={() => setOpenModalDisable(false)}
          onSubmit={() => handleSubmit("delete")}
        />
        <CreateCupom
          open={openModalCreateCupom}
          contentsData={contentForCombo}
          onClose={() => setOpenModalCreateCupom(false)}
          onSubmit={onUpdateCouponsList}
        />
        <CouponsList
          open={openModalListCoupons}
          couponsData={coupons}
          onClose={() => setOpenModalListCoupons(false)}
          onSubmit={onUpdateCouponsList}
        />
      </div>
    </div>
  );
};

export default MyChannelScreen;
