import React from "react";
import ContentLoader from "react-content-loader";

const CommentsLoading = () => {
  return (
    <ContentLoader height={360} width="100%">
      <rect x="10" y="29" rx="0" ry="0" width="100%" height="32" />
      <rect x="10" y="71" rx="0" ry="0" width="100%" height="32" />

      <rect x="10" y="159" rx="0" ry="0" width="100%" height="32" />
      <rect x="10" y="201" rx="0" ry="0" width="100%" height="32" />
    </ContentLoader>
  );
};

export default CommentsLoading;
