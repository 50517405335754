import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";
import VMasker from "vanilla-masker";
import Moment from "moment";

import {
  CustomButton,
  SnackAlert,
  InputBasic,
  Select,
} from "../../../../../Template/Admin/components";
import { createCupom } from "../../../../../services/api";

Moment.locale("pt-BR");

const CreateCupom = ({ open, contentsData, onClose, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState([]);
  const [discountType, setDiscountType] = useState([]);
  const [formData, setFormData] = useState({
    cupom: "",
    valor: "",
    tipo_desconto: "",
    conteudo_id: "",
    quantidade: "",
    data_expiracao: "",
    senha: "",
  });
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  useEffect(() => {
    if (open) {
      setDiscountType([
        {
          value: "Porcentagem",
          label: "Porcentagem",
        },
        {
          value: "Real",
          label: "Real",
        },
      ]);
      setContents(contentsData);
    } else resetFields();
  }, [open, contentsData]);

  const resetFields = () => {
    setFormData({
      cupom: "",
      valor: "",
      tipo_desconto: "",
      conteudo_id: "",
      quantidade: "",
      data_expiracao: "",
      senha: "",
    });
    setLoading(false);
    setDiscountType([]);
    setContents([]);
  };

  const handleChangeForm = (value, e) => {
    const newList = Object.assign({}, formData, {
      [e.target.name]: value,
    });
    setFormData(newList);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    const loggedUser = JSON.parse(sessionStorage.getItem("currentUser"));

    const newData = new FormData();
    newData.append("user_id", loggedUser.id);
    Object.keys(formData).forEach((item) => {
      newData.append(String(item), formData[item]);
    });

    createCupom(newData)
      .then((response) => {
        if (response.data.return) {
          setAlert({
            open: true,
            type: "success",
            title: response.data.data,
          });
          setTimeout(() => {
            onSubmit();
          }, 3000);
        } else {
          setAlert({
            open: true,
            type: "warning",
            title: response.data.data,
          });
        }
      })
      .catch((err) => {
        setAlert({ open: true, type: "error", title: err });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      keepMounted
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
    >
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <DialogTitle id="modal-title">Criar Cupom</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <div id="modal-body">
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <InputBasic
                  required
                  label="Cupom"
                  name="cupom"
                  maxLength={100}
                  placeholder="Insira o cupom"
                  value={formData.cupom}
                  onChange={handleChangeForm}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputBasic
                  required
                  label="Quantidade"
                  name="quantidade"
                  maxLength={15}
                  placeholder="Insira uma quantidade"
                  value={formData.quantidade}
                  onChange={(value, e) => {
                    const newValue = VMasker.toNumber(value);
                    handleChangeForm(newValue, e);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  label="Tipo do produto"
                  options={discountType}
                  name="tipo_desconto"
                  onChange={(e) => handleChangeForm(e.target.value, e)}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputBasic
                  required
                  label="Valor"
                  maxLength={10}
                  name="valor"
                  placeholder="Insira o valor"
                  value={formData.valor}
                  onChange={(value, e) => {
                    const newValue = VMasker.toMoney(value);
                    handleChangeForm(newValue, e);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  label="Produto"
                  options={contents}
                  name="conteudo_id"
                  onChange={(e) => handleChangeForm(e.target.value, e)}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputBasic
                  required
                  label="Data de Expiração"
                  name="data_expiracao"
                  placeholder="Insira a data de expiração"
                  type="date"
                  min={Moment()
                    .utc(true)
                    .format("YYYY-MM-DD")}
                  value={formData.data_expiracao}
                  onChange={handleChangeForm}
                />
              </Grid>
              <Grid item xs={12} md={12} className="mt-3">
                <InputBasic
                  required={true}
                  label="Insira sua senha para concluir a criação do cupom"
                  placeholder="Insira a sua senha"
                  type="password"
                  name="senha"
                  value={formData.senha}
                  onChange={handleChangeForm}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={onClose}>Fechar</CustomButton>
          <CustomButton type="submit" disabled={loading}>
            {loading ? <CircularProgress size={20} /> : <div>Enviar</div>}
          </CustomButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

CreateCupom.propTypes = {
  open: PropTypes.bool,
  contentsData: PropTypes.array,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

CreateCupom.defaultProps = {
  open: false,
  contentsData: [],
  onClose: () => {},
  onSubmit: () => {},
};

export default CreateCupom;
