import React, { useState } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";

import { CustomButton, SnackAlert, SectionTitle } from "../../../components";
import { disableContentChannel } from "../../../../../services/api";

const DisableContent = ({
  open,
  myChannel,
  selectedContent,
  onClose,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  const handleSubmit = () => {
    setLoading(true);

    const loggeduser = JSON.parse(sessionStorage.getItem("currentUser"));

    disableContentChannel(
      loggeduser?.id,
      myChannel?.id,
      selectedContent?.conteudo_id
    )
      .then((response) => {
        setLoading(false);

        if (response.data.return) {
          setAlert({
            open: true,
            type: "success",
            title: "Produto desabilitado com sucesso!",
          });
          setTimeout(() => {
            onSubmit();
          }, 3000);
        } else {
          setAlert({
            open: true,
            type: "warning",
            title: response.data.data,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ open: true, type: "error", title: err });
      });
  };

  const handleClose = () => {
    onClose();
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
    >
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <DialogTitle id="modal-title">{"Desabilitar Produto"}</DialogTitle>
      <DialogContent>
        <div id="modal-body">
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <SectionTitle
                title={`Tem certeza que deseja desabilitar o produto "${selectedContent?.titulo_conteudo}"?`}
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleClose}>Fechar</CustomButton>
        <CustomButton onClick={handleSubmit}>
          {loading ? <CircularProgress size={20} /> : <div>Enviar</div>}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

DisableContent.propTypes = {
  open: PropTypes.bool,
  myChannel: PropTypes.any,
  selectedContent: PropTypes.any,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

DisableContent.defaultProps = {
  open: false,
  myChannel: {},
  selectedContent: {},
  onClose: () => {},
  onSubmit: () => {},
};

export default DisableContent;
