import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const ToggleLoggedContext = createContext();

const LoggedContext = ({ children }) => {
  const currentUser = sessionStorage.getItem("currentUser") ? true : false;

  const [isLogged, setIsLogged] = useState(currentUser);

  const toggleLoggedAction = () => {
    const currentUser = sessionStorage.getItem("currentUser") ? true : false;

    setIsLogged(currentUser);
  };

  return (
    <ToggleLoggedContext.Provider value={{ isLogged, toggleLoggedAction }}>
      {children}
    </ToggleLoggedContext.Provider>
  );
};

LoggedContext.propTypes = {
  children: PropTypes.any,
};

export default LoggedContext;
