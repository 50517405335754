import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import VMasker from "vanilla-masker";
import { InputBasic } from "../../../../Template/Admin/components";

import "../../styles.css";

const Form = ({ data, onSubmit, loading }) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [city, setCity] = useState("");
  const [birthday, setBirthday] = useState("");

  useEffect(() => {
    setEmail(data.email);
    setUsername(data.username);
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setPhone(data.telefone);
    setCellphone(data.celular);
    setCity(data.local);
    setBirthday(data.nascimento);
  }, [data]);

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      email,
      username,
      firstName,
      lastName,
      phone,
      cellphone,
      city,
      birthday,
    };

    onSubmit(data);
  }

  return (
    <form onSubmit={handleSubmit} className="d-flex flex-column">
      <div className="row mb-4 pl-0">
        <div className="col-md-3 col-lg-3">
          <InputBasic
            label="E-mail"
            placeholder="Insira o seu e-mail"
            value={email}
            disabled={true}
          />
        </div>
        <div className="col-md-3 col-lg-3">
          <InputBasic
            required={true}
            label="Username"
            placeholder="Insira o seu username"
            value={username}
            onChange={(value) => setUsername(value)}
          />
        </div>
        <div className="col-md-3 col-lg-3">
          <InputBasic
            required={true}
            label="Primeiro Nome"
            placeholder="Insira seu primeiro nome"
            value={firstName}
            onChange={(value) => setFirstName(value)}
          />
        </div>
      </div>
      <div className="row mb-4 pl-0">
        <div className="col-md-3 col-lg-3">
          <InputBasic
            label="Último Nome"
            placeholder="Insira seu último nome"
            value={lastName}
            onChange={(value) => setLastName(value)}
          />
        </div>
        <div className="col-md-3 col-lg-3">
          <InputBasic
            label="Telefone"
            placeholder="Insira o seu telefone"
            value={phone}
            onChange={(value) => {
              let maskedValue = VMasker.toPattern(value, "(99) 9999-9999");
              setPhone(maskedValue);
            }}
          />
        </div>
        <div className="col-md-3 col-lg-3">
          <InputBasic
            label="Celular"
            placeholder="Insira o seu celular"
            value={cellphone}
            onChange={(value) => {
              let maskedValue = VMasker.toPattern(value, "(99) 9 9999-9999");
              setCellphone(maskedValue);
            }}
          />
        </div>
      </div>
      <div className="row mb-4 pl-0">
        <div className="col-md-3 col-lg-3">
          <InputBasic
            label="Cidade"
            placeholder="Insira a sua cidade"
            value={city}
            onChange={(value) => setCity(value)}
          />
        </div>
        <div className="col-md-3 col-lg-3">
          <InputBasic
            label="Data de Nascimento"
            placeholder="Insira a sua data de nascimento"
            type="date"
            value={birthday}
            onChange={(value) => setBirthday(value)}
          />
        </div>
      </div>
      <div className="edit-profile-screen-btn-container">
        <button
          type="submit"
          className="btn btn-outline-primary btn-block btn-lg"
        >
          {loading ? <CircularProgress size={18} color="primary" /> : "Enviar"}
        </button>
      </div>
    </form>
  );
};

Form.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  data: {},
  loading: false,
  onSubmit: (data) => {},
};

export default Form;
