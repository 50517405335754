import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";

import { CustomButton, SnackAlert, InputBasic } from "../../../components";
import { updateMyChannel } from "../../../../../services/api";

const UpdateMyChannel = ({ open, myChannel, onClose, onSubmit }) => {
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    titulo: "",
    descricao: "",
    imagem_destaque: "",
  });
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  useEffect(() => {
    if (!open) {
      resetFields();
    } else {
      const item = myChannel;
      setFormData({
        titulo: item.titulo_canal,
        descricao: item.descricao_canal,
      });
    }
    // eslint-disable-next-line
  }, [open]);

  const resetFields = () => {
    setFormData({
      titulo: "",
      descricao: "",
      imagem_destaque: "",
    });
    setFile("");
  };

  const handleChangeForm = (value, e) => {
    const newList = Object.assign({}, formData, {
      [e.target.name]: value,
    });
    setFormData(newList);
  };

  const handleFile = (e) => {
    e.preventDefault();

    const reader = new FileReader();

    reader.onload = async (e) => {};
    setFile(e.target.files[0]);

    if (e.target.files?.[0]?.name) reader.readAsArrayBuffer(e.target.files[0]);
  };

  const handleSubmit = () => {
    if (!formData.titulo)
      setAlert({
        open: true,
        type: "warning",
        title: "Campo Título é obrigatório",
      });
    else if (!formData.descricao)
      setAlert({
        open: true,
        type: "warning",
        title: "Campo Descrição é obrigatório",
      });
    else {
      setLoading(true);

      const loggeduser = JSON.parse(sessionStorage.getItem("currentUser"));

      const newData = new FormData();
      newData.append("titulo", formData.titulo);
      newData.append("descricao", formData.descricao);
      newData.append("usuario_id", loggeduser?.id);
      newData.append("canal_id", myChannel?.id);
      if (file) newData.append("imagem_destaque", file);

      updateMyChannel(newData)
        .then((response) => {
          setLoading(false);

          if (response.data.return) {
            setAlert({
              open: true,
              type: "success",
              title: "Evento atualizado com sucesso!",
            });
            setTimeout(() => {
              onSubmit();
            }, 3000);
          } else {
            setAlert({
              open: true,
              type: "warning",
              title: response.data.data,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          setAlert({ open: true, type: "error", title: err });
        });
    }
  };

  const handleClose = () => {
    onClose();
    resetFields();
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
    >
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <DialogTitle id="modal-title">{"Editar Meu Evento"}</DialogTitle>
      <DialogContent>
        <div id="modal-body">
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <InputBasic
                required={true}
                label="Título"
                name="titulo"
                placeholder="Insira o título"
                value={formData.titulo}
                onChange={handleChangeForm}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <InputBasic
                required={true}
                label="Descrição"
                name="descricao"
                placeholder="Insira uma descrição"
                value={formData.descricao}
                onChange={handleChangeForm}
                maxLength={200}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <InputBasic
                type="file"
                label="Imagem Destaque"
                name="imagem_destaque"
                value={formData.imagem_destaque}
                onChange={(value, e) => {
                  handleFile(e);
                  handleChangeForm(value, e);
                }}
                accept="image/png, image/jpeg, image/gif, image/jpg"
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleClose}>Fechar</CustomButton>
        <CustomButton onClick={handleSubmit}>
          {loading ? <CircularProgress size={20} /> : <div>Enviar</div>}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

UpdateMyChannel.propTypes = {
  open: PropTypes.bool,
  myChannel: PropTypes.any,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

UpdateMyChannel.defaultProps = {
  open: false,
  myChannel: {},
  onClose: () => {},
  onSubmit: () => {},
};

export default UpdateMyChannel;
