import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import "./styles.css";

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#3f51b5",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    backgroundColor: "#FFF",
    borderRadius: "8px 8px 0 0",
    fontWeight: theme.typography.fontWeightBold,
    border: "1px solid #e8e8e8",
    "&:hover": {
      color: "#3f51b5",
      opacity: 1,
      backgroundColor: "#eeeeee",
    },
    "&$selected": {
      color: "#3f51b5",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#3f51b5",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const LocalVideos = ({ data, live, mainUrl }) => {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [useTabs, setUseTabs] = useState(false);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (data.length > 1) setUseTabs(true);
    else setUseTabs(false);
  }, [data]);

  useEffect(() => {
    function resize() {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getFrameVideo = (tabs) => {
    if (live && mainUrl === "#") {
      return (
        <iframe
          width="100%"
          height={width < 600 ? "200" : height - 250}
          src={`${data[tabs ? value : 0].url}?autoplay=1`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="Vídeo da câmera"
        ></iframe>
      );
    }

    return (
      <video
        id="hls-example"
        className="video-js vjs-default-skin local-videos-min-h"
        width="100%"
        height={height - 250}
        controls
      >
        <source type="application/x-mpegURL" src={data[tabs ? value : 0].url} />
      </video>
    );
  };

  return (
    <div>
      {useTabs ? (
        <div>
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="local videos"
          >
            {data.map((video, i) => (
              <AntTab key={String(i)} label={video.nome} />
            ))}
          </AntTabs>
          {getFrameVideo(true)}
        </div>
      ) : (
        getFrameVideo(false)
      )}
    </div>
  );
};

LocalVideos.propTypes = {
  data: PropTypes.array,
  live: PropTypes.bool,
  mainUrl: PropTypes.string,
};

LocalVideos.defaultProps = {
  data: [],
  live: false,
  mainUrl: "",
};

export default LocalVideos;
