import React from "react";
import { List } from "react-content-loader";

const OrgLoading = () => {
  let width = window.innerWidth;

  return (
    <div>
      <List
        height={width < 700 ? 90 : 160}
        style={{ marginBottom: 30 }}
        backgroundColor="#e0e0e0"
      />
      <List height={width < 700 ? 90 : 160} backgroundColor="#e0e0e0" />
    </div>
  );
};

export default OrgLoading;
