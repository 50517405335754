import React, { useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import VMasker from "vanilla-masker";
import { InputBasic } from "../../../../Template/Admin/components";

import "../../styles.css";

const Form = ({ onSubmit, loading }) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [city, setCity] = useState("");
  const [birthday, setBirthday] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      email,
      username,
      firstName,
      lastName,
      phone,
      cellphone,
      city,
      birthday,
      password,
      confirmPassword,
    };

    onSubmit(data);
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="d-flex flex-column align-items-center"
    >
      <div className="d-flex flex-row">
        <InputBasic
          required={true}
          className="onboarding-input-mr"
          label="E-mail"
          placeholder="Insira o seu e-mail"
          value={email}
          onChange={(value) => setEmail(value)}
        />
        <InputBasic
          required={true}
          label="Username"
          placeholder="Insira o seu username"
          value={username}
          onChange={(value) => setUsername(value)}
        />
      </div>
      <div className="d-flex flex-row">
        <InputBasic
          required={true}
          className="onboarding-input-mr"
          label="Primeiro Nome"
          placeholder="Insira seu primeiro nome"
          value={firstName}
          onChange={(value) => setFirstName(value)}
        />
        <InputBasic
          label="Último Nome"
          placeholder="Insira seu último nome"
          value={lastName}
          onChange={(value) => setLastName(value)}
        />
      </div>
      <div className="d-flex flex-row">
        <InputBasic
          className="onboarding-input-mr"
          label="Telefone"
          placeholder="Insira o seu telefone"
          value={phone}
          onChange={(value) => {
            let maskedValue = VMasker.toPattern(value, "(99) 9999-9999");
            setPhone(maskedValue);
          }}
        />
        <InputBasic
          label="Celular"
          placeholder="Insira o seu celular"
          value={cellphone}
          onChange={(value) => {
            let maskedValue = VMasker.toPattern(value, "(99) 9 9999-9999");
            setCellphone(maskedValue);
          }}
        />
      </div>
      <div className="d-flex flex-row">
        <InputBasic
          className="onboarding-input-mr"
          label="Cidade"
          placeholder="Insira a sua cidade"
          value={city}
          onChange={(value) => setCity(value)}
        />
        <InputBasic
          className="form-input-wd"
          label="Data de Nascimento"
          placeholder="Insira a sua data de nascimento"
          type="date"
          value={birthday}
          onChange={(value) => setBirthday(value)}
        />
      </div>
      <div className="d-flex flex-row">
        <InputBasic
          required={true}
          className="onboarding-input-mr"
          label="Senha"
          placeholder="Insira a sua senha"
          type="password"
          value={password}
          onChange={(value) => setPassword(value)}
        />
        <InputBasic
          required={true}
          label="Confirme a Senha"
          placeholder="Confirme a Senha"
          type="password"
          value={confirmPassword}
          onChange={(value) => setConfirmPassword(value)}
        />
      </div>
      <div className="mt-3 onboarding-input-btn">
        <div className="row full-width">
          <button
            type="submit"
            className="btn btn-outline-primary btn-block btn-lg"
          >
            {loading ? (
              <CircularProgress size={18} color="primary" />
            ) : (
              "Cadastrar"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

Form.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  loading: false,
  onSubmit: (data) => {},
};

export default Form;
