import React from "react";
import PropTypes from "prop-types";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "moment";

Moment.locale("pt-BR");

const useRowStyles = makeStyles((theme) => ({
  cell: {
    padding: "0 16px",
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  active: {
    backgroundColor: "green",
    color: "#FFF",
  },
  disabled: {
    backgroundColor: "red",
    color: "#FFF",
  },
}));

const Row = ({ row, index, onSelect }) => {
  const classes = useRowStyles();

  return (
    <TableRow hover tabIndex={-1} key={String(index)} style={{ width: "100%" }}>
      <TableCell component="th" scope="row" className={classes.cell}>
        {row.cupom || "-"}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {row.valor || "-"}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {row.quantidade || "-"}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {row.tipo_desconto || "-"}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {row.titulo_conteudo || "-"}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {row.data_expiracao
          ? Moment(row.data_expiracao)
              .utc()
              .format("DD/MM/YYYY")
          : "-"}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        <Button
          size="small"
          className={[
            classes.margin,
            row.status === "1" ? classes.active : classes.disabled,
          ].join(" ")}
          onClick={() => onSelect(row)}
        >
          {row.status === "1" ? "Ativo" : "Inativo"}
        </Button>
      </TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  row: PropTypes.any,
  index: PropTypes.number,
  onSelect: PropTypes.func,
};

Row.defaultProps = {
  row: [],
  index: 0,
  onSelect: (value) => {},
};

export default Row;
