import React, { useContext } from "react";
import { useHistory, withRouter, Route, Redirect } from "react-router-dom";
import { MenuNavigator } from "../../Template/Admin/components";
import {
  HomeScreen,
  AboutScreen,
  NewsScreen,
  ProfileScreen,
  EditProfileScreen,
  ChangePasswordScreen,
  SearchResultScreen,
  ChannelsScreen,
  ChannelDetailScreen,
  MyChannelScreen,
  MyShoppingScreen,
  MySalesScreen,
  ValidateTicketScreen,
} from "../../Admin";
import { ToggleLoggedContext } from "../../Context/login";

import userDefault from "../../assets/images/user.jpg";

import "./styles.css";

function HomeStack() {
  const { isLogged } = useContext(ToggleLoggedContext);

  const history = useHistory();

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        sessionStorage.getItem("currentUser") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );

  function handleLogout() {
    sessionStorage.clear();
    history.push("/home");
    window.location.reload(false);
  }

  function goToLogin(e) {
    e.preventDefault();

    history.push("/login");
  }

  return (
    <MenuNavigator
      isLogged={isLogged}
      userImg={userDefault}
      goToLogin={goToLogin}
      onLogout={handleLogout}
    >
      <Route path="/home" component={HomeScreen} exact />
      <Route path="/home/noticia" component={NewsScreen} />
      <Route path="/home/saiba-mais" component={AboutScreen} />
      <PrivateRoute path="/home/perfil" component={ProfileScreen} />
      <PrivateRoute path="/home/alterar-perfil" component={EditProfileScreen} />
      <PrivateRoute
        path="/home/alterar-senha"
        component={ChangePasswordScreen}
      />
      <Route path="/home/eventos" component={ChannelsScreen} />
      <Route path="/home/evento/:id" component={ChannelDetailScreen} />
      <PrivateRoute path="/home/meu-evento" component={MyChannelScreen} />
      <PrivateRoute path="/home/minhas-compras" component={MyShoppingScreen} />
      <PrivateRoute path="/home/minhas-vendas" component={MySalesScreen} />
      <Route path="/home/resultado-pesquisa" component={SearchResultScreen} />
      <PrivateRoute
        path="/home/validar-ingresso"
        component={ValidateTicketScreen}
      />
    </MenuNavigator>
  );
}

export default withRouter(HomeStack);
