import React from "react";
import PropTypes from "prop-types";

import "./styles.css";

const ModalLogout = ({ name, title, onLogout }) => {
  return (
    <div
      className="modal fade"
      id={name}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal-sair"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-sm modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modal-sair">
              {title}
            </h5>
            <button
              className="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            Para encerrar a sessão, clique em Sair.
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <a
              className="btn btn-primary"
              onClick={onLogout}
              data-dismiss="modal"
              href="/"
            >
              Sair
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalLogout.propTypes = {
  name: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  onLogout: PropTypes.func.isRequired,
};

ModalLogout.defaultProps = {
  name: "",
  title: "",
  onLogout: () => {},
};

export default ModalLogout;
