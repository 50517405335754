import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// import "./styles.css";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const DataTable = ({ header, rows, rowComponent, onSelect }) => {
  const classes = useStyles();

  const Component = rowComponent;

  const Row = (props) => {
    return <Component {...props} />;
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="data-table">
        <TableHead>
          <TableRow>
            {header.map((item, i) => (
              <TableCell key={String(i)} align="left">
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            rows.map((row, i) => (
              <Row key={String(i)} row={row} index={i} onSelect={onSelect} />
            ))
          ) : (
            <TableRow style={{ height: 200 }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DataTable.propTypes = {
  header: PropTypes.array,
  rows: PropTypes.array,
  rowComponent: PropTypes.element,
  onSelect: PropTypes.func,
};

DataTable.defaultProps = {
  header: [],
  rows: [],
  rowComponent: null,
  onSelect: (e) => {},
};

export default DataTable;
