import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import { NavLink, useHistory } from "react-router-dom";
import { Footer, ModalLogout, InputSearch, SnackAlert } from "../index";
import { searchRoomService } from "../../../../services/api";

import logo from "../../../../assets/images/logo_v3.png";

import "./styles.css";

const MenuNavigator = ({
  children,
  isLogged,
  userImg,
  onLogout,
  goToLogin,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [loggedMenu, setLoggedMenu] = useState(menuItemsLogged);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  const history = useHistory();

  useEffect(() => {
    getMyChannelOptions();
  }, []);

  const CustomButton = withStyles((theme) => ({
    root: {
      color: "#FFF",
      backgroundColor: "rgb(13, 210, 174)",
      "&:hover": {
        backgroundColor: "#111",
      },
      minWidth: 40,
      minHeight: 48,
      textTransform: "none",
      marginLeft: 8,
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 12,
      display: "flex",
      flexDirection: "row",
    },
  }))(Button);

  const loggedUser = JSON.parse(sessionStorage.getItem("currentUser"));

  const menuItems = [
    {
      title: "Home",
      path: "/home",
      icon: "fas fa-fw fa-home",
    },
    {
      title: "Eventos",
      path: "/home/eventos",
      icon: "fas fa-fw fa-podcast",
    },
    {
      title: "Saiba Mais",
      path: "/home/saiba-mais",
      icon: "fas fa-fw fa-info-circle",
    },
  ];

  const getMyChannelOptions = () => {
    const userChannel = JSON.parse(sessionStorage.getItem("userChannel"));

    if (userChannel) {
      const myChannel = {
        title: "Meu Evento",
        path: "/home/meu-evento",
        icon: "fas fa-fw fa-chalkboard-teacher",
      };
      const mySales = {
        title: "Minhas Vendas",
        path: "/home/minhas-vendas",
        icon: "fas fa-fw fa-money-bill-wave",
      };
      const newData = [...menuItemsLogged];
      newData.splice(3, 0, myChannel);
      newData.splice(4, 0, mySales);
      setLoggedMenu(newData);
    }
  };

  function onInitSearch(search) {
    setLoading(true);
    const loggeduser = JSON.parse(sessionStorage.getItem("currentUser"));

    const data = new FormData();
    data.append("busca", search);

    if (loggedUser) {
      data.append("user_id", loggeduser.id);
    }

    searchRoomService(data)
      .then((response) => {
        setLoading(false);

        if (response.data.return) {
          let params = {
            data: response.data.data,
            search,
          };

          history.push("/home/resultado-pesquisa", params);

          setClearSearch(true);
          setTimeout(() => {
            setClearSearch(false);
          }, 1000);
        } else {
          setAlert({ open: true, type: "warning", title: response.data.data });
        }
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ open: true, type: "error", title: err });
      });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateOnClick = (path) => {
    setAnchorEl(null);
    history.push(`/home${path}`);
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <div id="page-top">
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <nav className="navbar navbar-expand navbar-light bg-white static-top osahan-nav sticky-top menu-navigator-bg">
        &nbsp;&nbsp;
        <button
          className="btn btn-link btn-sm menu-navigator-toggle-bg order-1 order-sm-0"
          id="sidebarToggle"
        >
          <i className="fas fa-bars"></i>
        </button>{" "}
        &nbsp;&nbsp;
        <img src={logo} alt="Logomarca" style={{ height: 46, width: 82 }} />
        {/* <!-- Navbar Search --> */}
        <InputSearch
          placeholder="Pesquise aqui..."
          loading={loading}
          clear={clearSearch}
          onSubmit={onInitSearch}
        />
        {/* <!-- Navbar --> */}
        <ul className="navbar-nav ml-auto ml-md-0 osahan-right-navbar pt-2 pb-2">
          {/* <li className="nav-item mx-1">
            <a className="nav-link" href="/">
              <i className="fas fa-plus-circle fa-fw mr-1"></i>
              Criar Sala
            </a>
          </li> */}
          <li className="nav-item dropdown no-arrow osahan-right-navbar-user">
            {isLogged ? (
              <>
                <div
                  aria-controls="menu-dropdown"
                  aria-haspopup="true"
                  onClick={handleClick}
                  className="nav-link dropdown-toggle user-dropdown-link"
                  style={{ cursor: "pointer", color: "red !important" }}
                >
                  <img alt="Avatar" src={userImg} className="mr-2" />
                  {loggedUser?.first_name || "-"}
                </div>
                <Menu
                  elevation={1}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  id="menu-dropdown"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    className="menu-navigator-item-dd"
                    onClick={() => navigateOnClick("/perfil")}
                  >
                    <i className="fas fa-fw fa-user-circle"></i> &nbsp; Meu
                    Perfil
                  </MenuItem>
                  <MenuItem
                    className="menu-navigator-item-dd"
                    onClick={() => navigateOnClick("/alterar-perfil")}
                  >
                    <i className="fas fa-fw fa-user-edit"></i> &nbsp; Alterar
                    Perfil
                  </MenuItem>
                  <MenuItem
                    className="menu-navigator-item-dd"
                    onClick={() => navigateOnClick("/alterar-senha")}
                  >
                    <i className="fas fa-fw fa-key"></i> &nbsp; Alterar senha
                  </MenuItem>
                  <div className="dropdown-divider"></div>
                  <MenuItem
                    className="menu-navigator-item-dd"
                    data-toggle="modal"
                    data-target="#logoutModal"
                    onClick={handleClose}
                  >
                    <i className="fas fa-fw fa-sign-out-alt"></i> &nbsp; Sair
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <div className="no-login-btn">
                <CustomButton onClick={goToLogin}>Fazer o login</CustomButton>
              </div>
            )}
          </li>
        </ul>
      </nav>
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <ul className="sidebar navbar-nav menu-navigator-bg toggled">
          {isLogged ? (
            <>
              {loggedMenu.map((menu, i) => (
                <li
                  key={String(i)}
                  className={[
                    "nav-item",
                    history.location.pathname === menu.path ? "active" : "",
                  ].join(" ")}
                >
                  <NavLink className="nav-link" to={menu.path}>
                    <i className={menu.icon}></i>
                    <span>{menu.title}</span>
                  </NavLink>
                </li>
              ))}
            </>
          ) : (
            <>
              {menuItems.map((menu, i) => (
                <li
                  key={String(i)}
                  className={[
                    "nav-item",
                    history.location.pathname === menu.path ? "active" : "",
                  ].join(" ")}
                >
                  <NavLink className="nav-link" to={menu.path}>
                    <i className={menu.icon}></i>
                    <span>{menu.title}</span>
                  </NavLink>
                </li>
              ))}
            </>
          )}
          {/* <li className="nav-item channel-sidebar-list">
            <h6> </h6>
          </li> */}
        </ul>
        <div className="position-relative" id="content-wrapper">
          {children}
          {/* <!-- Sticky Footer --> */}
          <div className="menu-navigator-footer-container">
            <Footer />
          </div>
        </div>
        {/*  <!-- /.content-wrapper --> */}
      </div>
      {/* <!-- Logout Modal--> */}
      <ModalLogout
        name="logoutModal"
        title="Quer mesmo sair?"
        onLogout={onLogout}
      />
    </div>
  );
};

MenuNavigator.propTypes = {
  children: PropTypes.node.isRequired,
  isLogged: PropTypes.bool,
  userImg: PropTypes.string,
  onLogout: PropTypes.func,
  goToLogin: PropTypes.func,
};

MenuNavigator.defaultProps = {
  onLogout: () => {},
  goToLogin: () => {},
  isLogged: false,
  userImg: "",
};

export default MenuNavigator;

const menuItemsLogged = [
  {
    title: "Home",
    path: "/home",
    icon: "fas fa-fw fa-home",
  },
  {
    title: "Eventos",
    path: "/home/eventos",
    icon: "fas fa-fw fa-podcast",
  },
  {
    title: "Minhas Compras",
    path: "/home/minhas-compras",
    icon: "fas fa-fw fa-shopping-bag",
  },
  {
    title: "Saiba Mais",
    path: "/home/saiba-mais",
    icon: "fas fa-fw fa-info-circle",
  },
];
