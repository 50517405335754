import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";

import { SectionTitle } from "../../components";
import { verifyFileFormat } from "../../../../utils";
import { BASE_URL } from "services/Ambiente";

import logo from "../../../../assets/images/logo_v3.png";

import "./styles.css";

const ChannelContentList = ({
  title,
  data,
  showDelete,
  onSelected,
  onClickPreview,
  onEdit,
  onDelete,
}) => {
  const onSetImgCard = (data) => {
    const format = verifyFileFormat(data.link_preview);
    if (format === "Imagem") return `${BASE_URL}/${data.link_preview}`;
    else return logo;
  };

  const renderButton = (item) => {
    if (showDelete || onEdit) return null;
    return (
      <div className="d-flex align-items-center justify-content-center mt-3">
        <span className="tags cs-pointer" onClick={(e) => onSelected(e, item)}>
          Comprar
        </span>
      </div>
    );
  };

  const verifyHtmlString = (value) => {
    if (!value) return false;
    return String(value).includes("<p>");
  };

  return (
    <div className="row channel-content-container">
      {title && <SectionTitle title={title} paddingLeft={false} />}
      {data.map((item, i) => (
        <div key={String(i)} className="col-xl-3 col-sm-6 mb-3 mt-2">
          <div className="video-card">
            <div className="item"></div>
            <div
              className="video-card-image d-flex align-items-center cs-pointer"
              onClick={(e) => onClickPreview(e, item)}
            >
              <img
                className="img-fluid video-img-content"
                src={onSetImgCard(item)}
                alt="Preview Card"
              />
            </div>
            <div className="video-card-body" style={{ position: "relative" }}>
              {showDelete && (
                <div className="channel-content-delete">
                  <span
                    className="tags cs-pointer"
                    title="Excluir"
                    style={{ padding: "2px 4px" }}
                    onClick={(e) => onDelete(e, item)}
                  >
                    <i className="fas fa-fw fa-trash"></i>
                  </span>
                </div>
              )}
              {onEdit && (
                <div className="channel-content-edit">
                  <span
                    className="tags cs-pointer"
                    title="Editar"
                    style={{ padding: "2px 4px" }}
                    onClick={(e) => onEdit(e, item)}
                  >
                    <i className="fas fa-fw fa-pen"></i>
                  </span>
                </div>
              )}
              <h5 className="video-title" style={{ width: "90%" }}>
                {item.titulo_conteudo}
              </h5>
              <div
                className="video-page"
                style={{
                  marginBottom: verifyHtmlString(item.descricao_conteudo)
                    ? 0
                    : 6,
                }}
              >
                {parse(item.descricao_conteudo)}
              </div>
              {item.endereco && (
                <div className="video-page">Endereço: {item.endereco}</div>
              )}
              <div className="video-page mt-1">
                Gratuito: {item.gratuito || "-"}
              </div>
              <div className="video-page mt-1">
                Tipo do Produto: {item.tipo_conteudo || "-"}
              </div>
              {item.gratuito === "Não" && (
                <>
                  <h6 className="video-title mt-1">
                    Valor: {item.valor || "-"}
                  </h6>
                  {renderButton(item)}
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

ChannelContentList.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  showDelete: PropTypes.bool,
  onSelected: PropTypes.func,
  onClickPreview: PropTypes.func,
  onDelete: PropTypes.func,
};

ChannelContentList.defaultProps = {
  data: [],
  title: "",
  showDelete: false,
  onSelected: (e, item) => {},
  onClickPreview: (e, item) => {},
  onEdit: null,
  onDelete: (e, item) => {},
};

export default ChannelContentList;
