import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { RoomsContainer } from "../../Template/Admin/components";

import "./styles.css";

const SearchResultScreen = ({ location }) => {
  const [resultRooms, setResultRooms] = useState([]);
  const [search, setSearch] = useState("");

  const history = useHistory();

  useEffect(() => {
    setResultRooms(location?.state?.data);
    setSearch(location?.state?.search);
  }, [location.state]);

  function onSelectedRoom(e, item) {
    e.preventDefault();

    history.push(`/home/sala/${item.id}`, item);
  }

  if (!location?.state?.data) {
    history.push("/home");
  }

  return (
    <div className="container-fluid pb-0 search-result-room-min-h">
      <div className="top-category section-padding mb-4">
        <RoomsContainer
          rooms={resultRooms}
          empty={false}
          title={`Resultado da Pesquisa - "${search}"`}
          msgEmpty={""}
          selectedRoom={(e, item) => onSelectedRoom(e, item)}
        />
      </div>
    </div>
  );
};

export default SearchResultScreen;
