import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  SectionTitle,
  BestMomentsLoading,
  BestMomentView,
  CustomButton,
} from "..";
import { convertMilToMin, convertMinToSec } from "../../../../utils";
import { ToggleLoggedContext } from "../../../../Context/login";

import imgDefault from "../../../../assets/images/v1.png";

import "./styles.css";

const BestMomentsTab = ({
  moments,
  live,
  clickActions,
  loading,
  videoUrl,
  owner,
  canShare,
  notAuth,
  addRequest,
}) => {
  const [newMoments, setNewMoments] = useState([]);
  const [selected, setSelected] = useState(null);
  const [urlSelected, setUrlSelected] = useState("");
  const [shareData, setShareData] = useState({
    url: "",
    startTime: 0,
  });
  const [openBestMomentModal, setOpenBestMomentModal] = useState(false);

  const { isLogged } = useContext(ToggleLoggedContext);

  const loggedUser = JSON.parse(sessionStorage.getItem("currentUser"));

  useEffect(() => {
    const votes = localStorage.getItem("myVotes");

    if (votes) {
      const aux = JSON.parse(votes);
      let aux2 = moments;

      // eslint-disable-next-line
      aux2.map((item) => {
        let selMoment = aux.find((i) => i.bestMomentId === item.id);

        if (selMoment) {
          item.voted = true;
          item.action = selMoment.vote;
        } else {
          item.voted = false;
          item.action = "";
        }
      });

      setNewMoments(aux2);
    } else {
      let aux = moments;
      // eslint-disable-next-line
      aux.map((item) => {
        item.voted = false;
        item.action = "";
      });

      setNewMoments(aux);
    }
  }, [moments, owner]);

  function onClickActions(vote, id) {
    if (isLogged) {
      const votes = localStorage.getItem("myVotes");

      let data = {
        bestMomentId: id,
        vote,
      };

      if (votes) {
        const aux = JSON.parse(votes);
        let item = aux.findIndex((item) => item.bestMomentId === id);

        if (item >= 0) {
          aux.splice(item, 1);
          localStorage.setItem("myVotes", JSON.stringify(aux));
          clickActions(vote, 0, id);
        } else {
          aux.push(data);
          localStorage.setItem("myVotes", JSON.stringify(aux));
          clickActions(vote, 1, id);
        }
      } else {
        let aux = [];
        aux.push(data);
        localStorage.setItem("myVotes", JSON.stringify(aux));
        clickActions(vote, 1, id);
      }

      //localStorage.clear();
    } else {
      notAuth(
        "É preciso fazer o login para dar like/deslike nos melhores momentos."
      );
    }
  }

  function requestMMContainer(item) {
    if (window.confirm("Deseja solicitar o download do melhor momento?")) {
      clickActions(null, 2, item.id);
    }
  }

  function onDisableMoment(id) {
    if (window.confirm("Tem certeza que deseja remover o melhor momento?")) {
      clickActions(null, 2, id);
    }
  }

  function onOpenMoment(moment) {
    setSelected(moment);

    let start = convertMinToSec(moment?.intervalo_inicial);
    let end = convertMinToSec(moment?.intervalo_final);

    if (start && end) {
      let url = `${videoUrl}?start=${start}&end=${end}`;
      setUrlSelected(url);
      setShareData({
        url: videoUrl,
        startTime: start,
      });
      setOpenBestMomentModal(true);
    }
  }

  const handleShare = (moment) => {
    const startTime = convertMinToSec(moment?.intervalo_inicial);
    const shareUrl = `${videoUrl?.replace("embed/", "watch?v=")}`;
    const textShare = `https://api.whatsapp.com/send?text=Its On Tickets \n\n${moment?.titulo}\n\nVeja o melhor momento compartilhado!\n${shareUrl}`;
    const deepLink = `${encodeURI(textShare)}%26t=${startTime}`;
    const link = document.createElement("a");
    link.href = deepLink;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function onCloseBestMomentModal() {
    setOpenBestMomentModal(false);
    setSelected(null);
    setUrlSelected("");
  }

  return (
    <div
      className="tab-pane fade show active"
      id="best-moments"
      role="tabpanel"
      aria-labelledby="best-moments-tab"
    >
      <div className="video-slider-right-list best-moments-container">
        {loading ? (
          <BestMomentsLoading />
        ) : live ? (
          <div className="pl-2 pr-2">
            <h6>
              Os melhores momentos estarão disponíveis nesta sessão assim que a
              transmissão ao vivo acabar.
            </h6>
          </div>
        ) : (
          newMoments.map((item, i) => (
            <div key={String(i)}>
              <div className="video-card video-card-list best-moments-block">
                <div
                  className="video-card-image cs-pointer"
                  onClick={() => onOpenMoment(item)}
                >
                  <div className="play-icon">
                    <i className="fas fa-play-circle"></i>
                  </div>
                  <img className="img-fluid" src={imgDefault} alt="Video" />
                  <div className="time">
                    {convertMilToMin(
                      item.intervalo_inicial,
                      item.intervalo_final
                    )}
                  </div>
                </div>
                <div className="video-card-body">
                  <div className="video-title">
                    <span>{item.titulo}</span>
                  </div>
                  <div className="video-page text-success">
                    {item.descricao}
                  </div>
                  <div className="video-view d-flex align-items-center mt-2">
                    <div className="d-flex align-items-center">
                      <button
                        className={[
                          "mr-2",
                          item.voted && item.action === 1
                            ? "total-like-l"
                            : "total-like",
                        ].join(" ")}
                        disabled={item.voted && item.action === 0}
                        onClick={() => onClickActions(1, item.id)}
                        title="Curti o melhor momento"
                      >
                        <i className="fas fa-thumbs-up"></i> {item.like}
                      </button>{" "}
                      <button
                        className={
                          item.voted && item.action === 0
                            ? "total-like-d"
                            : "total-like"
                        }
                        disabled={item.voted && item.action === 1}
                        onClick={() => onClickActions(0, item.id)}
                        title="Não curti o melhor momento"
                      >
                        <i className="fas fa-thumbs-down"></i> {item.deslike}
                      </button>{" "}
                      {/* {canShare && ( */}
                      <button
                        className="total-like ml-2"
                        onClick={() => handleShare(item)}
                        title="Compartilhar Melhor Momento"
                      >
                        <i className="fas fa-fw fa-share-alt"></i>
                      </button>
                      {/*  )} */}
                      {loggedUser && (
                        <button
                          className="total-like ml-2"
                          onClick={() => requestMMContainer(item)}
                          title="Solicitar Download"
                        >
                          <i className="fa fa-download"></i>
                        </button>
                      )}
                      {owner || loggedUser?.id === item.usuario_id ? (
                        <button
                          className="total-like ml-2"
                          onClick={() => onDisableMoment(item.id)}
                          title="Remover Melhor Momento"
                        >
                          <i className="fas fa-fw fa-trash"></i>
                        </button>
                      ) : (
                        <div></div>
                      )}
                      {/* <span
                        dir="rtl"
                        className="total-like-user-main ml-2"
                      ></span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
        <BestMomentView
          open={openBestMomentModal}
          onClose={onCloseBestMomentModal}
          title={selected?.titulo}
          url={urlSelected}
          // canShare={canShare}
          canShare={true}
          shareData={shareData}
        />

        {!loading && (
          <div className="d-flex flex-row justify-content-between align-items-center">
            {newMoments.length < 1 ? (
              <div className="ml-2 mt-3">
                <SectionTitle title="Ainda não há melhores momentos cadastrados" />
              </div>
            ) : (
              ""
            )}

            {isLogged && !live && (
              <div className="mr-2 mt-1">
                <CustomButton
                  title="Adicionar melhor momento"
                  onClick={addRequest}
                >
                  <i className="fas fa-fw fa-plus"></i>
                </CustomButton>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

BestMomentsTab.propTypes = {
  moments: PropTypes.array,
  live: PropTypes.bool,
  loading: PropTypes.bool,
  clickActions: PropTypes.func,
  videoUrl: PropTypes.string,
  owner: PropTypes.bool,
  canShare: PropTypes.bool,
  notAuth: PropTypes.func,
  addRequest: PropTypes.func,
};

BestMomentsTab.defaultProps = {
  moments: [],
  live: true,
  loading: true,
  videoUrl: "",
  owner: true,
  canShare: true,
  clickActions: (voto, action, id) => {},
  notAuth: (msg) => {},
  addRequest: () => {},
};

export default BestMomentsTab;
