import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ItemsCarousel from "react-items-carousel";
import Banner from "react-js-banner";
import { BASE_URL } from "../../../../services/Ambiente";

import "./styles.css";

const NewsBanner = ({ news, onClick }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const chevronWidth = 20;

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveItemIndex((state) => state + 1);
    }, 9000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const Chevron = ({ left }) => {
    return (
      <div className="news-banner-btn">
        {left ? (
          <i className="fas fa-fw fa-chevron-left"></i>
        ) : (
          <i className="fas fa-fw fa-chevron-right"></i>
        )}
      </div>
    );
  };

  return (
    <div className="pl-3 pr-3 news-banner-container">
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={1}
        leftChevron={<Chevron left={true} />}
        rightChevron={<Chevron left={false} />}
        outsideChevron
        chevronWidth={chevronWidth}
        infiniteLoop={true}
      >
        {news.map((item) => (
          <div key={item.id} onClick={() => onClick(item)}>
            <Banner
              title={` `}
              image={`${BASE_URL}/${item.imagem_destaque}`}
              css={{
                color: "#FFFFFF",
                backgroundColor: "#f2f2f2",
                fontSize: 20,
                height: "auto",
                width: "100%",
              }}
            />
          </div>
        ))}
      </ItemsCarousel>
    </div>
  );
};

NewsBanner.propTypes = {
  news: PropTypes.array,
  onClick: PropTypes.func,
};

NewsBanner.defaultProps = {
  news: [],
  onClick: (data) => {},
};

export default NewsBanner;
