import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import LoggedContext from "./Context/login";

import Login from "./Site/Login";
import Onboarding from "./Site/Onboarding";
import ForgotPassword from "./Site/ForgotPassword";

import { HomeStack, RedirectScreen } from "./Admin";

function Routes() {
  return (
    <LoggedContext>
      <BrowserRouter>
        <Route exact path="/" component={RedirectScreen} />
        <Route path="/home" component={HomeStack} />
        <Route path="/login" component={Login} exact />
        <Route path="/cadastro" component={Onboarding} exact />
        <Route path="/esqueci-senha" component={ForgotPassword} exact />
      </BrowserRouter>
    </LoggedContext>
  );
}

export default Routes;
