import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";

const TabPanel = ({ children, value, index, setPadding, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={setPadding ? 1 : 0} style={{ paddingTop: setPadding ? 8 : 4 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number,
  value: PropTypes.number,
  setPadding: PropTypes.bool,
};

TabPanel.defaultProps = {
  index: 0,
  value: 0,
  setPadding: false,
};

export default TabPanel;
