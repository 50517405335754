import React, { useState, useEffect, useCallback } from "react";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";

import {
  SectionTitle,
  SnackAlert,
  SimpleAlert,
  FullLoading,
  QrCodeReader,
} from "../../Template/Admin/components";
import { validateTicket } from "../../services/api";

import "./styles.css";

Moment.locale("pt-BR");

const EXEC_TIMEOUT = 3000;

const ValidateTicketScreen = (props) => {
  const [loading, setLoading] = useState(false);
  const [showComponent, setShowComponent] = useState(true);
  const [buttonText, setButtonText] = useState("Ler novo ingresso");
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });
  const [openMsg, setOpenMsg] = useState({
    open: false,
    type: "info",
    title: "",
    description: "",
  });

  const history = useHistory();

  useEffect(() => {
    const state = props?.location?.state;
    const userChannel = JSON.parse(sessionStorage.getItem("userChannel"));
    if (state?.onwerId !== userChannel?.id) history.push("/home");
    getCameraPermissions();

    return () => {
      onReloadPage();
    };
    // eslint-disable-next-line
  }, []);

  const onReloadPage = () => {
    window.location.reload();
  };

  const getCameraPermissions = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({
        video: true,
      });
    } catch (error) {
      showPermissionsError();
    }
  };

  const showPermissionsError = () => {
    const msg =
      "É necessário ter a permissão da câmera concedida para esta funcionalidade";
    setOpenMsg({
      open: true,
      type: "warning",
      title: "Ops!",
      description: msg,
    });
    setButtonText("Tentar novamente");
  };

  const onValidateTicket = useCallback((ticket) => {
    setShowComponent(false);
    setLoading(true);

    setTimeout(async () => {
      try {
        const loggedUser = JSON.parse(sessionStorage.getItem("currentUser"));

        if (loggedUser) {
          const data = new FormData();
          data.append("user_id", loggedUser?.id);
          data.append("texto_qrcode", ticket);

          const response = await validateTicket(data);

          setButtonText("Ler novo ingresso");

          const type = response.data.return ? "success" : "error";
          const title = response.data.return ? "Ótimo!" : "Ops!";
          setOpenMsg({
            open: true,
            type,
            title,
            description: response.data?.data,
          });
        }
      } catch (error) {
        setAlert({ open: true, type: "error", title: error });
      } finally {
        setLoading(false);
      }
    }, EXEC_TIMEOUT);
  }, []);

  const handleResultQRCode = useCallback(
    (result, error) => {
      if (result?.text) onValidateTicket(result?.text);
      else {
        if (error?.message === "Permission denied") {
          showPermissionsError();
        }
      }
    },
    [onValidateTicket]
  );

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  const onCloseMsg = () => {
    if (buttonText === "Tentar novamente") {
      onReloadPage();
    } else {
      setOpenMsg({ ...openMsg, open: false, title: "", description: "" });
      setButtonText("Ler novo ingresso");
      getCameraPermissions();
      handleShowComponent();
    }
  };

  const handleShowComponent = () => {
    setTimeout(() => {
      setShowComponent(true);
    }, 500);
  };

  return (
    <div className="container-fluid pb-0 val-ticket-screen-container">
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <div className="top-category section-padding mb-4">
        <div className="val-ticket-screen-top-container pl-0">
          <div>
            <SectionTitle title="Validar Ingresso" />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <SimpleAlert
          open={openMsg.open}
          type={openMsg.type}
          title={openMsg.title}
          description={openMsg.description}
          buttonText={buttonText}
          onClose={onCloseMsg}
        />
      </div>
      <div className="col-lg-12 col-md-12 mb-5 pl-0 d-flex flex-column">
        {showComponent && (
          <Grid container spacing={1}>
            <Grid item xs={1} md={1}></Grid>
            <Grid item xs={10} md={10}>
              {/* O componente não funciona no Chrome Mobile usando local (localhost) sem https */}
              <QrCodeReader
                onResult={handleResultQRCode}
                style={{ width: "100%" }}
                className="val-ticket-screen-qrcode"
                scanDelay={2000}
                constraints={{
                  facingMode: "environment",
                }}
              />
            </Grid>
            <Grid item xs={1} md={1}></Grid>
          </Grid>
        )}
      </div>
      <FullLoading
        open={loading}
        infoText="Por favor, aguarde enquanto validamos o QRCode."
      />
    </div>
  );
};

export default ValidateTicketScreen;
