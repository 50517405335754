import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import parse from "html-react-parser";

import { ChannelsContentContainer } from "../../components";
import { BASE_URL } from "services/Ambiente";

import logo from "../../../../assets/images/logo_v3.png";

const MyChannelContainer = ({
  myChannel,
  contents,
  emptyContent,
  msgEmptyContent,
  onPreviewContent,
  onEditContent,
  onDeleteContent,
}) => {
  const onSetImgCard = (img) => {
    if (img) return `${BASE_URL}/${img}`;
    else return logo;
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <div className="align-1">
            <img
              src={onSetImgCard(myChannel?.imagem_destaque)}
              alt="Imagem Evento"
              style={{
                border: "2px solid #000000",
                width: 180,
                borderRadius: 8,
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <div className="d-flex flex-column align-1">
                <h6 className="text-dark">Título do Evento</h6>
                <span style={{ fontSize: 14 }}>{myChannel?.titulo_canal}</span>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="d-flex flex-column align-1">
                <h6 className="text-dark">Descrição do Evento</h6>
                <span style={{ fontSize: 14 }}>
                  {parse(myChannel?.descricao_canal)}
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} className="mt-4">
          <ChannelsContentContainer
            contents={contents}
            empty={emptyContent}
            title="Meus Produtos"
            showDelete
            msgEmpty={msgEmptyContent}
            onPreviewContent={onPreviewContent}
            onEditContent={onEditContent}
            onDeleteContent={onDeleteContent}
          />
        </Grid>
      </Grid>
    </div>
  );
};

MyChannelContainer.propTypes = {
  myChannel: PropTypes.any,
  contents: PropTypes.array,
  emptyContent: PropTypes.bool,
  msgEmptyContent: PropTypes.string,
  onPreviewContent: PropTypes.func,
  onEditContent: PropTypes.func,
  onDeleteContent: PropTypes.func,
};

MyChannelContainer.defaultProps = {
  myChannel: {},
  contents: [],
  emptyContent: false,
  msgEmptyContent: "",
  onPreviewContent: (e, item) => {},
  onEditContent: null,
  onDeleteContent: (e, item) => {},
};

export default MyChannelContainer;
