import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  RoomsLoading,
  SnackAlert,
  SectionTitle,
  ChannelsContentContainer,
  ContentBuy,
  PreviewContent,
  CustomButton,
} from "../../Template/Admin/components";
import { getChannelContent, getEvent } from "../../services/api";
import { onlyNumbers } from "../../utils";

import "./styles.css";

const ChannelDetailScreen = (props) => {
  const [contentChannel, setContentChannel] = useState([]);
  const [channel, setChannel] = useState([]);
  const [emptyContent, setEmptyContent] = useState(false);
  const [msgEmptyContent, setMsgEmptyContent] = useState("");
  const [selectedContent, setSelectedContent] = useState({});
  const [openModalBuy, setOpenModalBuy] = useState(false);
  const [openModalPreview, setOpenModalPreview] = useState(false);
  const [showTicketButton, setShowTicketButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  const history = useHistory();

  useEffect(() => {
    const id = onlyNumbers(props?.location?.pathname);

    if (!props?.location.state && !id) {
      history.push("/home");
    }

    onGetEvent(id);
    onGetChannelContent(id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (channel.length > 0) {
      const userChannel = JSON.parse(sessionStorage.getItem("userChannel"));
      if (userChannel)
        setShowTicketButton(userChannel?.id === channel?.[0]?.id);
    }
  }, [channel]);

  const onGetEvent = async (id) => {
    try {
      const response = await getEvent(id);

      if (response.data.return) setChannel(response.data.data);
      else setChannel([]);
    } catch (err) {
      setAlert({ open: true, type: "error", title: err });
    } finally {
      setLoadingPage(false);
    }
  };

  const onGetChannelContent = async (id) => {
    try {
      const response = await getChannelContent(id);
      setLoading(false);

      if (response.data.return) {
        setContentChannel(response.data.data);
        setEmptyContent(false);
      } else {
        setEmptyContent(true);
        setMsgEmptyContent(response.data.data);
      }
    } catch (err) {
      setLoading(false);
      setAlert({ open: true, type: "error", title: err });
    }
  };

  const handleSelectedContent = (e, content) => {
    e.preventDefault();

    const loggedUser = sessionStorage.getItem("currentUser");

    if (loggedUser) {
      setSelectedContent(content);
      setOpenModalBuy(true);
    } else {
      const id = onlyNumbers(props?.location?.pathname);
      const state = props?.location?.state;
      history.push("/login", {
        goBack: true,
        previousPage: `/home/evento/${id}`,
        ...state,
      });
    }
  };

  const handleSelectedPreview = (e, content) => {
    e.preventDefault();

    setSelectedContent(content);
    setOpenModalPreview(true);
  };

  const goToValidateTicket = () => {
    history.push("/home/validar-ingresso", { onwerId: channel?.id });
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  if (loading || loadingPage) {
    return (
      <div className="container-fluid pb-0 channel-detail-container">
        <RoomsLoading />
      </div>
    );
  }

  return (
    <div className="container-fluid channel-detail-container">
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <div className="top-category section-padding mb-4">
        <div className="pl-0 channel-detail-top-container">
          <div>
            <SectionTitle
              title={`Produtos do Evento${
                channel.length > 0 ? ` "${channel?.[0]?.titulo}"` : ""
              }`}
            />
          </div>
          {showTicketButton && (
            <div className="d-flex justify-content-end">
              <CustomButton onClick={goToValidateTicket}>
                <span>Validar Ingressos</span>
              </CustomButton>
            </div>
          )}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 mb-5 pl-0">
        <ChannelsContentContainer
          contents={contentChannel}
          empty={emptyContent}
          title=""
          showDelete={false}
          msgEmpty={msgEmptyContent}
          selectedContent={handleSelectedContent}
          onPreviewContent={handleSelectedPreview}
        />
      </div>
      <ContentBuy
        open={openModalBuy}
        selectedContent={selectedContent}
        onClose={() => setOpenModalBuy(false)}
      />
      <PreviewContent
        open={openModalPreview}
        selectedContent={selectedContent}
        onClose={() => setOpenModalPreview(false)}
      />
    </div>
  );
};

export default ChannelDetailScreen;
