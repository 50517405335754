import React from "react";
import PropTypes from "prop-types";

import "./styles.css";

const SectionTitle = ({ title, paddingLeft }) => {
  return (
    <div className={["col-md-12", paddingLeft ? "pl-0" : ""].join(" ")}>
      <div className="main-title">
        <h6>{title}</h6>
      </div>
    </div>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string,
  paddingLeft: PropTypes.bool,
};

SectionTitle.defaultProps = {
  title: "Insira um título",
  paddingLeft: true,
};

export default SectionTitle;
