import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const InsertComent = ({ loggedUser, numberOfMsgs, sendComment }) => {
  const [comment, setComment] = useState("");

  const history = useHistory();

  function onSendComment() {
    sendComment(comment);
    setComment("");
  }

  function goToLogin() {
    history.push("/login");
  }

  return loggedUser ? (
    <div className="reviews-members pt-1">
      <div className="media">
        <div className="media-body">
          <div className="form-members-body">
            <textarea
              rows="2"
              placeholder="Escreva seu comentário aqui"
              className="form-control"
              style={{ borderRadius: 8 }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </div>
          <div className="form-members-footer text-right mt-2">
            <b className="float-left">{`${numberOfMsgs} comentário${
              numberOfMsgs <= 1 ? "" : "s"
            }`}</b>
            <button
              className="btn btn-outline-danger btn-sm"
              type="button"
              onClick={() => setComment("")}
            >
              Cancelar
            </button>{" "}
            <button
              className="btn btn-danger btn-sm"
              type="button"
              onClick={onSendComment}
            >
              Comentar
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="reviews-members pt-0">
      <button
        className="btn btn-danger btn-sm mt-3 mb-3"
        type="button"
        onClick={goToLogin}
      >
        Para inserir um comentário, faça o login
      </button>
    </div>
  );
};

InsertComent.propTypes = {
  loggedUser: PropTypes.any,
  numberOfMsgs: PropTypes.number,
  sendComment: PropTypes.func,
};

InsertComent.defaultProps = {
  loggedUser: {},
  numberOfMsgs: 0,
  sendComment: (comment) => {},
};

export default InsertComent;
