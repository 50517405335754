import React from "react";
import PropTypes from "prop-types";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "moment";

import { formatMoney } from "../../../../utils";

Moment.locale("pt-BR");

const useRowStyles = makeStyles((theme) => ({
  cell: {
    padding: "4px 16px",
  },
}));

const Row = ({ row, index }) => {
  const classes = useRowStyles();

  return (
    <TableRow hover tabIndex={-1} key={String(index)} style={{ width: "100%" }}>
      <TableCell component="th" scope="row" className={classes.cell}>
        {row.canal_titulo || "-"}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {row.conteudo_titulo || "-"}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {row.status_pagamento || "-"}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {formatMoney(row.valor_pago)}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {row.data_update_not
          ? `${Moment(row.data_update_not)
              .utc(true)
              .format("DD/MM/YYYY")}${
              row.hora_update_not ? ` ${row.hora_update_not}` : ""
            }`
          : "-"}
      </TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  row: PropTypes.any,
  index: PropTypes.number,
};

Row.defaultProps = {
  row: [],
  index: 0,
};

export default Row;
