import React, { useState } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { CircularProgress } from "@material-ui/core";
import { CustomButton, InputBasic } from "../../../components";

import "./styles.css";

const AddBestMoment = ({ open, loading, onSubmit, onClose }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const handleClose = () => {
    setTitle("");
    setDescription("");
    setStart("");
    setEnd("");
    onClose();
  };

  const eraseFields = () => {
    setTitle("");
    setDescription("");
    setStart("");
    setEnd("");
  };

  function onSubmitForm(e) {
    e.preventDefault();

    let newStart = start.split(":");
    let newEnd = end.split(":");

    if (newStart.length === 2) {
      newStart = `${start}:00`;
    }

    if (newEnd.length === 2) {
      newEnd = `${end}:00`;
    }

    const data = {
      title,
      description,
      start: start.split(":").length <= 2 ? newStart : start,
      end: end.split(":").length <= 2 ? newEnd : end,
    };

    onSubmit(data);
    eraseFields();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
    >
      <DialogTitle id="modal-title">{"Adicionar Melhor Momento"}</DialogTitle>
      <DialogContent>
        <div id="modal-body" className="add-best-moment-container">
          <form
            className="d-flex flex-column full-width"
            onSubmit={onSubmitForm}
          >
            <InputBasic
              required={true}
              className="mb-2"
              label="Título"
              value={title}
              onChange={(value) => setTitle(value)}
            />
            <InputBasic
              className="mb-2"
              label="Descrição"
              maxLength={40}
              value={description}
              onChange={(value) => setDescription(value)}
            />
            <InputBasic
              required={true}
              className="mb-2"
              label="Intervalo Inicial"
              type="time"
              value={start}
              onChange={(value) => setStart(value)}
            />
            <InputBasic
              required={true}
              className="mb-2"
              label="Intervalo Final"
              type="time"
              value={end}
              onChange={(value) => setEnd(value)}
            />
            <div className="d-flex align-items-center full-width mt-3">
              <button
                type="submit"
                className="btn btn-outline-primary btn-block btn-lg"
              >
                {loading ? (
                  <CircularProgress size={18} color="primary" />
                ) : (
                  "Incluir"
                )}
              </button>
            </div>
          </form>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleClose}>Fechar</CustomButton>
      </DialogActions>
    </Dialog>
  );
};

AddBestMoment.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

AddBestMoment.defaultProps = {
  open: false,
  loading: false,
  onClose: () => {},
  onSubmit: (data) => {},
};

export default AddBestMoment;
