import React, { useState, useEffect } from "react";
import Moment from "moment";

import {
  SectionTitle,
  SnackAlert,
  RoomsLoading,
  MySalesContainer,
} from "../../Template/Admin/components";
import { getMySales } from "../../services/api";

import "./styles.css";

Moment.locale("pt-BR");

const MySalesScreen = () => {
  const [sales, setSales] = useState([]);
  const [emptySales, setEmptySales] = useState(false);
  const [msgEmptySales, setMsgEmptySales] = useState("");
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  useEffect(() => {
    onGetMySales();
  }, []);

  const onGetMySales = () => {
    setLoading(true);

    const loggedUser = JSON.parse(sessionStorage.getItem("currentUser"));
    const email = encodeURIComponent(loggedUser?.email);

    if (loggedUser) {
      getMySales(loggedUser?.id, email)
        .then((response) => {
          setLoading(false);

          if (response.data.return) {
            setSales(response.data.data);
            setEmptySales(false);
          } else {
            setEmptySales(true);
            setMsgEmptySales(response.data.data);
          }
        })
        .catch(() => {
          setLoading(false);
          setAlert({
            open: true,
            type: "error",
            title: "Erro ao buscar lista de vendas.",
          });
        });
    }
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  if (loading) {
    return (
      <div className="container-fluid pb-0 my-sales-screen-container">
        <RoomsLoading />
      </div>
    );
  }

  return (
    <div className="container-fluid pb-0 my-sales-screen-container">
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <div className="top-category section-padding mb-4">
        <div className="my-sales-screen-top-container pl-0">
          <SectionTitle title="Minhas Vendas" />
        </div>
      </div>
      <div className="col-lg-12 col-md-12 mb-5 pl-0">
        <MySalesContainer
          sales={sales}
          empty={emptySales}
          msgEmpty={msgEmptySales}
        />
      </div>
    </div>
  );
};

export default MySalesScreen;
