import React, { useState, useEffect } from "react";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import {
  SectionTitle,
  SnackAlert,
  CustomButton,
  ProfileLoading,
} from "../../Template/Admin/components";
import { getProfileService } from "../../services/api";

import "./styles.css";

Moment.locale("pt-BR");

const ProfileScreen = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  const history = useHistory();

  useEffect(() => {
    getProfile();
  }, []);

  function getProfile() {
    setLoading(true);

    const loggedUser = JSON.parse(sessionStorage.getItem("currentUser"));

    getProfileService(loggedUser.id)
      .then((response) => {
        setLoading(false);

        if (response.data.return) {
          setUser(response.data.data[0]);
        } else {
          setAlert({ open: true, type: "warning", title: response.data.data });
        }
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ open: true, type: "error", title: err });
      });
  }

  function goToEditProfile() {
    history.push("/home/alterar-perfil");
  }

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  if (loading) {
    return (
      <div className="container-fluid pb-0 profile-screen-container">
        <ProfileLoading />
      </div>
    );
  }

  return (
    <div className="container-fluid pb-0 profile-screen-container">
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <div className="top-category section-padding mb-4">
        <div className="profile-screen-top-container pl-0">
          <div>
            <SectionTitle title="Meu Perfil" />
          </div>
          <div className="d-flex justify-content-end">
            <CustomButton onClick={goToEditProfile}>
              <span>Editar</span>
            </CustomButton>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 mb-5 pl-0 d-flex flex-column">
        <div className="row mb-4 pl-0">
          <div className="col-md-3 col-lg-3 profile-screen-mt">
            <h6 className="text-dark">Primeiro Nome:</h6>
            <span className="profile-screen-txt">{user?.first_name}</span>
          </div>
          <div className="col-md-3 col-lg-3 profile-screen-mt">
            <h6 className="text-dark">Último Nome:</h6>
            <span className="profile-screen-txt">{user?.last_name}</span>
          </div>
          <div className="col-md-3 col-lg-3 profile-screen-mt">
            <h6 className="text-dark">E-mail:</h6>
            <span className="profile-screen-txt">{user?.email}</span>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-3 col-lg-3 profile-screen-mt">
            <h6 className="text-dark">Usuário:</h6>
            <span className="profile-screen-txt">{user?.username}</span>
          </div>
          <div className="col-md-3 col-lg-3 profile-screen-mt">
            <h6 className="text-dark">Telefone:</h6>
            <span className="profile-screen-txt">{user?.telefone}</span>
          </div>
          <div className="col-md-3 col-lg-3 profile-screen-mt">
            <h6 className="text-dark">Celular:</h6>
            <span className="profile-screen-txt">{user?.celular}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-lg-3 profile-screen-mt">
            <h6 className="text-dark">Cidade:</h6>
            <span className="profile-screen-txt">{user?.local}</span>
          </div>
          <div className="col-md-3 col-lg-3 profile-screen-mt">
            <h6 className="text-dark">Data de Nascimento:</h6>
            <span className="profile-screen-txt">
              {Moment(user?.nascimento)
                .utc()
                .format("DD/MM/YYYY")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileScreen;
