import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import Moment from "moment";

import {
  SectionTitle,
  SendGift,
  SnackAlert,
  TicketsList,
} from "../../components";
import { getTickets } from "../../../../services/api";

import "./styles.css";

Moment.locale("pt-BR");

const MyShoppingList = ({ title, data, loading, onSelected }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalTickets, setOpenModalTickets] = useState(false);
  const [loadingTickets, setLoadingTickets] = useState([]);
  const [selected, setSelected] = useState({});
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  const disableLoadingTickets = useCallback(() => {
    const aux = [];
    data &&
      data.forEach(() => {
        aux.push(false);
      });
    setLoadingTickets(aux);
  }, [data]);

  useEffect(() => {
    disableLoadingTickets();
  }, [data, disableLoadingTickets]);

  const handleSelected = (item, action, index) => {
    setSelected(item);

    if (action === "tickets") onGetTickets(item, index);
    else setOpenModal(true);
  };

  const onGetTickets = async (item, i) => {
    try {
      const load = [...loadingTickets];
      load[i] = true;
      setLoadingTickets(load);

      const loggeduser = JSON.parse(sessionStorage.getItem("currentUser"));

      const params = new FormData();
      params.append("conteudo_pagamento_id", item?.transacao_id);
      params.append("conteudo_id", item?.conteudo_id);
      params.append("user_id", loggeduser?.id);

      const response = await getTickets(params);
      if (response.data.return) {
        setSelectedTickets(response.data.data);
        setOpenModalTickets(true);
      } else {
        setSelectedTickets([]);
        setAlert({
          open: true,
          type: "warning",
          title: response.data.data || "",
        });
      }
    } catch (error) {
      setAlert({ open: true, type: "error", title: error.message || "Error." });
    } finally {
      disableLoadingTickets();
    }
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <div className="row my-shopping-list-container">
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      {title && <SectionTitle title={title} paddingLeft={false} />}
      {data.map((item, i) => (
        <div key={String(i)} className="col-xl-3 col-sm-6 mb-3 mt-2">
          <div className="video-card">
            <div className="video-card-body">
              <div className="video-title text-dark msl-txt-title">
                {item.conteudo_titulo}
              </div>
              <div className="video-page mb-1">
                <strong>Evento: </strong>
                {item.canal_titulo}
              </div>
              <div className="video-page mb-1">
                <strong>Status do Pagamento: </strong>
                {item.status_pagamento || "-"}
              </div>
              <div className="video-page mb-1">
                <strong>Valor Pago: </strong>
                {item.valor_pago || "-"}
              </div>
              <div className="video-page mb-3">
                <strong>Data/Hora: </strong>
                {item.data_update_not
                  ? `${Moment(item.data_update_not)
                      .utc(true)
                      .format("DD/MM/YYYY")} - ${item.hora_update_not}`
                  : "-"}
              </div>
              <div className="text-center d-flex flex-column align-items-center justify-content-center">
                <span
                  className="tags cs-pointer"
                  style={{ padding: "4px 6px", minWidth: "50%" }}
                  onClick={!loading[i] ? (e) => onSelected(e, item) : () => {}}
                >
                  {loading[i] ? (
                    <CircularProgress
                      size={14}
                      color="inherit"
                      style={{ marginTop: 2 }}
                    />
                  ) : (
                    "Reenviar por e-mail"
                  )}
                </span>
                <div className="d-flex align-items-center">
                  <span
                    className="tags cs-pointer"
                    style={{ padding: "4px 6px" }}
                    onClick={() => handleSelected(item, "presentar", i)}
                  >
                    Presentear
                  </span>
                  <span
                    className="tags cs-pointer"
                    style={{ padding: "4px 6px" }}
                    onClick={() => handleSelected(item, "tickets", i)}
                  >
                    {loadingTickets[i] ? (
                      <CircularProgress
                        size={14}
                        color="inherit"
                        style={{ marginTop: 2 }}
                      />
                    ) : (
                      "Tickets"
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <SendGift
        open={openModal}
        selected={selected}
        onClose={() => setOpenModal(false)}
      />
      <TicketsList
        open={openModalTickets}
        ticketsData={selectedTickets}
        onClose={() => setOpenModalTickets(false)}
        onSubmit={() => {}}
      />
    </div>
  );
};

MyShoppingList.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  loading: PropTypes.array,
  onSelected: PropTypes.func,
};

MyShoppingList.defaultProps = {
  data: [],
  title: "",
  loading: [],
  onSelected: (e, item) => {},
};

export default MyShoppingList;
