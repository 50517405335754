import React, { useState, useEffect } from "react";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import {
  SectionTitle,
  SnackAlert,
  RoomsLoading,
  ChannelsContainer,
  RequestChannel,
  CustomButton,
} from "../../Template/Admin/components";
import { getChannels } from "../../services/api";

import "./styles.css";

Moment.locale("pt-BR");

const ChannelsScreen = () => {
  const [channels, setChannels] = useState([]);
  const [emptyChannels, setEmptyChannels] = useState(false);
  const [msgEmptyChannels, setMsgEmptyChannels] = useState("");
  const [openModalRequest, setOpenModalRequest] = useState(false);
  const [showRequestButton, setShowRequestButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  const history = useHistory();

  useEffect(() => {
    onGetChannels();
    handleShowButtonGetStore();
  }, []);

  const handleShowButtonGetStore = () => {
    const loggedUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (!loggedUser) setShowRequestButton(false);
    else {
      const userChannel = JSON.parse(sessionStorage.getItem("userChannel"));
      if (!userChannel) setShowRequestButton(true);
      else setShowRequestButton(false);
    }
  };

  function onGetChannels() {
    setLoading(true);

    getChannels()
      .then((response) => {
        setLoading(false);

        if (response.data.return) {
          setChannels(response.data.data);
          setEmptyChannels(false);
        } else {
          setEmptyChannels(true);
          setMsgEmptyChannels(response.data.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ open: true, type: "error", title: err });
      });
  }

  const onSelectedRoom = (e, item) => {
    e.preventDefault();

    history.push(`/home/evento/${item.id}`, item);
  };

  const goToRequestChannel = () => setOpenModalRequest(true);

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  if (loading) {
    return (
      <div className="container-fluid pb-0 channels-screen-container">
        <RoomsLoading />
      </div>
    );
  }

  return (
    <div className="container-fluid pb-0 channels-screen-container">
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <div className="top-category section-padding mb-4">
        <div className="channels-screen-top-container pl-0">
          <div>
            <SectionTitle title="Eventos" />
          </div>
          {showRequestButton && (
            <div className="d-flex justify-content-end">
              <CustomButton onClick={goToRequestChannel}>
                <span>Solicitar Loja</span>
              </CustomButton>
            </div>
          )}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 mb-5 pl-0">
        <ChannelsContainer
          channels={channels}
          empty={emptyChannels}
          title=""
          msgEmpty={msgEmptyChannels}
          selectedChannel={onSelectedRoom}
        />
      </div>
      <RequestChannel
        open={openModalRequest}
        onClose={() => setOpenModalRequest(false)}
      />
    </div>
  );
};

export default ChannelsScreen;
