import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Moment from "moment";

import {
  CustomButton,
  SnackAlert,
  DataTable,
  FullLoading,
} from "../../../../../Template/Admin/components";
import { BASE_URL } from "../../../../../services/Ambiente";

import Row from "./Row";

Moment.locale("pt-BR");

const TicketsList = ({ open, ticketsData, onClose, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  useEffect(() => {
    if (open) setCoupons(ticketsData);
    else resetFields();
  }, [open, ticketsData]);

  const resetFields = () => {
    setLoading(false);
    setCoupons([]);
  };

  const handleViewTicket = (row) => {
    const link = `${BASE_URL}/tickets/meu_ingresso/${row.texto_qrcode}`;
    const element = document.createElement("a");
    element.href = link;
    element.target = "_blank";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      keepMounted
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
      maxWidth="lg"
    >
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <DialogTitle id="modal-title">Lista de Tickets</DialogTitle>
      <DialogContent>
        <div id="modal-body">
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <DataTable
                header={headers}
                rows={coupons}
                rowComponent={Row}
                onSelect={handleViewTicket}
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={onClose}>Fechar</CustomButton>
      </DialogActions>
      <FullLoading open={loading} infoText="Aguarde um momento..." />
    </Dialog>
  );
};

TicketsList.propTypes = {
  open: PropTypes.bool,
  ticketsData: PropTypes.array,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

TicketsList.defaultProps = {
  open: false,
  ticketsData: [],
  onClose: () => {},
  onSubmit: () => {},
};

export default TicketsList;

const headers = ["Nome", "CPF", "E-mail", "Utilizado?", "Ações"];
