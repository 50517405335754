import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const RedirectScreen = () => {
  const history = useHistory();

  useEffect(() => {
    history.push("/home");
  });

  return <div></div>;
};

export default RedirectScreen;
