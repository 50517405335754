import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Share } from "@material-ui/icons";
import { CustomButton } from "../../../components";

import "./styles.css";

const BestMomentView = ({ open, url, title, canShare, shareData, onClose }) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function resize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleShare = () => {
    const shareUrl = `${shareData.url?.replace("embed/", "watch?v=")}`;
    const textShare = `https://api.whatsapp.com/send?text=Its On Tickets \n\n${title}\n\nVeja o melhor momento compartilhado!\n${shareUrl}`;
    const deepLink = `${encodeURI(textShare)}%26t=${shareData.startTime}`;
    const link = document.createElement("a");
    link.href = deepLink;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
    >
      <DialogTitle id="modal-title">
        <div className="best-moment-header">
          {title}
          {canShare && (
            <CustomButton title="Compartilhar" showBorder onClick={handleShare}>
              <Share />
            </CustomButton>
          )}
        </div>
      </DialogTitle>
      <DialogContent>
        <div id="modal-body" className="best-moment-view-container">
          <iframe
            width="100%"
            height={width < 700 ? "180" : "300"}
            src={url}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title={title}
          ></iframe>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleClose}>Fechar</CustomButton>
      </DialogActions>
    </Dialog>
  );
};

BestMomentView.propTypes = {
  open: PropTypes.bool,
  url: PropTypes.string,
  title: PropTypes.string,
  canShare: PropTypes.bool,
  shareData: PropTypes.any,
  onClose: PropTypes.func,
};

BestMomentView.defaultProps = {
  open: false,
  url: "",
  title: "",
  canShare: false,
  shareData: {},
  onClose: () => {},
};

export default BestMomentView;
