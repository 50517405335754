import React, { useState, useEffect } from "react";
import { SectionTitle, SnackAlert } from "../../Template/Admin/components";
import { changePasswordService } from "../../services/api";
import Form from "./containers/Form";

import "./styles.css";

const ChangePasswordScreen = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  useEffect(() => {
    const loggedUser = JSON.parse(sessionStorage.getItem("currentUser"));

    if (loggedUser) {
      setUser(loggedUser);
    }
  }, []);

  function changePassword(data) {
    setLoading(true);

    const newData = new FormData();
    newData.append("email", data.email);
    newData.append("password", data.password);
    newData.append("new_password", data.newPassword);
    newData.append("confirm_new_password", data.confirmNewPassword);

    changePasswordService(newData)
      .then((response) => {
        setLoading(false);

        if (response.data.return) {
          setAlert({ open: true, type: "success", title: response.data.data });
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
        } else {
          setAlert({ open: true, type: "warning", title: response.data.data });
        }
      })
      .catch((err) => {
        setLoading(false);
        setAlert({ open: true, type: "error", title: err });
      });
  }

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <div className="container-fluid pb-0 change-password-screen-container">
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <div className="top-category section-padding mb-4">
        <div className="row pl-0">
          <div className="col-lg-8 col-md-8">
            <SectionTitle title="Alterar Senha" />
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 mb-5 pl-0 d-flex flex-column">
        <Form
          data={user}
          loading={loading}
          onSubmit={changePassword}
          clearFields={success}
        />
      </div>
    </div>
  );
};

export default ChangePasswordScreen;
