import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import { InputBasic } from "../../../../Template/Admin/components";

import "../../styles.css";

const Form = ({ data, onSubmit, loading, clearFields }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  useEffect(() => {
    setEmail(data.email);
  }, [data]);

  useEffect(() => {
    if (clearFields) {
      setPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    }
  }, [clearFields]);

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      email,
      password,
      newPassword,
      confirmNewPassword,
    };

    onSubmit(data);
  }

  return (
    <form onSubmit={handleSubmit} className="d-flex flex-column">
      <div className="row mb-4 pl-0">
        <div className="col-md-4 col-lg-4">
          <InputBasic
            label="E-mail"
            placeholder="Insira o seu e-mail"
            value={email}
            disabled={true}
          />
        </div>
        <div className="col-md-4 col-lg-4">
          <InputBasic
            required={true}
            label="Senha"
            placeholder="Insira o sua senha antiga"
            type="password"
            value={password}
            onChange={(value) => setPassword(value)}
          />
        </div>
      </div>
      <div className="row mb-4 pl-0">
        <div className="col-md-4 col-lg-4">
          <InputBasic
            required={true}
            label="Nova Senha"
            placeholder="Insira a sua nova senha"
            type="password"
            value={newPassword}
            onChange={(value) => setNewPassword(value)}
          />
        </div>
        <div className="col-md-4 col-lg-4">
          <InputBasic
            required={true}
            label="Confirme a Nova Senha"
            placeholder="Insira novamente a nova senha"
            type="password"
            value={confirmNewPassword}
            onChange={(value) => setConfirmNewPassword(value)}
          />
        </div>
      </div>
      <div className="change-password-screen-btn-container">
        <button
          type="submit"
          className="btn btn-outline-primary btn-block btn-lg"
        >
          {loading ? <CircularProgress size={18} color="primary" /> : "Alterar"}
        </button>
      </div>
    </form>
  );
};

Form.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  clearFields: PropTypes.bool,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  data: {},
  loading: false,
  clearFields: false,
  onSubmit: (data) => {},
};

export default Form;
