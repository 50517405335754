import React from "react";
import ContentLoader from "react-content-loader";

const UsersLoading = () => {
  return (
    <ContentLoader height={150} width="100%" backgroundColor="#ddd">
      <rect x="10" y="9.5" width="125.5" height="17" />
      <rect x="10" y="44.7" width="296" height="17" />

      <rect x="10" y="89.5" width="125.5" height="17" />
      <rect x="10" y="124.7" width="296" height="17" />
    </ContentLoader>
  );
};

export default UsersLoading;
