import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";

import "./styles.css";

const SearchInputMobile = ({ placeholder, loading, clear, onSubmit }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (clear) {
      setValue("");
    }
  }, [clear]);

  function onSearch(e) {
    e.preventDefault();

    onSubmit(value);
  }

  function onPressEnter(e) {
    if (e.nativeEvent.keyCode === 13) {
      onSubmit(value);
    }
  }

  return (
    <div className="top-mobile-search">
      <div className="row">
        <div className="col-md-12">
          <form className="mobile-search" onSubmit={onSearch}>
            <div className="input-group">
              <input
                type="text"
                placeholder={placeholder}
                className="form-control search-input-mobile-content"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyPress={onPressEnter}
              />
              <div className="input-group-append">
                <button
                  type="button"
                  className="btn btn-dark search-input-mobile-bg"
                  onClick={() => onSubmit(value)}
                >
                  {loading ? (
                    <CircularProgress size={14} color="primary" />
                  ) : (
                    <div>
                      <i className="fas fa-search"></i>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

SearchInputMobile.propTypes = {
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  clear: PropTypes.bool,
  onSubmit: PropTypes.func,
};

SearchInputMobile.defaultProps = {
  placeholder: "Pesquise aqui...",
  loading: false,
  clear: false,
  onSubmit: (value) => {},
};

export default SearchInputMobile;
