import React, { useState } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { CustomButton, SnackAlert, InputBasic } from "../../../components";
import { CircularProgress } from "@material-ui/core";
import VMasker from "vanilla-masker";
import Grid from "@material-ui/core/Grid";

import { contrateAquiService } from "../../../../../services/api";

import "./styles.css";

const BuyHere = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    telefone: "",
    cidade: "",
    estado: "",
    capital_disponivel: "",
    trabalha_eventos: "",
    mensagem: "",
  });

  const handleChangeForm = (value, e) => {
    const newList = Object.assign({}, formData, {
      [e.target.name]: value,
    });
    setFormData(newList);
  };

  function handleSubmit(e) {
    e.preventDefault();

    const newData = new FormData();
    Object.keys(formData).forEach((item) => {
      newData.append(String(item), formData[item]);
    });

    setLoading(true);

    contrateAquiService(newData)
      .then((response) => {
        if (response.data.return) {
          setAlert({
            open: true,
            type: "success",
            title: response.data.data,
          });
          setTimeout(function() {
            onClose();
          }, 3000);
        } else {
          setAlert({
            open: true,
            type: "warning",
            title: response.data.data,
          });
        }
      })
      .catch((err) => {
        setAlert({ open: true, type: "error", title: err });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const resetFields = () => {
    setFormData({
      nome: "",
      email: "",
      telefone: "",
      cidade: "",
      estado: "",
      capital_disponivel: "",
      trabalha_eventos: "",
      mensagem: "",
    });
  };

  const handleClose = () => {
    onClose();
    resetFields();
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
    >
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <DialogTitle id="modal-title">{"Seja Franqueado"}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <div id="modal-body" className="add-buy-container">
            <Grid container spacing={1}>
              {loading ? (
                <Grid item xs={12} md={12}>
                  <div className="alert alert-info" role="alert">
                    Por favor, aguarde enquanto sua solicitação está sendo
                    enviada.
                  </div>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} md={6}>
                    <InputBasic
                      required
                      label="Nome"
                      placeholder="Insira o seu nome"
                      name="nome"
                      value={formData.nome}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputBasic
                      required
                      label="E-mail"
                      type="email"
                      placeholder="Insira o seu e-mail"
                      name="email"
                      value={formData.email}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <InputBasic
                      required
                      label="Telefone"
                      placeholder="Insira o seu telefone"
                      type="tel"
                      name="telefone"
                      value={formData.telefone}
                      onChange={(value, e) => {
                        const maskedValue = VMasker.toPattern(
                          value,
                          "(99) 9 9999-9999"
                        );
                        handleChangeForm(maskedValue, e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputBasic
                      required
                      label="Cidade"
                      placeholder="Insira a Cidade"
                      name="cidade"
                      value={formData.cidade}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputBasic
                      required
                      label="Estado"
                      placeholder="Insira a Estado"
                      name="estado"
                      value={formData.estado}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputBasic
                      required
                      label="Capital Disponível"
                      placeholder="Insira o capital disponível"
                      type="tel"
                      name="capital_disponivel"
                      value={formData.capital_disponivel}
                      onChange={(value, e) => {
                        const maskedValue = VMasker.toMoney(value);
                        handleChangeForm(maskedValue, e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="form-group">
                      <div className="d-flex flex-row align-items-start">
                        <label>Trabalha com eventos?</label>
                      </div>
                      <select
                        className="form-control"
                        name="trabalha_eventos"
                        value={formData.trabalha_eventos}
                        onChange={(e) => handleChangeForm(e.target.value, e)}
                        required
                      >
                        <option value="" key="1">
                          Selecione uma opção
                        </option>
                        <option value="Sim" key="2">
                          Sim
                        </option>
                        <option value="Não" key="3">
                          Não
                        </option>
                      </select>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <InputBasic
                      required
                      label="Descrição"
                      placeholder="Insira uma descrição"
                      name="mensagem"
                      value={formData.mensagem}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleClose}>Fechar</CustomButton>
          <CustomButton type="submit" disabled={loading}>
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <div>Enviar Solicitação</div>
            )}
          </CustomButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

BuyHere.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

BuyHere.defaultProps = {
  open: false,
  onClose: () => {},
};

export default BuyHere;
