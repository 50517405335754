import React from "react";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import { SectionTitle } from "../../Template/Admin/components";
import { BASE_URL } from "../../services/Ambiente";

import "./styles.css";

Moment.locale("pt-BR");

const NewsScreen = ({ location }) => {
  const history = useHistory();

  function convertToHTML(text) {
    let stringHtml = text || "<p></p>";

    return <div dangerouslySetInnerHTML={{ __html: stringHtml }}></div>;
  }

  if (!location?.state) {
    history.push("/home");
  }

  return (
    <div className="container-fluid pb-0 news-screen-container">
      <div className="top-category section-padding mb-3">
        <div className="row">
          <SectionTitle
            title={`Notícia - ${location?.state?.titulo}`}
            paddingLeft={false}
          />
        </div>
      </div>
      <div className="d-flex flex-column pl-0 mb-5">
        {location?.state?.imagem_destaque && (
          <div className="col-lg-12 col-md-12 pl-0 mb-3">
            <div className="card">
              <div className="card-body d-flex justify-content-center">
                <img
                  className="news-screen-img"
                  src={`${BASE_URL}/${location?.state?.imagem_destaque}`}
                  alt="Logo organização"
                />
              </div>
            </div>
          </div>
        )}
        <div className="col-lg-12 col-md-12 mb-5 pl-0">
          <h6 className="text-dark">Resumo:</h6>
          <span>{convertToHTML(location?.state?.resumo)}</span>
          <h6 className="text-dark">Descrição:</h6>
          <span>{convertToHTML(location?.state?.descricao)}</span>
          <h6 className="text-dark">Autor:</h6>
          <span>{location?.state?.autor}</span>
          <h6 className="text-dark mt-3">Data de Publicação:</h6>
          <span>
            {Moment(location?.state?.data_cadastro)
              .utc()
              .format("DD/MM/YYYY")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default NewsScreen;
