import React, { useEffect } from "react";
import { BrowserQRCodeReader } from "@zxing/browser";

// Esse componente foi refatorado tendo como base a bilbioteca 'react-qr-reader'
// para atender uma necessidade deste sistema e que a biblioteca acima não atendia.
// Todos os direitos reservados para o criador da biblioteca.
// Link - https://github.com/react-qr-reader/react-qr-reader

const QrCodeReader = ({
  constraints,
  scanDelay,
  className,
  onResult,
  videoId,
}) => {
  useEffect(() => {
    const codeReader = new BrowserQRCodeReader(null, {
      delayBetweenScanAttempts: scanDelay,
    });

    if (
      !isMediaDevicesSupported() &&
      isValidType(onResult, "onResult", "function")
    ) {
      const message =
        ' Seu navegador não tem suporte para a MediaDevices API. Você pode resolver isso usando o "npm i webrtc-adapter"';

      onResult(null, new Error(message), codeReader);
    }

    const handleResult = (result, error) => {
      if (isValidType(onResult, "onResult", "function")) {
        onResult(result, error, codeReader);
      }
    };

    if (isValidType(constraints, "constraints", "object")) {
      codeReader
        .decodeOnceFromConstraints({ video: constraints }, videoId)
        .then(handleResult)
        .catch((error) => {
          if (isValidType(onResult, "onResult", "function")) {
            onResult(null, error, codeReader);
          }
        });
    }
    // eslint-disable-next-line
  }, []);

  const isMediaDevicesSupported = () => {
    const isMediaDevicesSupported =
      typeof navigator !== "undefined" && !!navigator.mediaDevices;

    return isMediaDevicesSupported;
  };

  const isValidType = (value, name, type) => {
    const isValid = typeof value === type;

    if (!isValid) {
      console.warn(`É esperado que "${name}" seja do tipo "${type}".`);
    }

    return isValid;
  };

  return (
    <section className={className}>
      <div
        style={{
          ...styles.container,
        }}
      >
        <video
          muted
          id={videoId}
          style={{
            ...styles.video,
            transform: constraints?.facingMode === "user" && "scaleX(-1)",
          }}
        />
      </div>
    </section>
  );
};

const styles = {
  container: {
    width: "100%",
    paddingTop: "100%",
    overflow: "hidden",
    position: "relative",
  },
  video: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "block",
    overflow: "hidden",
    position: "absolute",
    transform: undefined,
  },
};

QrCodeReader.displayName = "QrCodeReader";
QrCodeReader.defaultProps = {
  constraints: {
    facingMode: "user",
  },
  videoId: "video",
  scanDelay: 500,
  stopScan: false,
};

export default QrCodeReader;
