import React from "react";
import ContentLoader from "react-content-loader";

const BestMomentsLoading = () => {
  return (
    <ContentLoader height={250} width="100%">
      <rect x="18" y="29.5" width="100" height="80" />
      <rect x="129.9" y="29.5" width="50%" height="12" />
      <rect x="129.9" y="64.7" width="50%" height="12" />
      <rect x="129.9" y="97.8" width="50%" height="12" />

      <rect x="18" y="149.9" width="100" height="80" />
      <rect x="129.9" y="149.9" width="50%" height="12" />
      <rect x="129.9" y="185" width="50%" height="12" />
      <rect x="129.9" y="218.2" width="50%" height="12" />
    </ContentLoader>
  );
};

export default BestMomentsLoading;
