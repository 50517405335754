import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { CustomButton } from "../../../components";
import { BASE_URL } from "services/Ambiente";
import { verifyFileFormat } from "../../../../../utils";

const PreviewContent = ({ open, selectedContent, onClose }) => {
  const [type, setType] = useState("");

  useEffect(() => {
    if (!open) {
      resetFields();
    } else {
      const ext = verifyFileFormat(selectedContent.link_preview);
      if (ext) setType(ext);
    }
    // eslint-disable-next-line
  }, [open, selectedContent]);

  const resetFields = () => {
    setType("");
  };

  const handleClose = () => {
    onClose();
    resetFields();
  };

  const renderImage = () => {
    return (
      <div className="d-flex justify-content-center">
        <img
          src={`${BASE_URL}/${selectedContent.link_preview}`}
          alt="Preview"
          style={{ height: 300 }}
        />
      </div>
    );
  };

  const renderVideo = () => {
    return (
      <div>
        <video
          src={`${BASE_URL}/${selectedContent.link_preview}`}
          style={{ height: 300 }}
          controls
        ></video>
      </div>
    );
  };

  const renderAudio = () => {
    return (
      <div>
        <audio
          src={`${BASE_URL}/${selectedContent.link_preview}`}
          controls
        ></audio>
      </div>
    );
  };

  const renderPreview = () => {
    if (type === "Vídeo") return renderVideo();
    else if (type === "Imagem") return renderImage();
    else return renderAudio();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
      maxWidth="lg"
    >
      <DialogTitle id="modal-title">{`Preview ${selectedContent.titulo_conteudo ||
        ""}`}</DialogTitle>
      <DialogContent>
        <div id="modal-body">
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              {renderPreview()}
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleClose}>Fechar</CustomButton>
      </DialogActions>
    </Dialog>
  );
};

PreviewContent.propTypes = {
  open: PropTypes.bool,
  selectedContent: PropTypes.any,
  onClose: PropTypes.func,
};

PreviewContent.defaultProps = {
  open: false,
  selectedContent: {},
  onClose: () => {},
};

export default PreviewContent;
