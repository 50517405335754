import React, { useState, useEffect, useContext } from "react";
import {
  SearchInputMobile,
  RoomsLoading,
  SnackAlert,
  NewsBanner,
  ChannelsContainer,
} from "../../Template/Admin/components";
import { useHistory } from "react-router-dom";
import {
  searchRoomService,
  getChannels,
  getBannersNoticesService,
} from "../../services/api";
import { ToggleLoggedContext } from "../../Context/login";

import "./styles.css";

const HomeScreen = () => {
  const [loadingPublic, setLoadingPublic] = useState(true);
  const [events, setEvents] = useState([]);
  const [emptyEvents, setEmptyEvents] = useState(false);
  const [msgEmptyEvents, setMegEmptyEvents] = useState("");
  const [bannersNotices, setBannersNotices] = useState([]);
  const [emptyBanners, setEmptyBanners] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  const { isLogged } = useContext(ToggleLoggedContext);

  const history = useHistory();

  useEffect(() => {
    getChannelsHome();
    getBannersHome();
  }, []);

  const getBannersHome = async () => {
    try {
      const response = await getBannersNoticesService();
      if (response.data.return) {
        setEmptyBanners(false);
        setBannersNotices(response.data.data);
      } else {
        setEmptyBanners(true);
      }
    } catch (error) {
      setAlert({ open: true, type: "error", title: error });
    }
  };

  const getChannelsHome = async () => {
    setLoadingPublic(true);
    try {
      const response = await getChannels();
      if (response.data.return) {
        setEmptyEvents(false);
        setEvents(response.data.data);
      } else {
        setEmptyEvents(true);
        setMegEmptyEvents(response.data.data);
        setEvents([]);
      }
    } catch (error) {
      setAlert({ open: true, type: "error", title: error });
    } finally {
      setLoadingPublic(false);
    }
  };

  function onInitSearch(search) {
    setLoadingSearch(true);
    const loggedUser = JSON.parse(sessionStorage.getItem("currentUser"));

    const data = new FormData();
    data.append("busca", search);

    if (loggedUser) {
      data.append("user_id", loggedUser.id);
    }

    searchRoomService(data)
      .then((response) => {
        setLoadingSearch(false);

        if (response.data.return) {
          let params = {
            data: response.data.data,
            search,
          };

          history.push("/home/resultado-pesquisa", params);

          setClearSearch(true);
          setTimeout(() => {
            setClearSearch(false);
          }, 1000);
        } else {
          setAlert({ open: true, type: "warning", title: response.data.data });
        }
      })
      .catch((err) => {
        setLoadingSearch(false);
        setAlert({ open: true, type: "error", title: err });
      });
  }

  function onSelectedEvent(e, item) {
    e.preventDefault();
    history.push(`/home/evento/${item.id}`, item);
  }

  function onSelectedNew(item) {
    history.push(`/home/noticia`, item);
  }

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <div className="container-fluid pb-0 home-screen-min-h">
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <SearchInputMobile
        placeholder="Pesquise aqui..."
        loading={loadingSearch}
        clear={clearSearch}
        onSubmit={onInitSearch}
      />
      {!emptyBanners && (
        <>
          <div className="top-category section-padding mb-4">
            <div className="col-md-24">
              <NewsBanner
                news={bannersNotices}
                onClick={(value) => onSelectedNew(value)}
              />
            </div>
          </div>
          <hr />
        </>
      )}
      {isLogged ? (
        !loadingPublic ? (
          <div className="home-screen-container">
            <ChannelsContainer
              channels={events}
              empty={emptyEvents}
              title="Eventos"
              msgEmpty={msgEmptyEvents}
              selectedChannel={(e, item) => onSelectedEvent(e, item)}
            />
          </div>
        ) : (
          <RoomsLoading />
        )
      ) : loadingPublic ? (
        <RoomsLoading />
      ) : (
        <div className="home-screen-container">
          <ChannelsContainer
            channels={events}
            empty={emptyEvents}
            title="Eventos"
            msgEmpty={msgEmptyEvents}
            selectedChannel={(e, item) => onSelectedEvent(e, item)}
          />
        </div>
      )}
    </div>
  );
};

export default HomeScreen;
