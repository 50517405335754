import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Moment from "moment";

import {
  CustomButton,
  SnackAlert,
  DataTable,
  FullLoading,
} from "../../../../../Template/Admin/components";
import { updateCouponStatus } from "../../../../../services/api";
import Row from "./Row";

Moment.locale("pt-BR");

const CouponsList = ({ open, couponsData, onClose, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    title: "",
  });

  useEffect(() => {
    if (open) setCoupons(couponsData);
    else resetFields();
  }, [open, couponsData]);

  const resetFields = () => {
    setLoading(false);
    setCoupons([]);
  };

  const handleChangeStatus = (row) => {
    setLoading(true);

    const loggedUser = JSON.parse(sessionStorage.getItem("currentUser"));
    const newData = new FormData();
    newData.append("conteudo_id", row?.conteudo_id);
    newData.append("cupom_id", row?.id);
    newData.append("user_id", loggedUser?.id);

    updateCouponStatus(newData)
      .then((response) => {
        if (response.data.return) {
          setAlert({
            open: true,
            type: "success",
            title: response.data.data,
          });
          setTimeout(() => {
            onSubmit();
          }, 1000);
        } else {
          setAlert({
            open: true,
            type: "warning",
            title: response.data.data,
          });
        }
      })
      .catch((err) => {
        setAlert({ open: true, type: "error", title: err.message || "" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function onCloseAlert() {
    setAlert({ open: false, type: "", title: "" });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      keepMounted
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
      maxWidth="lg"
    >
      <SnackAlert
        open={alert.open}
        type={alert.type}
        title={alert.title}
        onClose={onCloseAlert}
      />
      <DialogTitle id="modal-title">Lista de Cupons</DialogTitle>
      <DialogContent>
        <div id="modal-body">
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <DataTable
                header={headers}
                rows={coupons}
                rowComponent={Row}
                onSelect={handleChangeStatus}
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={onClose}>Fechar</CustomButton>
      </DialogActions>
      <FullLoading open={loading} infoText="Aguarde um momento..." />
    </Dialog>
  );
};

CouponsList.propTypes = {
  open: PropTypes.bool,
  couponsData: PropTypes.array,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

CouponsList.defaultProps = {
  open: false,
  couponsData: [],
  onClose: () => {},
  onSubmit: () => {},
};

export default CouponsList;

const headers = [
  "Cupom",
  "Valor",
  "Quantidade",
  "Tipo do Desconto",
  "Produto",
  "Data Expiração",
  "Status",
];
