import React from "react";
import PropTypes from "prop-types";
import { MyShoppingList } from "../../components";

const MyShoppingContainer = ({
  shopping,
  empty,
  msgEmpty,
  loading,
  selectedShopping,
}) => {
  return (
    <>
      {empty ? (
        <div className="col-md-12 mb-3 pl-0" style={{ minHeight: 140 }}>
          <div className="main-title">
            <span>{msgEmpty}</span>
          </div>
        </div>
      ) : (
        <div className="video-block section-padding">
          <MyShoppingList
            title=""
            data={shopping}
            loading={loading}
            onSelected={(e, item) => selectedShopping(e, item)}
          />
        </div>
      )}
    </>
  );
};

MyShoppingContainer.propTypes = {
  shopping: PropTypes.array,
  empty: PropTypes.bool,
  msgEmpty: PropTypes.string,
  loading: PropTypes.array,
  selectedShopping: PropTypes.func,
};

MyShoppingContainer.defaultProps = {
  shopping: [],
  empty: false,
  msgEmpty: "",
  loading: [],
  selectedShopping: (e, item) => {},
};

export default MyShoppingContainer;
