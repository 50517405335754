import React from "react";
import PropTypes from "prop-types";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

import "./styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const SimpleAlert = ({
  title,
  description,
  open,
  type,
  buttonText,
  useCloseButton,
  onClose,
}) => {
  const classes = useStyles();

  const renderButton = () => {
    if (useCloseButton) {
      return (
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      );
    }

    return (
      <Button variant="outlined" color="inherit" size="small" onClick={onClose}>
        {buttonText}
      </Button>
    );
  };

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert className="simple-alert" action={renderButton()} severity={type}>
          {title && <AlertTitle>{title}</AlertTitle>}
          {description}
        </Alert>
      </Collapse>
    </div>
  );
};

SimpleAlert.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  open: PropTypes.bool,
  buttonText: PropTypes.string,
  useCloseButton: PropTypes.bool,
  type: PropTypes.oneOf(["error", "warning", "info", "success", ""]),
  onClose: PropTypes.func,
};

SimpleAlert.defaultProps = {
  title: "",
  description: "",
  type: "success",
  open: false,
  useCloseButton: false,
  buttonText: "Fechar",
  onClose: () => {},
};

export default SimpleAlert;
