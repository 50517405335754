import React from "react";
import PropTypes from "prop-types";

// import "./styles.css";

const InputBasic = ({
  label,
  value,
  name,
  placeholder,
  type,
  onChange,
  className,
  required,
  maxLength,
  disabled,
  ...props
}) => {
  return (
    <div className={["form-group", className].join(" ")}>
      <div className="d-flex flex-row align-items-start">
        <label>{label}</label>
        {required && <span className="text-danger ml-1">*</span>}
      </div>
      <input
        type={type}
        name={name}
        required={required}
        className="form-control"
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        value={value}
        step={type === "time" ? "1" : null}
        onChange={(e) => onChange(e.target.value, e)}
        {...props}
      ></input>
    </div>
  );
};

InputBasic.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

InputBasic.defaultProps = {
  value: "",
  label: "",
  name: "",
  type: "text",
  className: "",
  placeholder: "",
  required: false,
  maxLength: 100,
  disabled: false,
  onChange: (value, e) => {},
};

export default InputBasic;
